import { createModule, mutation, action, getter } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError, showMessage } from '@/helpers/notifications'
import { ICustomDiscount, IPromotion } from '@/interfaces/IPromotion'
import { uploadFile } from '@/helpers/files'
import store from '.'
import { getCourses, ICourse } from '@/api/courses'

export default class PromotionsStore extends createModule({
  namespaced: 'promotions',
  strict: false,
}) {
  private _promotions: IPromotion[] = []
  private _totalPromotions = 0

  get promotions() {
    return this._promotions
  }

  get totalPromotions() {
    return this._totalPromotions
  }

  @mutation private setPromotions(promotions: IPromotion[]) {
    for (const promotion of promotions) {
      for (const discount of promotion.customDiscount) {
        //TODO возвращать с сервера
        discount.abbreviation = discount.courseId.toString()
      }
    }

    this._promotions = promotions
  }

  @mutation private setTotalPromotions(totalPromotions: number) {
    this._totalPromotions = totalPromotions
  }

  @mutation private deletePromotionLocal(id: number) {
    this._promotions = this._promotions.filter(promotion => promotion.id !== id)
  }

  @mutation private disablePromotionLocal(id: number) {
    const promotion = this._promotions.find(p => p.id === id)
    if (promotion) {
      promotion.enabled = false
    }
  }

  @mutation private enablePromotionLocal(id: number) {
    for (const promotion of this._promotions) {
      promotion.enabled = false
    }
    const promotion = this._promotions.find(p => p.id === id)
    if (promotion) {
      promotion.enabled = true
    }
  }

  @action async fetchPromotions({
    searchQuery,
    page,
    perPage,
    orderBy,
  }: {
    searchQuery: string
    page: number
    perPage: number
    orderBy: Array<{ field: string; type: 'asc' | 'desc' }> | null
  }) {
    try {
      const search = searchQuery ? `&searchQuery=${searchQuery}` : ''

      const order = orderBy
        ? `&orderBy=[${orderBy.map(el => `{"${el.field}":"${el.type}"}`)}]`
        : '&orderBy=[{"id":"desc"}]'

      const query = `v1/promotions?page=${page}&perPage=${perPage}${search}${order}`

      const response: AxiosResponse<{
        promotions: IPromotion[]
        meta: { totalItems: number }
      }> = await axios.get(query)
      this.setPromotions(response.data.promotions)
      this.setTotalPromotions(response.data.meta.totalItems)
    } catch (error) {
      console.error(error)
    }
  }

  @action async deletePromotion(id: number) {
    try {
      await axios.delete(`v1/promotions/${id}`)
      this.deletePromotionLocal(id)
      showMessage('Акция удалена')
    } catch (error) {
      console.error(error)
      showError('При удалении акции произошла ошибка')
    }
  }

  @action async enablePromotion(id: number) {
    try {
      await axios.patch(`v1/promotions/${id}/enable`)
      this.enablePromotionLocal(id)
    } catch (error) {
      console.error(error)
      showError('При активации акции произошла ошибка')
    }
  }

  @action async disablePromotion(id: number) {
    try {
      await axios.patch(`v1/promotions/${id}/disable`)
      this.disablePromotionLocal(id)
    } catch (error) {
      console.error(error)
      showError('При отключении акции произошла ошибка')
    }
  }
}
