import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { createModule, mutation, action, getter } from 'vuex-class-component'
import { LocalizedField } from './i18nStore'

interface IArticle {
  id: number
  title: LocalizedField
  alias: string
  content: LocalizedField
  previewImage: string
  smmImage: string
  publishDate: string
  realViewsCount: number
  fakeViewsCount: number
  tag: {
    id: number
    label: string
    color: string
  }
}

export interface ITag {
  id: number
  label: LocalizedField
  alias: string
  color: string
}

export default class BlogStore extends createModule({
  namespaced: 'blog',
  strict: false,
}) {
  @getter blogState: 'new' | 'edit' = 'new'
  @getter tags: ITag[] = []
  @getter selectedTag: ITag | null = null
  @getter article: IArticle | null = null

  @action
  async fetchArticle(id: number) {
    try {
      const response = await axios.get<IArticle>(`v1/blog/articles/${id}`)
      this.article = response.data
      return true
    } catch (error) {
      console.error(error)
      showError('При загрузке статьи произошла ошибка')
      return false
    }
  }

  @action
  async fetchTags() {
    const response = await axios.get<ITag[]>('v1/blog/tags')
    this.tags = response.data
    try {
    } catch (error) {
      console.error(error)
      showError('При загрузке тегов произошла ошибка')
    }
  }

  @action
  async createTag(tag: ITag) {
    try {
      const response = await axios.post<ITag>('v1/blog/tags', tag)
      this.tags.push(response.data)
      this.selectedTag = response.data
      return true
    } catch (error) {
      console.error(error)
      showError('При создании категории произошла ошибка')
      return false
    }
  }

  @action
  async saveTag(tag: ITag) {
    try {
      const response = await axios.patch<ITag>(`v1/blog/tags/${tag.id}`, tag)
      Object.assign(this.tags.find(t => t.id === tag.id)!, response.data)
      this.selectedTag = tag
      return true
    } catch (error) {
      console.error(error)
      showError('При создании категории произошла ошибка')
      return false
    }
  }

  @action
  async removeTag(id: number) {
    await axios.delete(`v1/blog/tags/${id}`)
    this.tags = this.tags.filter(t => t.id !== id)
    this.selectedTag = null
    try {
    } catch (error) {
      console.error(error)
      showError('При удалении категории произошла ошибка')
    }
  }
}
