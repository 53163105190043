import { createModule, mutation, action, getter } from 'vuex-class-component'

export const languages = ['ru', 'uk', 'en'] as const
export type Language = typeof languages[number]
export type LocalizedField = Partial<Record<Language, string>>

export function emptyLocalizedField(): LocalizedField {
  return { ru: '', en: '', uk: '' }
}
export default class I18nStore extends createModule({
  namespaced: 'i18n',
  strict: false,
}) {
  @getter selectedLanguage: Language = 'ru'
}
