import { showError, showMessage } from '@/helpers/notifications'
import { nanoid } from 'nanoid'
import {
  FieldValidator,
  greater,
  isUrl,
  maxLength,
  minLength,
  notEmpty,
  ValidationGroup,
} from '@/helpers/validation'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { createModule, mutation, action, getter } from 'vuex-class-component'
import { Teacher } from './courseStore'
import { emptyLocalizedField, LocalizedField } from './i18nStore'
import { courseStore, lessonStore } from '.'
import {
  IKinescopeVideo,
  KinescopeFolder,
  KinescopeVideoChapter,
} from '@/components/lessonEdit/KinescopeVideoSelector'

export type HomeworkType = 'work' | 'test' | 'none'

export type LessonFile = {
  name: FieldValidator<string>
  url: string | null
  file: File | null
}

export type LessonLink = {
  name: FieldValidator<string>
  url: FieldValidator<string>
}

export type TestQuestion = {
  id: string
  isImages: boolean
  multiply: boolean
  title: FieldValidator<string>
  image: string | null
  imageFile: File | null
  answers: TestAnswer[]
  answersCountValidator: FieldValidator<boolean>
  collapsed: boolean
}

export type TestAnswer = {
  id: string
  title: FieldValidator<string>
  note: string
  image: FieldValidator<string | null>
  imageFile: File | null
  isCorrect: boolean
}

export type VideoChapter = {
  id: string
  title: FieldValidator<string>
  time: FieldValidator<string>
}

interface ISprintsAndLessons {
  sprint: {
    id: number
    title: LocalizedField
    stream_id: number
    position: number
    description: string
  }
  lessons: {
    id: number
    title: string
    description: string
    date_start: string
    live: boolean
    live_url: string | null
    type: number
    position: number
    note: string | null
    attaches: unknown[]
    video: {
      video_fragments: string | null
      url: string | null
      play_time: unknown | null
      urls_video: Record<string, string>
    }
  }[]
}

interface ILesson {
  id: number | null
  title: LocalizedField
  description: LocalizedField
  live: {
    url: string | null
    isActive: boolean
  }
  video: {
    url: string | null
    isChatVisible: boolean
    alternativeUrl: string | null
    duration: string | null
    kinescopeVideoId: string | null
    chapters: string | null
  }
  sprintId: number | null
  sendPulseEvent: string | null
  homeworkType: HomeworkType
  teacherId: number
  access: {
    startDate: string
    videoOffset: number
    homeworkOffset: number
  }
  note: string | null
  links: { name: string; url: string }[]
  files: { name: string; url: string }[]
  homework: {
    text: string | null
    points: number | null
  }
  test: Test
}

interface Test {
  points: {
    bad: number | null
    good: number | null
    great: number | null
  }
  questions: IQuestion[]
}

interface IQuestion {
  id: string
  image: string | null
  title: string | null
  answers: IAnswer[]
  hasImages: boolean
  isMultiply: boolean
}

interface IAnswer {
  id: string
  note: null
  image: null
  title: string | null
  isCorrect: boolean
}

type Sprint = {
  id: number
  title: string
  stream_id: number
  position: number
  description: string
  lessonsCount: number
}

type Lesson = {
  id: number
  title: string
  description: string
  date_start: string
  live: boolean
  live_url: string | null
  type: number
  position: number
  note: string | null
  attaches: unknown[]
  positionText: string
  sprintId: number
  streamId: number
}

function chapterTimeFromMs(value: number) {
  value /= 1000

  let seconds = Math.floor(value % 60) || 0
  let minutes = Math.floor((value / 60) % 60) || 0
  let hours = Math.floor((value / (60 * 60)) % 60) || 0

  let secondsText = seconds.toString()
  let minutesText = minutes.toString()
  let hoursText = hours.toString()

  if (hours < 0) {
    hoursText = '00'
  } else if (hours < 10) {
    hoursText = `0${hours}`
  }
  if (minutes < 0) {
    minutesText = '00'
  } else if (minutes < 10) {
    minutesText = `0${minutes}`
  }
  if (seconds < 0) {
    secondsText = '00'
  } else if (seconds < 10) {
    secondsText = `0${seconds}`
  }

  return `${hoursText}:${minutesText}:${secondsText}`
}

function msFromChapterTime(value: string) {
  const split = value.split(':')
  switch (split.length) {
    case 0:
      return 0
    case 1:
      return parseInt(split[0]) * 1000
    case 2:
      return parseInt(split[0]) * 60 * 1000 + parseInt(split[1]) * 1000
    case 3:
      return (
        parseInt(split[0]) * 60 * 60 * 1000 +
        parseInt(split[1]) * 60 * 1000 +
        parseInt(split[2]) * 1000
      )
  }

  return 0
}

export default class LessonStore extends createModule({
  namespaced: 'lesson',
  strict: false,
}) {
  @getter showEmptyKinescopeFolders = false
  @getter lessonState: 'new' | 'edit' = 'new'
  @getter chaptersPending = false

  @getter lessonId: number | null = null
  @getter lessonSprintId: number = -1

  @getter kinescopeFolders: KinescopeFolder[] = []

  @getter mainValidator = new ValidationGroup()
  @getter filesAndNotesValidator = new ValidationGroup()
  @getter homeworkValidator = new ValidationGroup()
  @getter testValidator = new ValidationGroup()

  @getter sprints: Sprint[] = []
  @getter lessons: Lesson[] = []

  @getter title = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.mainValidator,
    localized: true,
  })
  @getter description = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.mainValidator,
    localized: true,
  })
  @getter selectedSprint = new FieldValidator<Sprint | null>({
    value: null,
    validators: [notEmpty()],
    group: this.mainValidator,
  })
  @getter homeworkType: HomeworkType = 'none'
  @getter sendpulseEvent = new FieldValidator<string>({
    value: '',
    validators: [],
    group: this.mainValidator,
  })
  @getter isLive = false
  @getter isChatEnabled = false
  @getter liveUrl = new FieldValidator<string>({
    value: '',
    validators: [notEmpty(), isUrl()],
    ignoreValidation: () => !lessonStore.isLive,
    group: this.mainValidator,
  })
  @getter kinescopeVideoId = new FieldValidator<string>({
    value: '',
    validators: [minLength(36), maxLength(36)],
    ignoreValidation: value => lessonStore.isLive || value === '',
    group: this.mainValidator,
  })
  @getter kinescopeEmbed = ''
  @getter chapters: VideoChapter[] = []
  @getter alternativeVideoUrl = new FieldValidator<string>({
    value: '',
    validators: [isUrl()],
    ignoreValidation: value => lessonStore.isLive || value === '',
    group: this.mainValidator,
  })
  @getter selectedTeacher = new FieldValidator<Teacher | null>({
    value: null,
    validators: [
      (value: Teacher) => {
        if (value === null) {
          return { error: 'Выберите преподавателя' }
        }
      },
    ],
    group: this.mainValidator,
  })
  @getter startDate = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.mainValidator,
  })
  @getter videoAccessStep = new FieldValidator<number | null>({
    value: 2,
    validators: [notEmpty(), greater(0)],
    group: this.mainValidator,
  })
  @getter homeworkAccessStep = new FieldValidator<number | null>({
    value: 1,
    validators: [notEmpty(), greater(0)],
    group: this.mainValidator,
  })
  @getter note = new FieldValidator<string>({
    value: '',
    validators: [],
    group: this.filesAndNotesValidator,
  })
  @getter files: LessonFile[] = []
  @getter links: LessonLink[] = []

  @getter homeworkText = new FieldValidator<string>({
    value: '',
    validators: [notEmpty()],
    group: this.homeworkValidator,
  })
  @getter homeworkPoints = new FieldValidator<number | null>({
    value: 100,
    validators: [notEmpty(), greater(0)],
    group: this.homeworkValidator,
  })

  @getter testBadPoints = new FieldValidator<number | null>({
    value: 40,
    validators: [notEmpty(), greater(0)],
    group: this.testValidator,
  })
  @getter testGoodPoints = new FieldValidator<number | null>({
    value: 60,
    validators: [notEmpty(), greater(0)],
    group: this.testValidator,
  })
  @getter testGreatPoints = new FieldValidator<number | null>({
    value: 80,
    validators: [notEmpty(), greater(0)],
    group: this.testValidator,
  })
  @getter testQuestions: TestQuestion[] = []

  @mutation
  public addChapter(data?: KinescopeVideoChapter) {
    this.chapters.push({
      id: nanoid(),
      title: new FieldValidator<string>({
        value: data?.title || '',
        validators: [notEmpty()],
        group: this.mainValidator,
      }),
      time: new FieldValidator({
        value: data ? chapterTimeFromMs(data.time) : '00:00:00',
        validators: [
          notEmpty(),
          (value: string) => {
            if (value.split(':').some(v => parseInt(v) > 59)) {
              return {
                error: 'Некорректное время',
              }
            }
          },
        ],
        group: this.mainValidator,
      }),
    })
  }

  @mutation
  public removeChapter(chapter: VideoChapter) {
    this.mainValidator.removeField(chapter.time)
    this.mainValidator.removeField(chapter.title)
    this.chapters = this.chapters.filter(c => c != chapter)
  }

  @mutation
  public addAnswer({
    question,
    data,
  }: {
    question: TestQuestion
    data?: IAnswer
  }) {
    question.answers.push({
      id: data?.id || nanoid(),
      image: new FieldValidator<string | null>({
        value: data?.image || null,
        validators: [notEmpty()],
        ignoreValidation: () => !question.isImages,
        group: this.testValidator,
      }),
      imageFile: null,
      isCorrect: data?.isCorrect || false,
      note: data?.note || '',
      title: new FieldValidator({
        value: data?.title || '',
        validators: [notEmpty()],
        ignoreValidation: () => question.isImages,
        group: this.testValidator,
      }),
    })
  }

  @mutation
  public removeAnswer({
    question,
    answer,
  }: {
    question: TestQuestion
    answer: TestAnswer
  }) {
    this.testValidator.removeField(answer.title)
    this.testValidator.removeField(answer.image)
    question.answers = question.answers.filter(a => a !== answer)
  }

  @mutation
  public addFile({
    url,
    name,
    file,
  }:
    | { url?: never; name?: never; file: File }
    | { url: string; name: string; file?: never }) {
    if (file) {
      this.files.push({
        file,
        name: new FieldValidator({
          value: file.name,
          validators: [notEmpty()],
          group: this.filesAndNotesValidator,
        }),
        url: null,
      })
    } else {
      this.files.push({
        file: null,
        name: new FieldValidator({
          value: name,
          validators: [notEmpty()],
          group: this.filesAndNotesValidator,
        }),
        url,
      })
    }
  }

  @mutation
  public removeFile(file: LessonFile) {
    this.filesAndNotesValidator.removeField(file.name)
    this.files = this.files.filter(f => f !== file)
  }

  @mutation
  public addLink({ name = '', url = '' }: { name?: string; url?: string }) {
    this.links.push({
      name: new FieldValidator({
        value: name,
        validators: [notEmpty()],
        group: this.filesAndNotesValidator,
      }),
      url: new FieldValidator({
        value: url,
        validators: [notEmpty(), isUrl()],
        group: this.filesAndNotesValidator,
      }),
    })
  }

  @mutation
  public removeLink(link: LessonLink) {
    this.filesAndNotesValidator.removeField(link.name)
    this.filesAndNotesValidator.removeField(link.url)
    this.links = this.links.filter(l => l !== link)
  }

  @mutation
  private setSprintsAndLessons(data: ISprintsAndLessons[]) {
    data = data.sort((el1, el2) => el1.sprint.position - el2.sprint.position)

    this.lessons = []
    this.sprints = []
    let sprintIndex = 1
    data.forEach(el => {
      this.sprints.push({
        ...el.sprint,
        title: el.sprint.title.ru || '',
        lessonsCount: el.lessons.length,
      })
      let lessonIndex = 1
      el.lessons = el.lessons.sort((l1, l2) => l1.position - l2.position)
      this.lessons = this.lessons.concat(
        el.lessons.map(lesson => ({
          ...lesson,
          positionText: `${sprintIndex}-${lessonIndex++}`,
          sprintId: el.sprint.id,
          streamId: el.sprint.stream_id,
        })),
      )
      sprintIndex++
    })
  }

  @action
  public async fillChapters(chapters: KinescopeVideoChapter[]) {
    this.chapters.forEach(this.removeChapter)
    chapters.forEach(this.addChapter)
  }

  @action
  async addQuestion(data?: IQuestion) {
    const question: TestQuestion = {
      id: data?.id || nanoid(),
      title: new FieldValidator({
        value: data?.title || '',
        validators: [notEmpty()],
        group: this.testValidator,
      }),
      image: data?.image || null,
      imageFile: null,
      isImages: data?.hasImages || false,
      multiply: data?.isMultiply || false,
      answers: [],
      collapsed: false,
      answersCountValidator: new FieldValidator({
        value: false,
        validators: [
          () => {
            if (!question.answers.some(a => a.isCorrect)) {
              return {
                error: 'У вопроса должен быть хотя бы один верный ответ',
              }
            }
          },
        ],
        group: this.testValidator,
      }),
    }

    this.testQuestions.push(question)

    if (data) {
      data.answers.forEach(answer => this.addAnswer({ question, data: answer }))
    } else {
      this.addAnswer({ question })
      this.addAnswer({ question })
    }
  }

  @action
  async removeQuestion(question: TestQuestion) {
    question.answers.forEach(answer => this.removeAnswer({ question, answer }))
    this.testValidator.removeField(question.title)
    this.testValidator.removeField(question.answersCountValidator)
    this.testQuestions = this.testQuestions.filter(q => q !== question)
  }

  @action
  private async setLessonData(data: ILesson) {
    this.title.set(data.title)
    this.description.set(data.description)
    this.lessonSprintId = data.sprintId ?? -1
    this.selectedSprint.set(
      this.sprints.find(s => s.id === data.sprintId) || null,
    )
    this.homeworkType = data.homeworkType
    this.sendpulseEvent.set(data.sendPulseEvent || '')
    this.selectedTeacher.set(
      courseStore.teachers.find(t => t.id === data.teacherId) || null,
    )
    this.isChatEnabled = data.video.isChatVisible
    this.isLive = data.live.isActive
    this.liveUrl.set(data.live.url || '')
    this.kinescopeVideoId.set(data.video.kinescopeVideoId || '')
    this.kinescopeEmbed = data.video.url || ''
    this.alternativeVideoUrl.set(data.video.alternativeUrl || '')
    this.startDate.set(data.access.startDate)
    this.videoAccessStep.set(data.access.videoOffset)
    this.homeworkAccessStep.set(data.access.homeworkOffset)
    this.note.set(data.note || '')
    data.links.forEach(this.addLink)
    data.files.forEach(this.addFile)
    if (data.homeworkType === 'work') {
      this.homeworkText.set(data.homework.text || '')
      if (data.homework.points) {
        this.homeworkPoints.set(data.homework.points)
      }
    } else if (data.homeworkType === 'test') {
      this.setTestData(data.test)
    }
  }

  @action
  private async setTestData(test: Test) {
    if (test.points.bad) {
      this.testBadPoints.set(test.points.bad)
    }
    if (test.points.good) {
      this.testGoodPoints.set(test.points.good)
    }
    if (test.points.great) {
      this.testGreatPoints.set(test.points.great)
    }
    test.questions.forEach(this.addQuestion)
  }

  @action
  async fetchSprintsAndLessons(streamId: number | string) {
    try {
      const response: AxiosResponse<{
        data: ISprintsAndLessons[]
      }> = await axios.get(`v1/streams/${streamId}/lessons/sprints`)
      this.setSprintsAndLessons(response.data.data)
      return true
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при загрузке потоков')
      return false
    }
  }

  @action
  async fetchLesson(id: number) {
    try {
      const response: AxiosResponse<ILesson> = await axios.get(
        `v2/lessons/${id}`,
      )
      this.setLessonData(response.data)

      if (response.data.video.kinescopeVideoId) {
        this.fetchChapters(response.data.video.kinescopeVideoId)
      }

      return true
    } catch (error) {
      console.error(error)
      showError('При загрузке урока произошла ошибка')
      return false
    }
  }

  @action
  async pasteLesson(json: string) {
    try {
      const data: ILesson = JSON.parse(json)
      if (!data.title && !data.description && !data.teacherId) {
        throw new Error()
      }

      data.id = this.lessonId
      data.sprintId = this.selectedSprint.value?.id ?? null
      data.access.startDate = this.startDate.value

      await this.reset()
      this.lessonId = data.id
      await this.setLessonData(data)

      if (data.video.kinescopeVideoId) {
        await this.fetchChapters(data.video.kinescopeVideoId)
      }

      showMessage('Урок вставлен, кроме ПОТОКА и ДАТЫ СТАРТА!')
    } catch (error) {
      showError('Информация в буфере обмена не подходит для вставки')
    }
  }

  @action
  async fetchChapters(id: string) {
    try {
      this.chaptersPending = true
      const response: AxiosResponse<{ data: IKinescopeVideo }> =
        await axios.get(`v2/kinescope/v1/videos/${id}`)

      if (!response.data.data.id) {
        throw new Error('Видео не найдено')
      }

      this.fillChapters(response.data.data.chapters.items)
      this.kinescopeEmbed = response.data.data.embed_link
    } catch (error) {
      this.fillChapters([])
      this.kinescopeEmbed = ''

      console.error(error)
      showError('При загрузке данных видео произошла ошибка')
    }
    this.chaptersPending = false
  }

  @action
  async updateChapters() {
    try {
      await axios.put(
        `v2/kinescope/v1/videos/${this.kinescopeVideoId}/chapters`,
        {
          items: this.chapters.map(c => ({
            time: msFromChapterTime(c.time.value),
            title: c.title.value,
          })),
          enabled: true,
        },
      )
      return true
    } catch (error) {
      console.error(error)
      showError('При сохранении таймкодов произошла ошибка')
      return false
    }
  }

  @action
  async validate() {
    this.homeworkValidator.resetErrors()
    this.testValidator.resetErrors()

    let result = this.mainValidator.validate()
    result = this.filesAndNotesValidator.validate() && result
    if (this.homeworkType === 'work') {
      result = this.homeworkValidator.validate() && result
    }
    if (this.homeworkType === 'test') {
      result = this.testValidator.validate() && result
    }

    return result
  }

  @action
  async saveLesson() {
    try {
      const data = await this.getLessonData()

      const response: AxiosResponse<{ id: number }> = await axios.patch(
        `v2/lessons/${this.lessonId}`,
        data,
      )

      this.lessonSprintId = data.sprintId

      return true
    } catch (error) {
      console.error(error)
      showError('При сохранении урока произошла ошибка')
      return false
    }
  }

  @action
  async createLesson() {
    try {
      const data = await this.getLessonData()

      const response: AxiosResponse<{ id: number }> = await axios.post(
        'v2/lessons',
        data,
      )

      this.lessonState = 'edit'
      this.lessonId = response.data.id

      return true
    } catch (error) {
      console.error(error)
      showError('При создании урока произошла ошибка')
      return false
    }
  }

  @action
  async getLessonData() {
    return {
      title: this.title.value,
      description: this.description.value,
      live: {
        url: this.liveUrl.value || null,
        isActive: this.isLive,
      },
      video: {
        kinescopeVideoId: this.kinescopeVideoId.value || null,
        isChatVisible: this.isChatEnabled,
        alternativeUrl: this.alternativeVideoUrl.value || null,
      },
      sprintId: this.selectedSprint.value!.id,
      sendPulseEvent: this.sendpulseEvent.value || null,
      homeworkType: this.homeworkType,
      teacherId: this.selectedTeacher.value?.id,
      access: {
        startDate: this.startDate.value,
        videoOffset: this.videoAccessStep.value,
        homeworkOffset: this.homeworkAccessStep.value,
      },
      note: this.note.value || null,
      files: this.files.map(f => ({ name: f.name.value, url: f.url })),
      links: this.links.map(l => ({ name: l.name.value, url: l.url.value })),
      homework:
        this.homeworkType === 'work'
          ? {
              text: this.homeworkText.value,
              points: this.homeworkPoints.value,
            }
          : undefined,
      test: this.homeworkType === 'test' ? await this.getTestData() : undefined,
    }
  }

  @action
  async getTestData() {
    return {
      points: {
        bad: this.testBadPoints.value,
        good: this.testGoodPoints.value,
        great: this.testGreatPoints.value,
      },
      questions: this.testQuestions.map(q => ({
        id: q.id,
        title: q.title.value,
        image: q.image,
        hasImages: q.isImages,
        isMultiply: q.multiply,
        answers: q.answers.map(a => ({
          id: a.id,
          title: a.title.value,
          image: a.image.value,
          isCorrect: a.isCorrect,
          note: a.note,
        })),
      })),
    }
  }

  @action
  async pasteTestData(json: string) {
    try {
      const data = JSON.parse(json) as Test

      if (!data.points && !data.questions) {
        throw new Error()
      }

      this.testBadPoints.set(40)
      this.testGoodPoints.set(60)
      this.testGreatPoints.set(80)
      this.testQuestions.forEach(this.removeQuestion)
      this.setTestData(data)
      showMessage('Тест вставлен')
    } catch (error) {
      showError('Информация в буфере обмена не подходит для вставки')
      return
    }
  }

  @action
  async reset() {
    this.lessonId = null
    this.fillChapters([])

    this.title.set(emptyLocalizedField())
    this.description.set(emptyLocalizedField())
    this.lessonSprintId = -1
    this.selectedSprint.set(null)
    this.homeworkType = 'none'
    this.sendpulseEvent.set('')
    this.selectedTeacher.set(null)
    this.isChatEnabled = false
    this.isLive = false
    this.liveUrl.set('')
    this.kinescopeVideoId.set('')
    this.kinescopeEmbed = ''
    this.alternativeVideoUrl.set('')
    this.startDate.set('')
    this.videoAccessStep.set(2)
    this.homeworkAccessStep.set(1)
    this.note.set('')
    this.links.forEach(this.removeLink)
    this.files.forEach(this.removeFile)
    this.homeworkText.set('')
    this.homeworkPoints.set(100)
    this.testBadPoints.set(40)
    this.testGoodPoints.set(60)
    this.testGreatPoints.set(80)
    this.testQuestions.forEach(this.removeQuestion)

    this.mainValidator.resetErrors()
    this.homeworkValidator.resetErrors()
    this.testValidator.resetErrors()
  }
}
