import axios from '@/libs/axios'
import { showMessage } from '@/helpers/notifications'

export default {
  state: {
    invites: [],
  },
  getters: {
    getInvites: state => state.invites,
  },
  mutations: {
    setInvites(state, invites) {
      state.invites = invites
    },
    deleteInvite(state, id) {
      const index = state.invites.findIndex(invite => invite.id === id)
      state.invites.splice(index, 1)
    },
    addInvite(state, invite) {
      state.invites.push(invite)
    },
  },
  actions: {
    async fetchInvites(ctx) {
      try {
        const response = await axios.get('v1/invites')
        ctx.commit('setInvites', response.data.data)
      } catch (error) {
        console.error(error)
      }
    },
    async addInvite(ctx, inviteData) {
      try {
        const response = await axios.post('v1/register/invite', inviteData)
        ctx.commit('addInvite', response.data.invite)
        showMessage('Приглашение успешно создано', 'Успех')
      } catch (error) {
        console.error(error)
      }
    },
    async deleteInvite(ctx, inviteId) {
      try {
        await axios.delete(`v1/invites/${inviteId}`)
        ctx.commit('deleteInvite', inviteId)
        showMessage('Приглашение успешно удалено', 'Успех')
      } catch (error) {
        console.error(error)
      }
    },
    async checkInvite(ctx, invite) {
      try {
        await axios.post('v1/register/invite/check', {
          invite_hash: invite,
        })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
  },
}
