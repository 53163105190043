import { createModule, mutation, action, getter } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError } from '@/helpers/notifications'
import { IPromotion } from '@/interfaces/IPromotion'
import { getCourses, ICourse } from '@/api/courses'
import {
  FieldValidator,
  greater,
  lessOrEqual,
  notEmpty,
  notSpaces,
  ValidationGroup,
} from '@/helpers/validation'
import { emptyLocalizedField } from './i18nStore'

export type PromotionTab = 'landing' | 'courseBlock' | 'promoBar' | 'popup'
export type CustomDiscount = {
  course: ICourse
  discount: FieldValidator<number>
}

export default class PromotionStore extends createModule({
  namespaced: 'promotion',
  strict: false,
}) {
  @getter promotionState: 'new' | 'edit' = 'new'

  @getter courses: ICourse[] = []
  @getter currentPromotionId = -1
  @getter activeTabs: PromotionTab[] = []

  @getter conditionsValidator = new ValidationGroup()
  @getter landingValidator = new ValidationGroup()
  @getter courseBlockValidator = new ValidationGroup()
  @getter promoBarValidator = new ValidationGroup()
  @getter popupValidator = new ValidationGroup()

  @getter title = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.conditionsValidator,
    localized: true,
  })
  @getter description = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.conditionsValidator,
    localized: true,
  })
  @getter dateEnd = new FieldValidator({
    value: '',
    validators: [
      notEmpty(),
      value => {
        const startDate = new Date(this.dateStart.value)
        const endDate = new Date(value)
        if (startDate.getTime() > endDate.getTime()) {
          return { error: 'Дата старта не может быть позже даты окончания' }
        }
      },
    ],
    group: this.conditionsValidator,
  })
  @getter dateStart = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    dependentFields: [this.dateEnd],
    group: this.conditionsValidator,
  })
  @getter discount = new FieldValidator({
    value: 0,
    validators: [notEmpty(), greater(0), lessOrEqual(100)],
    group: this.conditionsValidator,
  })
  @getter customDiscounts: CustomDiscount[] = []

  @getter landingUrl = new FieldValidator({
    value: '',
    validators: [notEmpty(), notSpaces()],
    group: this.landingValidator,
  })
  @getter landingTitle = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.landingValidator,
    localized: true,
  })
  @getter landingDescription = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.landingValidator,
    localized: true,
  })
  @getter landingBackground = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.landingValidator,
  })
  @getter landingButtonText = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.landingValidator,
    localized: true,
  })
  @getter landingImage = new FieldValidator<File | string | null>({
    value: null,
    validators: [notEmpty()],
    group: this.landingValidator,
  })
  @getter landingCourses = new FieldValidator<ICourse[]>({
    value: [],
    validators: [notEmpty()],
    group: this.landingValidator,
  })

  @getter blockColor = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.courseBlockValidator,
  })
  @getter blockBackground = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.courseBlockValidator,
  })
  @getter blockImage = new FieldValidator<File | string | null>({
    value: null,
    validators: [notEmpty()],
    group: this.courseBlockValidator,
  })

  @getter barUsePromoTitle = true
  @getter barUseLandingUrl = true
  @getter barTitle = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.promoBarValidator,
    localized: true,
  })
  @getter barButtonText = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.promoBarValidator,
    localized: true,
  })
  @getter barUrl = new FieldValidator({
    value: '',
    validators: [notEmpty(), notSpaces()],
    group: this.promoBarValidator,
  })
  @getter barColor = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.promoBarValidator,
  })
  @getter barBackground = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.promoBarValidator,
  })
  @getter barTimer = new FieldValidator({
    value: 24,
    validators: [notEmpty(), greater(0)],
    group: this.promoBarValidator,
  })
  @getter barImage = new FieldValidator<File | string | null>({
    value: null,
    validators: [notEmpty()],
    group: this.promoBarValidator,
  })

  @getter popupUseLandingUrl = true
  @getter popupUrl = new FieldValidator({
    value: '',
    validators: [notEmpty(), notSpaces()],
    group: this.popupValidator,
  })
  @getter popupButtonText = new FieldValidator({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.popupValidator,
    localized: true,
  })
  @getter popupTimer = new FieldValidator({
    value: 24,
    validators: [notEmpty(), greater(0)],
    group: this.popupValidator,
  })
  @getter popupMediaRu = new FieldValidator<File | string | null>({
    value: null,
    validators: [notEmpty()],
    group: this.popupValidator,
  })
  @getter popupMediaUa = new FieldValidator<File | string | null>({
    value: null,
    validators: [notEmpty()],
    group: this.popupValidator,
  })
  @getter popupMediaEn = new FieldValidator<File | string | null>({
    value: null,
    validators: [notEmpty()],
    group: this.popupValidator,
  })

  @mutation
  addCustomDiscount({ course, value = 0 }: { course: ICourse; value: number }) {
    this.customDiscounts.push({
      course,
      discount: new FieldValidator<number>({
        value,
        validators: [notEmpty(), greater(0), lessOrEqual(100)],
        group: this.conditionsValidator,
      }),
    })
  }

  @mutation
  removeCustomDiscount(customDiscount: CustomDiscount) {
    this.conditionsValidator.removeField(customDiscount.discount)
    this.customDiscounts = this.customDiscounts.filter(
      d => d !== customDiscount,
    )
  }

  @action
  private async setPromotionData(promotion: IPromotion) {
    this.title.set(promotion.title)
    this.description.set(promotion.description)
    this.discount.set(promotion.discount)
    this.dateStart.set(promotion.dateStart)
    this.dateEnd.set(promotion.dateEnd)
    promotion.customDiscount.forEach(c => {
      const course = this.courses.find(course => course.id === c.courseId)
      if (course) {
        this.addCustomDiscount({
          course,
          value: c.discount,
        })
      }
    })

    if (promotion.landing) {
      this.activeTabs.push('landing')
      this.landingUrl.set(promotion.landing.alias)
      this.landingTitle.set(promotion.landing.title)
      this.landingDescription.set(promotion.landing.description)
      this.landingButtonText.set(promotion.landing.buttonText)
      this.landingBackground.set(promotion.landing.color)
      this.landingImage.set(promotion.landing.image)
      this.landingCourses.value = this.courses.filter(c =>
        promotion.landing?.courses.includes(c.id),
      )
    }

    if (promotion.bar) {
      this.activeTabs.push('promoBar')
      this.barBackground.set(promotion.bar.color)
      this.barColor.set(promotion.bar.textColor)
      this.barImage.set(promotion.bar.image)
      this.barTitle.set(promotion.bar.text)
      this.barButtonText.set(promotion.bar.buttonText)
      this.barUrl.set(promotion.bar.url)
      this.barTimer.set(promotion.bar.timer)
      this.barUsePromoTitle = promotion.title === promotion.bar.text
      this.barUseLandingUrl = promotion.landing?.alias === promotion.bar.url
    }

    if (promotion.popup) {
      this.activeTabs.push('popup')
      this.popupMediaRu.set(promotion.popup.media.ru!)
      this.popupMediaUa.set(promotion.popup.media.uk!)
      this.popupMediaEn.set(promotion.popup.media.en!)
      this.popupUrl.set(promotion.popup.url)
      this.popupButtonText.set(promotion.popup.buttonText)
      this.popupTimer.set(promotion.popup.timer)
      this.popupUseLandingUrl = promotion.landing?.alias === promotion.popup.url
    }

    if (promotion.block) {
      this.activeTabs.push('courseBlock')
      this.blockBackground.set(promotion.block.color)
      this.blockColor.set(promotion.block.textColor)
      this.blockImage.set(promotion.block.image)
    }
  }

  @action
  async fetchCourses() {
    try {
      this.courses = (await getCourses()).filter(c => c.type === 'paid')
    } catch (error) {
      console.error(error)
      showError('При загрузке курсов произошла ошибка')
    }
  }

  @action
  async fetchPromotion(id: number) {
    try {
      const response: AxiosResponse<{
        promotion: IPromotion
      }> = await axios.get(`v1/promotions/${id}`)

      this.setPromotionData(response.data.promotion)

      return true
    } catch (error) {
      console.error(error)
      showError('При загрузке акции произошла ошибка')
      return false
    }
  }

  @action
  async reset() {
    this.activeTabs = []
    this.title.set(emptyLocalizedField())
    this.description.set(emptyLocalizedField())
    this.discount.set(0)
    this.dateStart.set('')
    this.dateEnd.set('')
    this.customDiscounts.forEach(cd => this.removeCustomDiscount(cd))
    this.landingUrl.set('')
    this.landingTitle.set(emptyLocalizedField())
    this.landingDescription.set(emptyLocalizedField())
    this.landingButtonText.set(emptyLocalizedField())
    this.landingBackground.set('')
    this.landingImage.set('')
    this.landingCourses.set([])
    this.barBackground.set('')
    this.barColor.set('')
    this.barImage.set('')
    this.barTitle.set(emptyLocalizedField())
    this.barButtonText.set(emptyLocalizedField())
    this.barUrl.set('')
    this.barTimer.set(24)
    this.popupMediaRu.set('')
    this.popupMediaUa.set('')
    this.popupMediaEn.set('')
    this.popupUrl.set('')
    this.popupButtonText.set(emptyLocalizedField())
    this.popupTimer.set(24)
    this.blockBackground.set('')
    this.blockColor.set('')
    this.blockImage.set('')

    this.conditionsValidator.resetErrors()
    this.promoBarValidator.resetErrors()
    this.courseBlockValidator.resetErrors()
    this.landingValidator.resetErrors()
    this.popupValidator.resetErrors()
  }

  @action
  async createPromotion() {
    try {
      const data = await this.getPromotionData()

      const response = await axios.post<{ promotion: { id: number } }>(
        'v1/promotions',
        data,
      )
      this.promotionState = 'edit'
      this.currentPromotionId = response.data.promotion.id

      return true
    } catch (error) {
      console.error(error)
      showError('При создании акции произошла ошибка')
      return false
    }
  }

  @action
  async savePromotion() {
    try {
      const data = await this.getPromotionData()

      await axios.patch(`v1/promotions/${this.currentPromotionId}`, data)

      return true
    } catch (error) {
      console.error(error)
      showError('При сохранении акции произошла ошибка')
      return false
    }
  }

  @action
  async getPromotionData() {
    return {
      title: this.title.value,
      description: this.description.value,
      discount: this.discount.value,
      dateStart: this.dateStart.value,
      dateEnd: this.dateEnd.value,
      customDiscount: this.customDiscounts.map(d => ({
        courseId: d.course.id,
        discount: d.discount.value,
      })),
      landing: this.activeTabs.includes('landing')
        ? {
            alias: this.landingUrl.value,
            title: this.landingTitle.value,
            description: this.landingDescription.value,
            buttonText: this.landingButtonText.value,
            color: this.landingBackground.value,
            image: this.landingImage.value,
            courses: this.landingCourses.value.map(c => c.id),
          }
        : undefined,
      block: this.activeTabs.includes('courseBlock')
        ? {
            color: this.blockBackground.value,
            textColor: this.blockColor.value,
            image: this.blockImage.value,
          }
        : undefined,
      bar: this.activeTabs.includes('promoBar')
        ? {
            color: this.barBackground.value,
            textColor: this.barColor.value,
            image: this.barImage.value,
            text: this.barTitle.value,
            buttonText: this.barButtonText.value,
            url: this.barUrl.value,
            timer: this.barTimer.value,
          }
        : undefined,
      popup: this.activeTabs.includes('popup')
        ? {
            media: {
              ru: this.popupMediaRu.value,
              uk: this.popupMediaUa.value,
              en: this.popupMediaEn.value,
            },
            url: this.popupUrl.value,
            buttonText: this.popupButtonText.value,
            timer: this.popupTimer.value,
          }
        : undefined,
    }
  }

  @action
  async validate() {
    this.conditionsValidator.resetErrors()
    this.promoBarValidator.resetErrors()
    this.courseBlockValidator.resetErrors()
    this.landingValidator.resetErrors()
    this.popupValidator.resetErrors()

    let result = this.conditionsValidator.validate()
    if (this.activeTabs.includes('courseBlock')) {
      result = this.courseBlockValidator.validate() && result
    }
    if (this.activeTabs.includes('landing')) {
      result = this.landingValidator.validate() && result
    }
    if (this.activeTabs.includes('popup')) {
      result = this.popupValidator.validate() && result
    }
    if (this.activeTabs.includes('promoBar')) {
      result = this.promoBarValidator.validate() && result
    }

    return result
  }
}
