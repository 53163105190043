import { createModule, mutation, action, getter } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError, showMessage } from '@/helpers/notifications'
import { DateString } from '@/helpers/types/dateString'
import { Nullable } from '@/helpers/types/nullable'
import { Query } from '@/components/WayupTable'

export interface IPromocode {
  id: number
  promocode: string
  discount: number
  courses: {
    id: number
    abbreviation: string
  }[]
  usesCount: number
  fullPrice: number
  discountPrice: number
  dateStart: DateString
  dateEnd: DateString
  created: DateString
  enabled: boolean
  description: Nullable<string>
  prepaymentAmount: Nullable<number>
  isFullPaymentEnabled: boolean
  isInstallmentEnabled: boolean
  isPrepaymentEnabled: boolean
}

//TODO тут только те поля, что необходимы для промокодов
export interface ICourse {
  id: number
  title: string
  preview_url: string
  preview_color: string
  type: 'free' | 'paid'
}

export type PromocodeData = {
  id: Nullable<number>
  promocode: string
  discount: number
  courses: number[]
  enabled: boolean
  dateStart: string
  dateEnd: string
  description: string
  prepaymentAmount: Nullable<number>
  isFullPaymentEnabled: boolean
  isInstallmentEnabled: boolean
  isPrepaymentEnabled: boolean
}

export default class PromocodesStore extends createModule({
  namespaced: 'promocodes',
  strict: false,
}) {
  @getter private _lastQuery: Query | null = null
  @getter promocodes: IPromocode[] = []
  @getter courses: ICourse[] = [] //TODO перенести в coursesStore, после его рефакторинга
  @getter promocodesCount = 0
  @getter pending = false

  @action
  async fetchPromocodes(query: Query) {
    this._lastQuery = query
    this.pending = true
    try {
      const search = query.search ? `&searchQuery=${query.search}` : ''
      const order = query.sort
        ? `&orderBy=[{"${query.sort.field.toString()}":"${query.sort.dir}"}]	`
        : ''

      const response: AxiosResponse<{
        promocodes: IPromocode[]
        meta: {
          count: number
          currentPage: number
          hasMorePages: boolean
          hasPages: boolean
          lastPage: number
          totalItems: number
        }
      }> = await axios.get(
        `v1/promocodes?page=${query.page}&perPage=${query.perPage}${search}${order}`,
      )

      this.promocodes = response.data.promocodes
      this.promocodesCount = response.data.meta.totalItems
    } catch (error) {
      console.error(error)
      showError('При загрузке промокодов произошла ошибка')
    }

    this.pending = false
  }

  @action
  async deletePromocode(id: number) {
    try {
      await axios.delete(`v1/promocodes/${id}`)

      this.promocodes = this.promocodes.filter(p => p.id !== id)
      showMessage('Промокод удален')
    } catch (error) {
      console.error(error)
      showError('При удалении промокода произошла ошибка')
    }
  }

  @action
  async createPromocode(data: PromocodeData) {
    try {
      const response: AxiosResponse<{
        promocode: IPromocode
      }> = await axios.post('v1/promocodes', data)
      this.promocodes.push(response.data.promocode)

      if (this._lastQuery) {
        await this.fetchPromocodes(this._lastQuery)
      }
      return true
    } catch (error) {
      if ((error as any).response.data?.errors?.promocode) {
        showError('Промокод с таким названием уже существует')
      } else {
        showError('При создании промокода произошла ошибка')
      }
      console.error(error)
      return false
    }
  }

  @action
  async updatePromocode(data: PromocodeData) {
    try {
      await axios.patch(`v1/promocodes/${data.id}`, data)

      if (this._lastQuery) {
        await this.fetchPromocodes(this._lastQuery)
      }
      return true
    } catch (error) {
      if ((error as any).response.data?.errors?.promocode) {
        showError('Промокод с таким названием уже существует')
      } else {
        showError('При сохранении промокода произошла ошибка')
      }
      console.error(error)
      return false
    }
  }

  @action //TODO перенести в coursesStore, после его рефакторинга
  async fetchCourses() {
    try {
      const response: AxiosResponse<{ courses: ICourse[] }> = await axios.get(
        'v1/courses',
      )

      //@ts-ignore //TODO
      response.data.courses.forEach(c => (c.title = c.title.ru))
      this.courses = response.data.courses
    } catch (error) {
      console.error(error)
      showError('При загрузке курсов произошла ошибка')
    }
  }
}
