import axios from '@/libs/axios'

export default {
  state: {
    users: [],
    currentUser: null,
  },
  getters: {
    getUsers: state => state.users,
    getCurrentUser: state => state.currentUser,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
    setCurrentUser(state, user) {
      state.currentUser = user
    },
    banUser(state, userId) {
      const currentUser = state.users.find(user => user.id === userId)
      currentUser.status = currentUser.status === 'Активный' ? 'Заблокирован' : 'Активный'
    },
  },
  actions: {
    async fetchUsers(ctx) {
      try {
        const response = await axios.get('v1/users')
        ctx.commit('setUsers', response.data.users)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchUser(ctx, userId) {
      try {
        const response = await axios.get(`v1/users/${userId}`)
        ctx.commit('setCurrentUser', response.data.user)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async banUser(ctx, userId) {
      try {
        await axios.post(`v1/users/${userId}/ban`)
        ctx.commit('banUser', userId)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
  },
}
