var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('b-overlay',{attrs:{"rounded":"sm","show":_vm.isLoading,"no-wrap":""}}),_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo d-flex align-items-center"},[_c('b-img',{attrs:{"src":_vm.appLogoImage,"alt":"logo"}}),_c('h2',{staticClass:"brand-text text-primary ml-1 text-uppercase"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1),_c('b-card-title',{staticClass:"mb-1 text-center"},[_vm._v(" Добро пожаловать! 🚀 ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Заполните поля данными, чтобы получить доступ к админ-центру ")]),_c('form-wizard',{staticClass:"mb-3 shadow-none",attrs:{"color":"#385BD7","title":null,"subtitle":null,"shape":"square","finish-button-text":"Зарегистрироваться","back-button-text":"Назад","next-button-text":"Далее"},on:{"on-complete":_vm.register}},[_c('tab-content',{attrs:{"title":"Персональные данные","icon":"feather icon-user","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Персональные данные")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Заполните свои персональные данные ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Имя","label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"Имя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"first-name","placeholder":"Иван","state":errors.length > 0 ? false : null},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Фамилия","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Фамилия","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false : null,"placeholder":"Иванов"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Дата рождения","label-for":"birth-date-input"}},[_c('validation-provider',{attrs:{"name":"Дата рождения","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"birth-date-input","raw":false,"options":_vm.options.date,"placeholder":"дд.мм.гггг","autocomplete":"off","show-decade-nav":""},on:{"blur":_vm.formatDate},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}}),_c('b-input-group-append',[_c('b-form-datepicker',_vm._b({attrs:{"show-decade-nav":"","start-weekday":"1","hide-header":"","max":_vm.maxDate,"date-format-options":{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          },"button-only":"","button-variant":errors.length > 0
                              ? 'outline-danger'
                              : 'outline-primary',"right":"","size":"sm","locale":"ru-RU","aria-controls":"birth-date-input"},on:{"context":_vm.onContext},model:{value:(_vm.datepickerBirthday),callback:function ($$v) {_vm.datepickerBirthday=$$v},expression:"datepickerBirthday"}},'b-form-datepicker',_vm.labels,false))],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Номер телефона","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Номер телефона","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon"}})],1),_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"placeholder":"1 234 567 8900"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Telegram","label-for":"telegram"}},[_c('validation-provider',{attrs:{"name":"Telegram","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"prepend":"@"}},[_c('b-form-input',{attrs:{"id":"telegram","state":errors.length > 0 ? false : null,"placeholder":"user"},model:{value:(_vm.form.telegram),callback:function ($$v) {_vm.$set(_vm.form, "telegram", $$v)},expression:"form.telegram"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Изображение профиля","label-for":"avatar"}},[_c('validation-provider',{attrs:{"name":"Изображение профиля","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":_vm.avatarPreview}}),_c('b-form-file',{ref:"avatarInput",attrs:{"id":"avatar","browse-text":_vm.form.avatar === null ? 'Выбор' : 'Обновить',"placeholder":".jpg, .png","drop-placeholder":"Перетащите сюда...","accept":".jpg, .png","state":_vm.avatarError},on:{"focus":function($event){_vm.avatarError = null},"input":_vm.handleAvatarUpload},model:{value:(_vm.form.avatar),callback:function ($$v) {_vm.$set(_vm.form, "avatar", $$v)},expression:"form.avatar"}}),_c('b-button',{staticClass:"btn-icon p-0 ml-1",attrs:{"id":"clear-pdf-button","variant":"flat-secondary"},on:{"click":_vm.clearAvatar}},[_c('b-tooltip',{attrs:{"target":"clear-pdf-button","delay":{ show: _vm.tooltipDelay, hide: 0 }}},[_vm._v(" Очистить файл ")]),_c('feather-icon',{attrs:{"size":"20","icon":"Trash2Icon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Детали аккаунта","icon":"feather icon-file-text","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Детали аккаунта")]),_c('small',{staticClass:"text-muted"},[_vm._v("Заполните данные об аккаунте")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"ivan.ivanov@email.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Пароль","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Пароль","vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"placeholder":"Пароль"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Подтверждение пароля","label-for":"c-password"}},[_c('validation-provider',{attrs:{"name":"Подтверждение пароля","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"c-password","type":_vm.passwordConfirmationFieldType,"state":errors.length > 0 ? false : null,"placeholder":"Повторите пароль"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordConfirmationVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Адрес","icon":"feather icon-map-pin","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Адрес")]),_c('small',{staticClass:"text-muted"},[_vm._v("Заполните адресные данные")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Страна","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"Страна","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('wayup-select',{attrs:{"placeholder":"Выберите страну","empty-filter-message":"Страны не найдены","options":_vm.countries,"label":"name","state":errors.length > 0 ? false : null,"clearable":false},on:{"change":_vm.onCountryChanged},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Город","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"Город","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('wayup-select',{attrs:{"placeholder":"Выберите город","disabled":_vm.form.country === null,"empty-filter-message":"Города не найдены","options":_vm.cities,"label":"name","pending":_vm.citiesPending,"state":errors.length > 0 ? false : null,"clearable":false},on:{"change":_vm.onCityChanged},model:{value:(_vm.selectedCity),callback:function ($$v) {_vm.selectedCity=$$v},expression:"selectedCity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Улица","label-for":"street"}},[_c('validation-provider',{attrs:{"name":"Улица","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HomeIcon"}})],1),_c('b-form-input',{attrs:{"id":"street","state":errors.length > 0 ? false : null,"type":"text","placeholder":"(Не указано)"},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Дом","label-for":"house"}},[_c('validation-provider',{attrs:{"name":"Дом","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HomeIcon"}})],1),_c('b-form-input',{attrs:{"id":"house","state":errors.length > 0 ? false : null,"type":"text","placeholder":"(Не указано)"},model:{value:(_vm.form.house),callback:function ($$v) {_vm.$set(_vm.form, "house", $$v)},expression:"form.house"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Строение","label-for":"block"}},[_c('validation-provider',{attrs:{"name":"Строение"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HomeIcon"}})],1),_c('b-form-input',{attrs:{"id":"block","state":errors.length > 0 ? false : null,"type":"number","placeholder":"(Не указано)"},model:{value:(_vm.form.block),callback:function ($$v) {_vm.$set(_vm.form, "block", $$v)},expression:"form.block"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Квартира","label-for":"apartment"}},[_c('validation-provider',{attrs:{"name":"Квартира","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HomeIcon"}})],1),_c('b-form-input',{attrs:{"id":"apartment","state":errors.length > 0 ? false : null,"type":"text","placeholder":"(Не указано)"},model:{value:(_vm.form.apartment),callback:function ($$v) {_vm.$set(_vm.form, "apartment", $$v)},expression:"form.apartment"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Почтовый индекс","label-for":"post-index"}},[_c('validation-provider',{attrs:{"name":"Почтовый индекс","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"InboxIcon"}})],1),_c('b-form-input',{attrs:{"id":"post-index","state":errors.length > 0 ? false : null,"type":"text","placeholder":"123456"},model:{value:(_vm.form.post_index),callback:function ($$v) {_vm.$set(_vm.form, "post_index", $$v)},expression:"form.post_index"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"№ отделения «Новой почты»","label-for":"post-office"}},[_c('validation-provider',{attrs:{"name":"№ отделения «Новой почты»","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"InboxIcon"}})],1),_c('b-form-input',{attrs:{"id":"post-office","state":errors.length > 0 ? false : null,"type":"text","placeholder":"123456"},model:{value:(_vm.form.post_office),callback:function ($$v) {_vm.$set(_vm.form, "post_office", $$v)},expression:"form.post_office"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Уже есть аккаунт?")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v("Войдите прямо сейчас")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }