import 'reflect-metadata'
import Vue from 'vue'
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import { fetchUser, TOKEN_KEY } from './@core/auth/auth'
import { loadFromLS } from './helpers/localStorage'

const vClickOutside = require('v-click-outside')
const { mask } = require('vue-the-mask')

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(vClickOutside)

Vue.directive('mask', mask)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/assets/scss/wayup.scss')

require('@core/assets/fonts/feather/iconfont.css')

Vue.config.productionTip = false

function renderVue() {
  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app')
}

if (loadFromLS(TOKEN_KEY)) {
  fetchUser().then(renderVue)
} else {
  renderVue()
}
