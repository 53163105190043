import { createModule, mutation, action } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError } from '@/helpers/notifications'

export interface IWebinarInstanceSimple {
  id: number
  dateStart: string
  aliasFinal: string
  membersCount: number
  ordersCount: number
  viewsCount: number
  withheldCount: number
}

interface IWebinarInstanceCreateData {
  promocode: {
    discount: number
    dateStart: string
    dateEnd: string
  }
  courseId: number
  dateStart: string
  youtube: string
  videoLength: number
  aliasFinal: string
  videoUrl: string
}

interface IWebinarInstanceUpdateData {
  id: number
  dateStart: string
  youtube: string
  videoLength: number
  aliasFinal: string
  videoUrl: string
}
export default class WebinarInstancesStore extends createModule({
  namespaced: 'webinarInstances',
  strict: false,
}) {
  private _id: number | null = null
  private _instancesCount = 0
  private _instances: IWebinarInstanceSimple[] = []
  private _page = 1
  private _perPage = 10
  private _alias = ''

  get instances() {
    return this._instances
  }

  get instancesCount() {
    return this._instancesCount
  }

  get alias() {
    return this._alias
  }

  @mutation public setAlias(alias: string) {
    this._alias = alias
  }

  @mutation public setPaginationOptions({
    page,
    perPage,
  }: {
    page: number
    perPage: number
  }) {
    this._page = page || 1
    this._perPage = perPage || 10
  }

  @mutation private setId(id: number) {
    this._id = id
  }

  @mutation private setInstances(instances: IWebinarInstanceSimple[]) {
    this._instances = instances
  }

  @mutation private setInstancesCount(value: number) {
    this._instancesCount = value
  }

  @mutation private deleteInstanceLocal(id: number) {
    this._instances = this._instances.filter(i => i.id !== id)
  }

  @action async createInstance(data: IWebinarInstanceCreateData) {
    try {
      await axios.post(`v1/webinar/${this._id}/instances`, data)
      await this.fetchInstances()
      return true
    } catch (error) {
      showError('При создании события произошла ошибка')
      console.error(error)
      return false
    }
  }

  @action async updateInstance(data: IWebinarInstanceUpdateData) {
    try {
      await axios.patch(`v1/webinar/instances/${data.id}`, data)
      await this.fetchInstances()
      return true
    } catch (error) {
      showError('При сохранении события произошла ошибка')
      console.error(error)
      return false
    }
  }

  @action async deleteInstance(id: number) {
    try {
      await axios.delete(`v1/webinars/instances/${id}`)
      this.deleteInstanceLocal(id)
    } catch (error) {
      showError('При удалении события произошла ошибка')
      console.error(error)
    }
  }

  @action async fetchInstances(id?: number) {
    if (id) {
      this.setId(id)
    }

    try {
      const response: AxiosResponse<{
        instances: IWebinarInstanceSimple[]
        meta: { count: number }
      }> = await axios.get(
        `v1/webinars/${id || this._id}/instances?page=${this._page}&perPage=${
          this._perPage
        }`,
      )

      this.setInstances(response.data.instances)
      this.setInstancesCount(response.data.meta.count)
      return true
    } catch (error) {
      console.error(error)
      showError('При получении экземпляров события произошла ошибка')
      return false
    }
  }
}
