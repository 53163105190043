import { showError } from '@/helpers/notifications'
import { createModule, mutation, action, getter } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { LocalizedField, emptyLocalizedField } from './i18nStore'
import { CourseType, LearnTime, LearnTimeType } from '@/api/courses'
import { CourseImageObject } from '@/components/courseEdit/MainTab/CourseImage'
import {
  FieldValidator,
  notEmpty,
  greater,
  lessOrEqual,
  greaterOrEqual,
  ValidationGroup,
} from '@/helpers/validation'
import { courseStore } from '.'
import { uploadImage } from '@/helpers/upload'
import { nanoid } from 'nanoid'
import { ICourse } from './promocodesStore'

export type Mentor = {
  id: number
  fullName: string
  avatar: string | null
  courses: number[]
}
export type Teacher = {
  id: number
  first_name: string
  last_name: string
  fullName: string
  position: string
  achievements: string
  email: string
  phone: string
  telegram: string
  dob: string
  avatar_url: string | null
  country_id: number
  city_id: number
  street: string
  house: string
  block: null | unknown
  apartment: string
  post_index: string
  post_office: number
  group: number
  status: number
  access: string
  email_verified: number
  created_at: string
  updated_at: string
  deleted_at: null | string
}
export type CourseObject = {
  title: LocalizedField
  abbreviation: string
  description: LocalizedField
  learnTime: LearnTime
  freePlaces: number
  worksCount: number
  tagId: number
  image: {
    color: string
    url: string
  }
  isPurchaseAllowed: boolean
  price: number
  prepayment: number
  discount: number
  challengePoints: number
  type: CourseType
  isActive: boolean
  mentors: number[]
  teacherId: number
  alias: string
  certificate: {
    rate: number
    templates: {
      ru: string | null
      en: string | null
      ua: string | null
    }
  }
  seo: { title: LocalizedField; description: LocalizedField }
  quiz: IQuiz | null
}
export type Tag = {
  id: number
  label: string
  color: string
}

type CourseData = {
  id: number
  title: LocalizedField
  abbreviation: string
  description: LocalizedField
  learnTime: LearnTime
  freePlaces: number
  worksCount: number
  tag: {
    id: number
    label: string
    color: string
  }
  image: {
    color: string
    url: string
  }
  price: number
  prepayment: number
  discount: number
  challengePoints: number
  isPurchaseAllowed: boolean
  type: CourseType
  isActive: boolean
  mentors: {
    id: number
    name: string
    avatar: string | null
  }[]
  teacher: {
    id: number
    name: string
    avatar: string | null
  }
  alias: string
  certificate: {
    rate: number
    templates: {
      en?: string
      ru?: string
      ua?: string
    }
  }
  seo: { title: LocalizedField; description: LocalizedField }
  quiz: IQuiz | null
}

export interface QuizQuestion {
  id: string
  title: FieldValidator<LocalizedField>
  description: FieldValidator<LocalizedField>
  amoId: FieldValidator<number | null>
  twoColumns: boolean
  isAnswers: boolean
  answers: QuizAnswer[]
  collapsed: boolean
}
export interface QuizAnswer {
  id: string
  text: FieldValidator<LocalizedField>
  points: FieldValidator<number>
}

interface IQuiz {
  enabled: boolean
  filename: LocalizedField
  image: string | null
  questions: IQuestion[]
  points: {
    yellow: number
    green: number
    gold: number
  }
}

interface IQuestion {
  answers: IAnswer[]
  id: string
  amoId: number
  title: LocalizedField
  description: LocalizedField
  type: 'answers' | 'input'
  twoColumns: boolean
}

interface IAnswer {
  id: string
  text: LocalizedField
  points: number
}

export default class CourseStore extends createModule({
  namespaced: 'course',
  strict: false,
}) {
  @getter courseId: number | null = null
  @getter mentors: Mentor[] = []
  @getter teachers: Teacher[] = []
  @getter tags: Tag[] = []
  @getter courseState: 'new' | 'edit' = 'new'
  @getter pending = false
  @getter enabled = false
  @getter quizEnabled = false
  @getter hasQuiz = false
  @getter isPurchaseAllowed = true
  @getter mainValidator = new ValidationGroup()
  @getter quizValidator = new ValidationGroup()

  @getter title = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.mainValidator,
    localized: true,
  })

  @getter alias = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.mainValidator,
  })

  @getter abbreviation = new FieldValidator({
    value: '',
    validators: [notEmpty()],
    group: this.mainValidator,
  })

  @getter description = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.mainValidator,
    localized: true,
  })

  @getter freePlaces = new FieldValidator<number | null>({
    value: null,
    validators: [notEmpty(), greater(0)],
    group: this.mainValidator,
  })

  @getter portfolioCount = new FieldValidator<number | null>({
    value: null,
    validators: [notEmpty(), greater(0)],
    group: this.mainValidator,
  })

  @getter certificateRate = new FieldValidator<number | null>({
    value: null,
    validators: [notEmpty(), greater(0), lessOrEqual(100)],
    group: this.mainValidator,
  })

  @getter challengePoints: FieldValidator<number | null> = new FieldValidator<
    number | null
  >({
    value: null,
    validators: [notEmpty(), greaterOrEqual(0)],
    ignoreValidation: () => courseStore.courseType.value === 'paid',
    group: this.mainValidator,
  })

  @getter learnTimeValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  @getter learnTimeValue = 1

  @getter learnTimeTypes: { label: string; value: LearnTimeType }[] = [
    { label: 'день', value: 'day' },
    { label: 'неделя', value: 'week' },
    { label: 'месяц', value: 'month' },
  ]
  @getter learnTimeType = this.learnTimeTypes[1]

  @getter selectedMentors = new FieldValidator<Mentor[]>({
    value: [],
    validators: [
      (value: Mentor[]) => {
        if (value.length === 0) {
          return { error: 'Выберите ментора' }
        }
      },
    ],
    group: this.mainValidator,
  })

  @getter selectedTeacher = new FieldValidator<Teacher | null>({
    value: null,
    validators: [
      (value: Teacher) => {
        if (value === null) {
          return { error: 'Выберите преподавателя' }
        }
      },
    ],
    group: this.mainValidator,
  })

  @getter prepayment: FieldValidator<number | null> = new FieldValidator<
    number | null
  >({
    value: null,
    validators: [
      notEmpty(),
      greater(0),
      () => {
        if (courseStore.prepayment.value! >= courseStore.price.value!) {
          return { error: 'Предоплата должна быть меньше цены' }
        }
      },
    ],
    ignoreValidation: () => courseStore.courseType.value === 'free',
    group: this.mainValidator,
  })

  @getter price: FieldValidator<number | null> = new FieldValidator<
    number | null
  >({
    value: null,
    validators: [notEmpty(), greater(0)],
    ignoreValidation: () => courseStore.courseType.value === 'free',
    dependentFields: [this.prepayment],
    group: this.mainValidator,
  })

  @getter discount: FieldValidator<number | null> = new FieldValidator<
    number | null
  >({
    value: 20,
    validators: [notEmpty(), greaterOrEqual(0)],
    ignoreValidation: () => courseStore.courseType.value === 'free',
    group: this.mainValidator,
  })

  @getter courseImage = new FieldValidator<CourseImageObject>({
    value: {
      color: '',
      url: null,
      preview: null,
      file: null,
    },
    validators: [
      (value: CourseImageObject) => {
        if (!value.file && !value.url) {
          return { error: 'Выберите изображение' }
        }
      },
    ],
    group: this.mainValidator,
  })

  @getter ruPdf: string | File | null = null
  @getter uaPdf: string | File | null = null
  @getter enPdf: string | File | null = null

  @getter selectedTag = new FieldValidator<Tag | null>({
    value: null,
    validators: [notEmpty({ error: 'Выберите тег' })],
    group: this.mainValidator,
  })

  @getter seoTitle = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.mainValidator,
    localized: true,
  })

  @getter seoDescription = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    group: this.mainValidator,
    localized: true,
  })

  @getter courseType = new FieldValidator<CourseType>({
    value: 'free',
    dependentFields: [
      this.price,
      this.discount,
      this.prepayment,
      this.challengePoints,
    ],
    validators: [],
  })

  @getter yellowPoints = new FieldValidator<number>({
    value: 0,
    validators: [notEmpty()],
    group: this.quizValidator,
  })

  @getter greenPoints = new FieldValidator<number>({
    value: 0,
    validators: [notEmpty()],
    group: this.quizValidator,
  })

  @getter goldPoints = new FieldValidator<number>({
    value: 0,
    validators: [notEmpty()],
    group: this.quizValidator,
  })

  @getter quizFileName = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    group: this.quizValidator,
    localized: true,
  })

  @getter quizImage = new FieldValidator<string | File | null>({
    value: null,
    validators: [],
    group: this.quizValidator,
  })

  @getter quizQuestions: QuizQuestion[] = []

  @mutation
  public removeAnswer({
    question,
    answer,
  }: {
    question: QuizQuestion
    answer: QuizAnswer
  }) {
    this.quizValidator.removeField(answer.text)
    this.quizValidator.removeField(answer.points)
    question.answers = question.answers.filter(a => a !== answer)
  }

  @action
  public async addQuestion(data?: IQuestion) {
    const question: QuizQuestion = {
      id: data?.id ?? nanoid(),
      title: new FieldValidator({
        value: data?.title ?? emptyLocalizedField(),
        validators: [notEmpty()],
        group: this.quizValidator,
        localized: true,
      }),
      description: new FieldValidator({
        value: data?.description ?? emptyLocalizedField(),
        group: this.quizValidator,
        localized: true,
      }),
      amoId: new FieldValidator<number | null>({
        value: data?.amoId ?? null,
        validators: [notEmpty()],
        group: this.quizValidator,
      }),
      isAnswers: data?.type === 'input' ? false : true,
      twoColumns: data?.twoColumns ?? false,
      answers: [],
      collapsed: false,
    }
    this.quizQuestions.push(question)

    if (data?.answers) {
      data.answers.forEach(answer => this.addAnswer({ question, data: answer }))
    } else {
      this.addAnswer({ question })
      this.addAnswer({ question })
    }
  }

  @action
  public async removeQuestion(question: QuizQuestion) {
    question.answers.forEach(answer => this.removeAnswer({ question, answer }))
    this.quizValidator.removeField(question.title)
    this.quizValidator.removeField(question.description)
    this.quizQuestions = this.quizQuestions.filter(q => q !== question)
  }

  @action
  public async addAnswer({
    question,
    data,
  }: {
    question: QuizQuestion
    data?: IAnswer
  }) {
    question.answers.push({
      id: data?.id ?? nanoid(),
      text: new FieldValidator({
        value: data?.text ?? emptyLocalizedField(),
        validators: [notEmpty()],
        group: this.quizValidator,
        ignoreValidation: () =>
          !courseStore.quizQuestions.find(q => q.id === question.id)!.isAnswers,
        localized: true,
      }),
      points: new FieldValidator({
        value: data?.points ?? 0,
        validators: [notEmpty()],
        ignoreValidation: () =>
          !courseStore.quizQuestions.find(q => q.id === question.id)!.isAnswers,
        group: this.quizValidator,
      }),
    })
  }

  @action
  private async getQuizData(): Promise<IQuiz | null> {
    if (!this.hasQuiz) {
      return null
    }

    return {
      enabled: this.quizEnabled,
      filename: this.quizFileName.value,
      image: this.quizImage.value as string,
      points: {
        gold: this.goldPoints.value,
        green: this.greenPoints.value,
        yellow: this.yellowPoints.value,
      },
      questions: this.quizQuestions.map(q => ({
        id: q.id,
        amoId: q.amoId.value!,
        title: q.title.value,
        description: q.description.value,
        twoColumns: q.twoColumns,
        type: q.isAnswers ? 'answers' : 'input',
        answers: q.isAnswers
          ? q.answers.map(a => ({
              id: a.id,
              text: a.text.value,
              points: a.points.value,
            }))
          : [],
      })),
    }
  }

  @action
  private async setCourseData(data: CourseData) {
    this.courseId = data.id
    this.enabled = data.isActive
    this.title.set(data.title)
    this.alias.set(data.alias)
    this.abbreviation.set(data.abbreviation)
    this.description.set(data.description)
    this.freePlaces.set(data.freePlaces)
    this.portfolioCount.set(data.worksCount)
    this.certificateRate.set(data.certificate.rate)
    this.challengePoints.set(data.challengePoints)
    this.courseType.value = data.type
    this.learnTimeType =
      this.learnTimeTypes.find(t => t.value === data.learnTime.type) ||
      this.learnTimeTypes[0]
    this.learnTimeValue = data.learnTime.value || 1
    this.courseImage.value.color = data.image.color
    this.courseImage.value.url = data.image.url
    this.ruPdf = data.certificate.templates.ru ?? null
    this.uaPdf = data.certificate.templates.ua ?? null
    this.enPdf = data.certificate.templates.en ?? null
    this.isPurchaseAllowed = data.isPurchaseAllowed
    this.price.set(data.price)
    this.prepayment.set(data.prepayment)
    this.discount.set(data.discount)
    this.selectedTeacher.set(
      this.teachers.find(t => t.id === data.teacher.id) ?? null,
    )
    this.selectedMentors.set(
      this.mentors.filter(m => data.mentors.some(mentor => mentor.id === m.id)),
    )
    this.selectedTag.set(data.tag)
    this.seoTitle.set(data.seo.title)
    this.seoDescription.set(data.seo.description)

    this.setQuizData(data.quiz)
  }

  @action
  private async setQuizData(quiz: IQuiz | null) {
    if (!quiz) {
      return
    }

    this.hasQuiz = true

    this.quizEnabled = quiz.enabled
    this.quizFileName.set(quiz.filename)
    this.quizImage.set(quiz.image)
    this.goldPoints.set(quiz.points.gold)
    this.greenPoints.set(quiz.points.green)
    this.yellowPoints.set(quiz.points.yellow)
    quiz.questions.forEach(this.addQuestion)
  }

  @action
  async fetchCourse(id: string | number) {
    try {
      const response: AxiosResponse<CourseData> = await axios.get(
        `v2/courses/${id}`,
      )

      this.setCourseData(response.data)

      return true
    } catch (error) {
      console.error(error)
      showError('При получении курса произошла ошибка')
      return false
    }
  }

  @action
  async saveCourse() {
    if (!this.mainValidator.validate()) {
      showError('Не все поля заполненны корректно')
      return false
    }
    this.pending = true

    await this.uploadFiles()

    try {
      switch (courseStore.courseState) {
        case 'edit':
          await axios.patch(
            `v2/courses/${this.courseId}`,
            await this.getCourseData(),
          )
          break
        case 'new':
          const response = await axios.post<ICourse>(
            'v2/courses',
            await this.getCourseData(),
          )
          console.log(response)

          this.courseState = 'edit'
          this.courseId = response.data.id
          break
      }
    } catch (error) {
      console.error(error)
      showError('При сохранении курса произошла ошибка')
      return false
    }

    this.pending = false

    return true
  }

  @action
  public async validate() {
    this.mainValidator.resetErrors()
    this.quizValidator.resetErrors()

    let result = this.mainValidator.validate()
    if (this.hasQuiz) {
      result = this.quizValidator.validate() && result
    }

    return result
  }

  @action
  async getCourseData(): Promise<CourseObject> {
    return {
      isActive: this.enabled,
      type: this.courseType.value,
      title: this.title.value,
      description: this.description.value,
      abbreviation: this.abbreviation.value,
      alias: this.alias.value,
      isPurchaseAllowed: this.isPurchaseAllowed,
      certificate: {
        rate: this.certificateRate.value!,
        templates: {
          ru: (this.ruPdf as string) || null,
          ua: (this.uaPdf as string) || null,
          en: (this.enPdf as string) || null,
        },
      },
      challengePoints: this.challengePoints.value!,
      freePlaces: this.freePlaces.value!,
      worksCount: this.portfolioCount.value!,
      image: {
        color: this.courseImage.value.color,
        url: this.courseImage.value.url!,
      },
      learnTime: {
        type: this.learnTimeType.value,
        value: this.learnTimeValue,
      },
      price: this.price.value!,
      prepayment: this.prepayment.value!,
      discount: this.discount.value!,
      tagId: this.selectedTag.value!.id,
      mentors: this.selectedMentors.value.map(m => m.id),
      teacherId: this.selectedTeacher.value!.id,
      seo: {
        title: this.seoTitle.value,
        description: this.seoDescription.value,
      },
      quiz: await this.getQuizData(),
    }
  }

  @action
  async uploadFiles() {
    if (this.courseImage.value.file) {
      const url = await uploadImage(
        this.courseImage.value.file,
        'images/courses',
      )
      if (url) {
        this.courseImage.value.url = url
        this.courseImage.value.file = null
      }
    }

    if (this.ruPdf instanceof File) {
      this.ruPdf = await this.uploadPdf(this.ruPdf as File)
    }
    if (this.uaPdf instanceof File) {
      this.uaPdf = await this.uploadPdf(this.uaPdf as File)
    }
    if (this.enPdf instanceof File) {
      this.enPdf = await this.uploadPdf(this.enPdf as File)
    }
  }

  @action
  async uploadPdf(file: File) {
    try {
      const formData = new FormData()
      formData.append('pdf', file)

      const response: AxiosResponse<{ url: string }> = await axios.post(
        'v1/uploads/pdf',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      return response.data.url
    } catch (error) {
      console.error(error)
      showError(`При загрузке сертификата ${file.name} произошла ошибка`)
      return file
    }
  }

  @action
  async fetchMentors(): Promise<void> {
    try {
      const response: AxiosResponse<{ mentors: Mentor[] }> = await axios.get(
        'v1/mentors',
      )

      this.mentors = response.data.mentors
    } catch (error) {
      console.error(error)
      showError('При загрузке менторов произошла ошибка')
    }
  }

  @action
  async fetchTeachers(): Promise<void> {
    try {
      const response: AxiosResponse<{ teachers: Teacher[] }> = await axios.get(
        'v1/teachers',
      )
      this.teachers = response.data.teachers
      this.teachers.forEach(
        t => (t.fullName = `${t.first_name} ${t.last_name}`.trim()),
      )
    } catch (error) {
      console.error(error)
      showError('При загрузке преподавателей произошла ошибка')
    }
  }

  @action
  async fetchTags() {
    try {
      const response: AxiosResponse<Tag[]> = await axios.get('v2/course/tags')
      this.tags = response.data
    } catch (error) {
      showError('При загрузке тегов произошла ошибка')
      console.error(error)
    }
  }

  @action async reset() {
    this.courseId = null
    this.enabled = true
    this.title.set(emptyLocalizedField())
    this.alias.set('')
    this.abbreviation.set('')
    this.description.set(emptyLocalizedField())
    this.freePlaces.set(null)
    this.portfolioCount.set(null)
    this.certificateRate.set(null)
    this.challengePoints.set(null)
    this.courseType.value = 'free'
    this.learnTimeType = this.learnTimeTypes[0]
    this.learnTimeValue = 1
    this.courseImage.value.color = ''
    this.courseImage.value.url = null
    this.ruPdf = null
    this.uaPdf = null
    this.enPdf = null
    this.price.set(null)
    this.prepayment.set(null)
    this.discount.set(null)
    this.selectedTeacher.set(null)
    this.selectedMentors.set([])
    this.selectedTag.set(null)
    this.seoTitle.set(emptyLocalizedField())
    this.seoDescription.set(emptyLocalizedField())
    this.quizFileName.set(emptyLocalizedField())
    this.quizEnabled = false
    this.quizImage.set(null)
    this.quizQuestions.forEach(this.removeQuestion)
    this.goldPoints.set(0)
    this.greenPoints.set(0)
    this.yellowPoints.set(0)
    this.hasQuiz = false

    this.mainValidator.resetErrors()
    this.quizValidator.resetErrors()
  }
}
