import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { nanoid } from 'nanoid'

export default {
  namespaced: true,
  state: {
    user: null,
    filter: {
      course: null,
      streams: [],
      lessons: [],
      mentors: [],
    },
    filterData: {
      courses: [],
      streams: [],
      lessons: [],
      mentors: [],
    },
    students: [],
    lessons: [],
    student: null,
    messages: [],
    lessonTitle: null,
    dialogStatus: null,
    dialogId: null,
    route: null,
    studentPending: false,
    chatPending: false,
    mentors: [],
    studentsForTable: [],
    sprints: [],
    homeworkTablePending: false,
    pendingStudents: false
  },
  getters: {
    homeworkTablePending: state => state.homeworkTablePending,
    getMentors: state => state.mentors,
    getSprints: state => state.sprints,
    getStudentsForTable: state => state.studentsForTable,
    studentPending: state => state.studentPending,
    chatPending: state => state.chatPending,
    getDialogId: state => state.dialogId,
    getLessonTitle: state => state.lessonTitle,
    getLesson: state =>
      state.lessons.find(lesson => lesson.id === state.dialogId),
    getMessages: state => state.messages,
    getStudents: state => state.students,
    getStudent: state => state.student,
    getLessons: state => state.lessons,
    getCourses: state => state.filterData.courses,
    getFilteredStreams: state => {
      return state.filter.course
        ? state.filterData.streams
            .filter(stream => stream.courseId === state.filter.course.id)
            .sort((s1, s2) => {
              const split1 = s1.dateStart.split('.')
              const date1 = new Date(
                [split1[1], split1[0], split1[2]].join('.'),
              ).getTime()
              const split2 = s2.dateStart.split('.')
              const date2 = new Date(
                [split2[1], split2[0], split2[2]].join('.'),
              ).getTime()

              return date2 - date1
            })
        : []
    },
    getFilteredLessons: state => {
      if (state.filter.mentors.length === 0) {
        return state.filterData.lessons
      } else {
        return state.filterData.lessons.filter(lesson =>
          state.filter.mentors.some(mentor =>
            mentor.lessonsIds.includes(lesson.id),
          ),
        )
      }
    },
    getFilteredMentors: state => {
      if (state.filter.lessons.length === 0) {
        return state.filterData.mentors
      } else {
        return state.filterData.mentors.filter(mentor =>
          state.filter.lessons.some(lesson =>
            lesson.mentorsIds.includes(mentor.id),
          ),
        )
      }
    },
    getCourseFilter: state => state.filter.course,
    getStreamFilter: state => state.filter.streams,
    getLessonFilter: state => state.filter.lessons,
    getMentorFilter: state => state.filter.mentors,
    getPendingStudents: state => state.pendingStudents,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setRoute(state, route) {
      state.route = route
    },
    setCourseFilter(state, course) {
      state.filter.course = course
      state.filter.lessons = []
      state.filter.streams = []
      state.filter.mentors = []
      state.students = []
      state.student = null
      state.dialogId = null
    },
    setStreamsFilter(state, streams) {
      state.filter.streams = streams
      state.filter.lessons = []
      state.filter.mentors = []
    },
    setLessonsFilter(state, lessons) {
      state.filter.lessons = lessons
    },
    setMentorsFilter(state, mentors) {
      state.filter.mentors = mentors
    },
    setCoursesAndStreams(state, data) {
      state.filterData.streams = []
      state.filterData.courses = data.courses.map(c => {
        state.filterData.streams = state.filterData.streams.concat(
          c.streams.map(s => {
            const day = s.dateStart.substring(8, 10)
            const month = s.dateStart.substring(5, 7)
            const year = s.dateStart.substring(0, 4)

            return {
              id: s.id,
              title: s.title,
              courseId: c.id,
              dateStart: `${day}.${month}.${year}`,
            }
          }),
        )
        return {
          id: c.id,
          title: c.title,
          imageUrl: c.imageUrl,
          hex: c.hexColor,
        }
      })
    },
    setLessonsAndMentors(state, data) {
      state.filterData.lessons = data.lessons
      state.filterData.mentors = data.mentors
    },
    setStudents(state, students) {
      students.forEach(student => {
        student.timeInt = new Date(student.lastMessageDate).getTime()
      })
      state.students = students
    },
    setDetailedStudentData(state, data) {
      state.lessons = data.lessons
      delete data['lessons']
      state.student = data
    },
    clearStudent(state) {
      state.lessons = []
      state.student = null
    },
    setDialogData(state, data) {
      let unreadIndex = 0

      for (let i = 0; i < data.messages.length; i++) {
        if (data.messages[i].isRead) unreadIndex = i + 1
      }
      if (data.messages.length !== unreadIndex) {
        data.messages.splice(unreadIndex, 0, {
          newMessagesLabel: true,
          messageId: -1,
        })
      }

      state.messages = data.messages
      state.lessonTitle = data.lesson
      state.dialogStatus = data.status
      state.dialogId = data.dialogId
    },
    addMessage(state, message) {
      state.messages = state.messages.filter(m => !m.newMessagesLabel)

      const lesson = state.lessons.find(lesson => lesson.id === state.dialogId)

      const student = state.students.find(
        student => student.id === state.student.id,
      )
      student.messagesCount -= lesson.unreadMessagesCount

      lesson.viewed = true
      lesson.lastMessageDate = message.created_at

      student.lastMessage = message.body
      student.lastMessageDate = Date.now()

      state.messages.push(message)
      lesson.messagesCount++
    },
    hideDialog(state) {
      state.dialogId = null
    },
    setDialogState(state, dialogState) {
      const lesson = state.lessons.find(lesson => lesson.id === state.dialogId)

      if (!lesson.viewed) {
        const student = state.students.find(
          student => student.id === state.student.id,
        )
        student.messagesCount -= lesson.unreadMessagesCount
      }

      lesson.isApprove = dialogState === 'success'
      lesson.isJustCreated = false
      lesson.isRepeated = false
      lesson.viewed = true

      state.messages.push({
        avatarUrl: user.avatar_url,
        body: 'Changing status',
        created_at: new Date(),
        id: nanoid(),
        isRead: true,
        isStatus: true,
        isStudent: false,
        isVideoReview: false,
        name: `${state.user.first_name} ${state.user.last_name}`,
        owner_id: state.user.id,
        status: dialogState === 'success' ? 'approved' : 'rejected',
      })

      state.messages = state.messages.filter(m => !m.newMessagesLabel)
    },
    setMentors(state, mentors) {
      state.mentors = mentors
    },
    setStudentsForTable(state, students) {
      const formatter = new Intl.DateTimeFormat('ru', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        minute: '2-digit',
        hour: '2-digit',
      })

      for (const student of students) {
        student.lessons = student.lessons.filter(l => !Array.isArray(l))

        for (const lesson of student.lessons) {
          lesson.date = formatter.format(new Date(lesson.date))
          if (lesson.homework) {
            if (lesson.homework.isJustCreated) lesson.status = 'none'
            else if (lesson.homework.isRepeated) lesson.status = 'repeated'
            else if (lesson.homework.isApprove) lesson.status = 'approved'
            else lesson.status = 'rejected'
          }
          if (lesson.test) {
            if (lesson.test.result === 'bad') {
              lesson.status = 'rejected'
            } else {
              lesson.status = 'approved'
            }
          }
          if (lesson.justPassed) {
            lesson.status = 'approved'
          }
        }
      }

      state.studentsForTable = students
    },
    setSprints(state, data) {
      data = data.sort((d1, d2) => d1.sprint.position - d2.sprint.position)

      state.sprints = data.map(el => {
        el.lessons = el.lessons.sort((l1, l2) => l1.position - l2.position)

        el.lessons.forEach(lesson => {
          lesson.date_start = new Date(lesson.date_start)
        })
        el.sprint.lessons = el.lessons
        return el.sprint
      })
    },
    setHomeworkTablePending(state, value) {
      state.homeworkTablePending = value
    },
    setPendingStudents(state, value) {
      state.pendingStudents = value
    },
  },
  actions: {
    async fetchStudents(ctx) {
      if (ctx.state.filter.streams.length === 0) {
        ctx.commit('setStudents', [])
        return
      }
      try {
        const streams = ctx.state.filter.streams.map(stream => stream.id)

        const lessons =
          ctx.state.filter.lessons.length === 0
            ? ''
            : `&lessons=[${ctx.state.filter.lessons.map(lesson => lesson.id)}]`

        const mentors =
          ctx.state.filter.mentors.length === 0
            ? ''
            : `&mentors=[${ctx.state.filter.mentors.map(mentor => mentor.id)}]`
        
        ctx.commit('setPendingStudents', true)
        const response = await axios.get(
          `v2/hw/students?streams=[${streams}]${lessons}${mentors}`,
        )
        ctx.commit('setStudents', response.data.data.students)
      } catch (error) {
        showError('Ошибка при получении списка студентов')
        console.error(error)
      } finally {
        ctx.commit('setPendingStudents', false)
      }
    },
    async fetchDetailedStudentData(ctx, studentId) {
      ctx.state.studentPending = true
      try {
        const streams = ctx.state.filter.streams.map(stream => stream.id)

        const lessons =
          ctx.state.filter.lessons.length === 0
            ? ''
            : `&lessons=[${ctx.state.filter.lessons.map(lesson => lesson.id)}]`

        const mentors =
          ctx.state.filter.mentors.length === 0
            ? ''
            : `&mentors=[${ctx.state.filter.mentors.map(mentor => mentor.id)}]`

        const response = await axios.get(
          `v2/hw/student?studentId=${studentId}&streams=[${streams}]${lessons}${mentors}`,
        )
        ctx.commit('setDetailedStudentData', response.data.data)
        ctx.state.studentPending = false
      } catch (error) {
        console.error(error)
        ctx.state.studentPending = false
      }
    },
    async fetchCoursesAndStreams(ctx) {
      try {
        const response = await axios.get('v1/courses-with-streams')
        ctx.commit('setCoursesAndStreams', response.data)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchLessonsAndMentors({ state, commit }) {
      if (state.filter.streams.length === 0) {
        commit('setLessonsAndMentors', { lessons: [], mentors: [] })
        return
      }
      try {
        const response = await axios.get(
          `v1/hw/lessons-and-mentors?streams=[${state.filter.streams.map(
            stream => stream.id,
          )}]`,
        )
        commit('setLessonsAndMentors', response.data.data)
      } catch (error) {
        console.error(error)
      }
    },
    updateRouteQuery({ state }) {
      const queryObject = {}
      let course = ''
      let streams = ''
      let lessons = ''
      let mentors = ''
      let student = ''
      let dialog = ''

      if (state.filter.course) {
        course = `?course=${state.filter.course.id}`
        queryObject.course = `${state.filter.course.id}`
      }

      if (state.filter.streams.length > 0) {
        const value = state.filter.streams.map(stream => stream.id)
        streams = `&streams=${value}`
        queryObject.streams = `${value}`
      }

      if (state.filter.lessons.length > 0) {
        const value = state.filter.lessons.map(lesson => lesson.id)
        lessons = `&lessons=${value}`
        queryObject.lessons = `${value}`
      }

      if (state.filter.mentors.length > 0) {
        const value = state.filter.mentors.map(mentors => mentors.id)
        mentors = `&mentors=${value}`
        queryObject.mentors = `${value}`
      }

      if (state.student) {
        student = `&student=${state.student.id}`
        queryObject.student = `${state.student.id}`

        if (state.dialogId) dialog = `&dialog=${state.dialogId}`
        queryObject.dialog = `${state.dialogId}`
      }

      const query = `${state.route}${course}${streams}${lessons}${mentors}${student}${dialog}`

      if (query && query !== 'null' && query !== '/homework') {
        history.replaceState(null, null, query)
      }
      return queryObject
    },
    async loadDataFromQuery({ state, commit, dispatch }, query) {
      const filterData = state.filterData

      if (query.course) {
        const courseId = parseInt(query.course)
        const course = filterData.courses.find(course => course.id === courseId)

        if (!course) return
        commit('setCourseFilter', course)
      } else {
        return
      }

      if (query.streams) {
        const streams = query.streams
          .split(',')
          .map(streamId => parseInt(streamId))

        commit(
          'setStreamsFilter',
          filterData.streams.filter(stream =>
            streams.some(id => id === stream.id),
          ),
        )

        await dispatch('fetchLessonsAndMentors')
      }
      if (query.lessons) {
        const lessons = query.lessons
          .split(',')
          .map(lessonId => parseInt(lessonId))

        commit(
          'setLessonsFilter',
          filterData.lessons.filter(lesson =>
            lessons.some(id => id === lesson.id),
          ),
        )
      }
      if (query.mentors) {
        const mentors = query.mentors
          .split(',')
          .map(mentorId => parseInt(mentorId))

        commit(
          'setMentorsFilter',
          filterData.mentors.filter(mentor =>
            mentors.some(id => id === mentor.id),
          ),
        )
      }

      await dispatch('fetchStudents')

      if (query.streams) {
        if (query.student) {
          dispatch('fetchDetailedStudentData', query.student)
        }

        if (query.dialog) {
          await dispatch('fetchDialog', query.dialog)
        }
      }
    },
    async fetchDialog(ctx, dialogId) {
      ctx.state.chatPending = true
      try {
        const response = await axios.get(`v1/chat/dialogs/${dialogId}`)
        ctx.commit('setDialogData', response.data.data)
        ctx.state.chatPending = false
      } catch (error) {
        console.error(error)
        ctx.state.chatPending = false
      }
    },
    async sendMessage(ctx, message) {
      try {
        const response = await axios.post(
          `v1/chat/dialogs/${ctx.state.dialogId}`,
          message,
        )
        ctx.commit('addMessage', response.data.data)
      } catch (error) {
        console.error(error)
        showError('Сообщение не отправлено')
      }
    },
    async changeDialogueState(ctx, state) {
      ctx.state.chatPending = true
      try {
        await axios.patch(`v1/chat/dialog/state/${ctx.state.dialogId}`, {
          type: state,
        })
        ctx.commit('setDialogState', state)
        ctx.state.chatPending = false
      } catch (error) {
        console.error(error)
        ctx.state.chatPending = false
      }
    },
    async fetchMentors({ commit }, streamId) {
      try {
        const response = await axios.get(
          `v1/hw/table/mentors?streamId=${streamId}`,
        )
        commit('setMentors', response.data.mentors)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchStudentsForTable({ commit }, { streamId, mentorId }) {
      try {
        const mentorFilter = mentorId ? `&mentor=${mentorId}` : ''
        const response = await axios.get(
          `v1/hw/students-and-lessons?stream=${streamId}${mentorFilter}`,
        )
        commit('setStudentsForTable', response.data.data)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchSprints({ commit }, streamId) {
      try {
        const response = await axios.get(
          `v1/streams/${streamId}/lessons/sprints`,
        )
        commit('setSprints', response.data.data)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
