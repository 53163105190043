import { loadFromLS, removeFromLS, saveToLS } from '@/helpers/localStorage'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { IUser } from '@/store/userStore'
import { userStore } from '@/store'
import router from '@/router'
import { showError } from '@/helpers/notifications'

export { login, logout, fetchUser, TOKEN_KEY }

const TOKEN_KEY = 'sanctum_token'

axios.interceptors.request.use(
  config => {
    const accessToken = loadFromLS(TOKEN_KEY)

    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error),
)

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error
    if (response && response.data.code === 401) {
      logout()
    }
    return Promise.reject(error)
  },
)

async function login(login: string, password: string) {
  try {
    const response: AxiosResponse<{
      token: string
    }> = await axios.post('v1/login', { login, password })

    saveToLS(TOKEN_KEY, response.data.token)

    await fetchUser()
  } catch (error) {
    console.error(error)
    showError('Введен неверный логин или пароль', 'Ошибка входа')
  }
}

function logout() {
  removeFromLS(TOKEN_KEY)
  userStore.clearUser()
  if (router.currentRoute.name !== 'login') {
    router.push({ name: 'login' })
  }
}

async function fetchUser() {
  try {
    const response: AxiosResponse<{
      user: IUser
    }> = await axios.post('v1/auth/check')

    userStore.setUser(response.data.user)
  } catch (error) {
    console.error(error)
  }
}
