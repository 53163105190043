import { Middleware } from '@/router/middlewarePipeline'
import { blogStore } from '@/store'

const blogEditMiddleware: Middleware = async function ({
  nextMiddleware,
  to,
  next,
}) {
  if (to.name === 'editArticle') {
    const id = parseInt(to.params.id)
    const result = await blogStore.fetchArticle(id)

    if (!result) {
      return next({ name: '404' })
    }

    blogStore.blogState = 'edit'
  } else {
    blogStore.blogState = 'new'
    blogStore.article = null
  }

  await blogStore.fetchTags()

  return nextMiddleware()
}

export default blogEditMiddleware
