import { showError } from './notifications'
import axios from '@/libs/axios'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export async function uploadImage(
  image: File,
  folder: string,
  progressChangedHandler?: (progressEvent: {
    loaded: number
    total: number
  }) => void,
) {
  try {
    const formData = new FormData()
    formData.append('folder', folder)
    formData.append('image', image)

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    if (progressChangedHandler) {
      config.onUploadProgress = progressChangedHandler
    }

    const response: AxiosResponse<{ url: string }> = await axios.post(
      'v2/uploads/images',
      formData,
      config,
    )
    return response.data.url
  } catch (error) {
    console.error(error)
    showError(`При загрузке изображения "${image.name}" произошла ошибка`)
    return null
  }
}
