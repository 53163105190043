import { RouteConfig } from 'vue-router'
import auth from '@/middlewares/auth'
import editWebinarMiddleware from '@/middlewares/webinarEditMiddleware'
import webinarInstancesMiddleware from '@/middlewares/webinarInstancesMiddleware'
import { Permission } from '@/enums/Permission'

const permissionsMiddleware =
  require('@/middlewares/permissionsMiddleware').default

export const webinarsRoutes: RouteConfig[] = [
  {
    path: '/webinars',
    name: 'webinars',
    component: () => import('@/views/webinars/Webinars.vue'),
    meta: {
      pageTitle: 'События',
      middleware: [auth], // TODO permissionsMiddleware
      permission: 'admin.webinars',
    },
  },
  {
    path: '/webinars/add',
    name: 'addWebinar',
    component: () => import('@/views/webinars/WebinarEdit.vue'),
    meta: {
      pageTitle: 'Добавить событие',
      middleware: [auth, editWebinarMiddleware], // TODO permissionsMiddleware
      permission: 'admin.webinars',
      breadcrumb: [
        {
          text: 'События',
          to: { name: 'webinars' },
        },
      ],
    },
  },
  {
    path: '/webinars/edit/:id',
    name: 'editWebinar',
    component: () => import('@/views/webinars/WebinarEdit.vue'),
    meta: {
      pageTitle: 'Редактировать событие',
      middleware: [auth, permissionsMiddleware, editWebinarMiddleware],
      permission: Permission.WEBINARS,
      breadcrumb: [
        {
          text: 'События',
          to: { name: 'webinars' },
        },
      ],
    },
  },
  {
    path: '/webinars/tags',
    name: 'webinar-tags',
    component: () => import('@/views/webinars/Tags.vue'),
    meta: {
      pageTitle: 'Теги',
      middleware: [auth, permissionsMiddleware],
      permission: Permission.WEBINARS,
      breadcrumb: [
        {
          text: 'События',
          to: { name: 'webinars' },
        },
      ],
    },
  },
  {
    path: '/webinars/:id',
    name: 'webinar',
    component: () => import('@/views/webinars/Instances.vue'),
    meta: {
      pageTitle: 'Экземпляры события',
      middleware: [auth, permissionsMiddleware, webinarInstancesMiddleware],
      permission: Permission.WEBINARS,
      breadcrumb: [
        {
          text: 'События',
          to: { name: 'webinars' },
        },
      ],
    },
  },
]
