<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-overlay rounded="sm" :show="isLoading" no-wrap />

    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo d-flex align-items-center">
          <b-img :src="appLogoImage" alt="logo" />
          <h2 class="brand-text text-primary ml-1 text-uppercase">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1 text-center">
          Добро пожаловать! 🚀
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Заполните поля данными, чтобы получить доступ к админ-центру
        </b-card-text>

        <form-wizard
          color="#385BD7"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Зарегистрироваться"
          back-button-text="Назад"
          next-button-text="Далее"
          class="mb-3 shadow-none"
          @on-complete="register"
        >
          <!-- personal details tab -->
          <tab-content
            title="Персональные данные"
            icon="feather icon-user"
            :before-change="validationFormInfo"
          >
            <validation-observer ref="infoRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Персональные данные</h5>
                  <small class="text-muted">
                    Заполните свои персональные данные
                  </small>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Имя" label-for="first-name">
                    <validation-provider
                      #default="{ errors }"
                      name="Имя"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="first-name"
                          v-model="form.first_name"
                          placeholder="Иван"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Фамилия" label-for="last-name">
                    <validation-provider
                      #default="{ errors }"
                      name="Фамилия"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="last-name"
                          v-model="form.last_name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Иванов"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Дата рождения"
                    label-for="birth-date-input"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Дата рождения"
                      rules="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <cleave
                          id="birth-date-input"
                          v-model="form.dob"
                          class="form-control"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          :raw="false"
                          :options="options.date"
                          placeholder="дд.мм.гггг"
                          autocomplete="off"
                          show-decade-nav
                          @blur="formatDate"
                        />

                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="datepickerBirthday"
                            show-decade-nav
                            start-weekday="1"
                            hide-header
                            v-bind="labels"
                            :max="maxDate"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            button-only
                            :button-variant="
                              errors.length > 0
                                ? 'outline-danger'
                                : 'outline-primary'
                            "
                            right
                            size="sm"
                            locale="ru-RU"
                            aria-controls="birth-date-input"
                            @context="onContext"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Номер телефона" label-for="phone">
                    <validation-provider
                      #default="{ errors }"
                      name="Номер телефона"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PhoneIcon" />
                        </b-input-group-prepend>

                        <cleave
                          id="phone"
                          v-model="form.phone"
                          class="form-control"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          :raw="false"
                          :options="options.phone"
                          placeholder="1 234 567 8900"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Telegram" label-for="telegram">
                    <validation-provider
                      #default="{ errors }"
                      name="Telegram"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        prepend="@"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="telegram"
                          v-model="form.telegram"
                          :state="errors.length > 0 ? false : null"
                          placeholder="user"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6">
                  <b-form-group label="Изображение профиля" label-for="avatar">
                    <validation-provider
                      #default="{ errors }"
                      name="Изображение профиля"
                      rules="required"
                    >
                      <div class="d-flex flex-row align-items-center">
                        <b-avatar class="mr-1" :src="avatarPreview" />

                        <b-form-file
                          id="avatar"
                          ref="avatarInput"
                          v-model="form.avatar"
                          :browse-text="
                            form.avatar === null ? 'Выбор' : 'Обновить'
                          "
                          placeholder=".jpg, .png"
                          drop-placeholder="Перетащите сюда..."
                          accept=".jpg, .png"
                          :state="avatarError"
                          @focus="avatarError = null"
                          @input="handleAvatarUpload"
                        />

                        <b-button
                          id="clear-pdf-button"
                          variant="flat-secondary"
                          class="btn-icon p-0 ml-1"
                          @click="clearAvatar"
                        >
                          <b-tooltip
                            target="clear-pdf-button"
                            :delay="{ show: tooltipDelay, hide: 0 }"
                          >
                            Очистить файл
                          </b-tooltip>
                          <feather-icon size="20" icon="Trash2Icon" />
                        </b-button>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- accoint details tab -->
          <tab-content
            title="Детали аккаунта"
            icon="feather icon-file-text"
            :before-change="validationForm"
          >
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Детали аккаунта</h5>
                  <small class="text-muted">Заполните данные об аккаунте</small>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="MailIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="email"
                          v-model="form.email"
                          type="email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="ivan.ivanov@email.com"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Пароль" label-for="password">
                    <validation-provider
                      #default="{ errors }"
                      name="Пароль"
                      vid="password"
                      rules="required|min:8"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LockIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="password"
                          v-model="form.password"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Пароль"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Подтверждение пароля"
                    label-for="c-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Подтверждение пароля"
                      rules="required|confirmed:password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="LockIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="c-password"
                          v-model="form.password_confirmation"
                          :type="passwordConfirmationFieldType"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Повторите пароль"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordConfirmationVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- address  -->
          <tab-content
            title="Адрес"
            icon="feather icon-map-pin"
            :before-change="validationFormAddress"
          >
            <validation-observer ref="addressRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">Адрес</h5>
                  <small class="text-muted">Заполните адресные данные</small>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Страна" label-for="country">
                    <validation-provider
                      #default="{ errors }"
                      name="Страна"
                      rules="required"
                    >
                      <wayup-select
                        v-model="selectedCountry"
                        placeholder="Выберите страну"
                        empty-filter-message="Страны не найдены"
                        :options="countries"
                        label="name"
                        :state="errors.length > 0 ? false : null"
                        :clearable="false"
                        @change="onCountryChanged"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Город" label-for="city">
                    <validation-provider
                      #default="{ errors }"
                      name="Город"
                      rules="required"
                    >
                      <wayup-select
                        v-model="selectedCity"
                        placeholder="Выберите город"
                        :disabled="form.country === null"
                        empty-filter-message="Города не найдены"
                        :options="cities"
                        label="name"
                        :pending="citiesPending"
                        :state="errors.length > 0 ? false : null"
                        :clearable="false"
                        @change="onCityChanged"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Улица" label-for="street">
                    <validation-provider
                      #default="{ errors }"
                      name="Улица"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HomeIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="street"
                          v-model="form.street"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="(Не указано)"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Дом" label-for="house">
                    <validation-provider
                      #default="{ errors }"
                      name="Дом"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HomeIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="house"
                          v-model="form.house"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="(Не указано)"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Строение" label-for="block">
                    <validation-provider #default="{ errors }" name="Строение">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HomeIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="block"
                          v-model="form.block"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          placeholder="(Не указано)"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Квартира" label-for="apartment">
                    <validation-provider
                      #default="{ errors }"
                      name="Квартира"
                      rules="required|integer"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HomeIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="apartment"
                          v-model="form.apartment"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="(Не указано)"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Почтовый индекс" label-for="post-index">
                    <validation-provider
                      #default="{ errors }"
                      name="Почтовый индекс"
                      rules="required|min:1"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="InboxIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="post-index"
                          v-model="form.post_index"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="123456"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="№ отделения «Новой почты»"
                    label-for="post-office"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="№ отделения «Новой почты»"
                      rules="integer"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="InboxIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="post-office"
                          v-model="form.post_office"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="123456"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>

        <b-card-text class="text-center mt-2">
          <span>Уже есть аккаунт?</span>
          <b-link :to="{ name: 'login' }">
            <span>Войдите прямо сейчас</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BImg,
  BFormDatepicker,
  BFormFile,
  BTooltip,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import { required, email, min, integer } from '@validations'
import { $themeConfig } from '@themeConfig'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import WayupSelect from '@/components/WayupSelect.vue'
import { showError } from '@/helpers/notifications'
import { userStore } from '@/store'
import { saveToLS } from '@/helpers/localStorage'
import { fetchUser, TOKEN_KEY } from '@/@core/auth/auth'
import axios from '@/libs/axios'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BImg,
    BFormDatepicker,
    BFormFile,
    BTooltip,
    BAvatar,
    BOverlay,

    FormWizard,
    TabContent,
    Cleave,
    WayupSelect,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // validation rules
      required,
      email,
      min,
      integer,

      form: {
        first_name: '',
        last_name: '',
        dob: null,
        phone: '',
        telegram: '',
        avatar: null,
        email: '',
        password: '',
        password_confirmation: '',
        country: null,
        city: null,
        street: '',
        house: '',
        block: '',
        apartment: null,
        post_index: null,
        post_office: null,
      },
      datepickerBirthday: '',
      avatarPreview: null,
      avatarError: null,
      tooltipDelay: 500,
      selectedCountry: null,
      selectedCity: null,
      labels: {
        labelPrevYear: 'Предыдущий год',
        labelPrevMonth: 'Предыдущий месяц',
        labelPrevDecade: 'Предыдущее десятилетие',
        labelCurrentMonth: 'Текущий месяц',
        labelNextMonth: 'Следующий месяц',
        labelNextYear: 'Следующий год',
        labelNextDecade: 'Следующее десятилетие',
        labelToday: 'Сегодня',
        labelSelected: 'Выбранная дата',
        labelNoDateSelected: 'Нет выбранной даты',
        labelCalendar: 'Календарь',
        labelNav: 'Навигация',
        labelHelp: '',
      },
      options: {
        date: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'RU',
        },
      },
      isLoading: false,
      citiesPending: false,
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries',
      cities: 'getCities',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    maxDate() {
      return new Date().toISOString().slice(0, 10)
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchCountries')
  },
  methods: {
    handleAvatarUpload() {
      const uploadedFile = this.$refs.avatarInput.files[0]
      if (!uploadedFile) return
      if (uploadedFile.size > 10240 * 1024) {
        this.avatarError = false
        this.$refs.avatarInput.reset()
        showError('Размер файла не должен превышать 10 MB')
        return
      }
      this.form.avatar = uploadedFile
      this.setAvatarPreview(uploadedFile)
      this.avatarError = null
    },
    setAvatarPreview(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            this.avatarPreview = reader.result
          },
          false,
        )
        reader.readAsDataURL(file)
      }
    },
    clearAvatar() {
      this.form.avatar = null
      this.avatarPreview = null
      this.avatarError = null
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    onContext(ctx) {
      this.form.dob =
        ctx.selectedFormatted === 'Нет выбранной даты'
          ? ''
          : ctx.selectedFormatted
      this.datepickerBirthday = ctx.selectedYMD
    },
    formatDate(date) {
      this.datepickerBirthday = date.split('.').reverse().join('-')
    },
    async onCountryChanged() {
      this.form.country = this.selectedCountry.id
      this.citiesPending = true
      await this.$store.dispatch('fetchCities', this.selectedCountry.id)
      this.selectedCity = null
      this.form.city = null
      this.citiesPending = false
    },
    onCityChanged() {
      this.form.city = this.selectedCity.id
    },
    async register() {
      this.isLoading = true

      const formData = new FormData()
      Object.keys(this.form).forEach(item =>
        formData.append(item, this.form[item]),
      )
      formData.append('invite_hash', this.$route.query.invite)

      try {
        const response = await axios.post('v1/register', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        if (response) {
          const { data } = response
          saveToLS(TOKEN_KEY, data.token)
          await fetchUser()
          this.$router.push({ name: 'home' })
        }
      } catch (error) {
        console.error(error)
        const { errors } = error.response.data
        showError(errors[Object.keys(errors)[0]][0])
      }

      this.isLoading = false
    },
  },
  setup() {
    // App Name
    localize('ru')

    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.auth-wrapper.auth-v1 {
  .auth-inner {
    max-width: 1000px !important;
  }
}

.dark-layout {
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
