import Vue from 'vue'
import { showError } from './notifications'
import declination from './declination'
import { Language, languages, LocalizedField } from '@/store/i18nStore'

export type Validator = (value: any) => ValidatorInfo | undefined

type ValidatorInfo = { error?: string; notification?: string }

type FieldConstructorProps<T> = {
  value: T
  validators?: Array<Validator>
  validateOnChange?: boolean
  ignoreValidation?: (value: T) => boolean
  group?: ValidationGroup
  dependentFields?: FieldValidator<any>[]
  localized?: boolean
}
type ProviderConstructorProps<T> = {
  getter: () => T
  setter: (value: T) => void
  validators?: Array<Validator>
  validateOnChange?: boolean
  ignoreValidation?: (value: T) => boolean
  group?: ValidationGroup
  dependentFields?: FieldValidator<any>[]
  localized?: boolean
}

export class FieldValidator<T> {
  public errorText: string | null = null
  public hasError: boolean = false
  private _notification: string | null = null

  private _value?: T
  private _setter?: (value: T) => void
  private _getter?: () => T
  private _validateOnChange: boolean
  private _validators?: Array<Validator>
  private _ignoreValidation?: (value: T) => boolean
  private _localized?: boolean
  private _dependentFields?: FieldValidator<any>[]

  private type: 'field' | 'provider'

  get value() {
    if (this.type === 'field') {
      return this._value!
    }

    return this._getter!()
  }
  set value(value) {
    if (this.type === 'field') {
      this._value = value
    } else {
      this._setter!(value!)
    }

    this.reset()

    if (this._validateOnChange) {
      if (this._dependentFields) {
        this._dependentFields.forEach(f => f.validate())
      }

      this.validate()
    }
  }

  constructor(props: FieldConstructorProps<T> | ProviderConstructorProps<T>) {
    const _props = props as FieldConstructorProps<T> &
      ProviderConstructorProps<T>

    if (_props.value !== undefined) {
      this._value = Vue.observable(_props.value)

      this.type = 'field'
    } else {
      this._getter = _props.getter
      this._setter = _props.setter

      this.type = 'provider'
    }
    this._validators = props.validators
    this._validateOnChange = props.validateOnChange === false ? false : true
    this._ignoreValidation = _props.ignoreValidation
    this._localized = _props.localized
    this._dependentFields = _props.dependentFields

    if (props?.group) {
      props.group.addField(this)
    }
  }

  public set = (value: T) => {
    this.value = value
  }

  public setError(error: string) {
    this.hasError = true
    this.errorText = error
  }

  public validate() {
    this.reset()

    if (!this._validators) {
      return true
    }

    if (this._ignoreValidation) {
      if (this._ignoreValidation(this.value)) {
        return true
      }
    }

    if (this._localized) {
      const errors: Partial<Record<Language, ValidatorInfo>> = {}
      for (const lang of languages) {
        for (const validator of this._validators) {
          const value = (this.value as LocalizedField)[lang as Language] ?? ''

          const error = validator(value)

          if (error) {
            errors[lang as Language] = error
            break
          }
        }
      }

      if (Object.keys(errors).length) {
        this.errorText = ''
        this._notification = ''
        for (const key in errors) {
          this.errorText += `${key}: ${errors[key as Language]?.error}<br/>`
          if (errors[key as Language]?.notification) {
            this._notification += `${key}: ${
              errors[key as Language]?.notification
            }; `
          }
        }

        if (this._notification) {
          this._notification = this._notification.slice(0, -2)
        }
        this.hasError = true
        return false
      }
    } else {
      for (const validator of this._validators) {
        const error = validator(this.value)

        if (error !== undefined) {
          this.hasError = true
          this.errorText = error?.error || null
          this._notification = error?.notification || null

          return false
        }
      }
    }

    return true
  }

  public showErrorNotification() {
    if (this._notification) {
      showError(this._notification)
    }
  }

  public toString() {
    if (this.value != null) {
      return (this.value as any).toString()
    }
    return ''
  }

  public reset() {
    this.hasError = false
    this.errorText = ''
    this._notification = null
  }
}

export class ValidationGroup {
  private _fields: FieldValidator<any>[] = []

  public get hasErrors() {
    return this._fields.some(f => f.hasError)
  }

  public addField(value: FieldValidator<any>) {
    this._fields.push(value)
  }

  public removeField(value: FieldValidator<any>) {
    this._fields = this._fields.filter(f => f !== value)
  }

  public validate() {
    let result = true

    this._fields.forEach(f => {
      if (!f.validate()) {
        result = false
      }
    })
    return result
  }

  public resetErrors() {
    this._fields.forEach(f => f.reset())
  }

  public showErrorNotifications() {
    this._fields.forEach(f => f.showErrorNotification())
  }
}

// VALIDATORS

export function notEmpty(props?: ValidatorInfo): Validator {
  const error = props?.error || 'Заполните поле'

  return (value: any) => {
    if (Array.isArray(value) && !value.length) {
      return { error, notification: props?.notification }
    }
    if (typeof value === 'string' && value.trim() === '') {
      return { error, notification: props?.notification }
    }
    if (value === null || value === undefined) {
      return { error, notification: props?.notification }
    }
  }
}

export function notSpaces(props?: ValidatorInfo): Validator {
  const error = props?.error || 'Поле не должно содержать пробелов'

  return (value: string) => {
    if (value && value.includes(' ')) {
      return { error, notification: props?.notification }
    }
  }
}

export function greater(min: number, props?: ValidatorInfo): Validator {
  const error = props?.error || `Значение должно быть больше ${min}`

  return (value: number) => {
    if (value <= min) {
      return { error, notification: props?.notification }
    }
  }
}

export function greaterOrEqual(min: number, props?: ValidatorInfo): Validator {
  const error = props?.error || `Значение должно быть больше или равно ${min}`

  return (value: number) => {
    if (value < min) {
      return { error, notification: props?.notification }
    }
  }
}

export function less(max: number, props?: ValidatorInfo): Validator {
  const error = props?.error || `Значение должно быть меньше ${max}`

  return (value: number) => {
    if (value >= max) {
      return { error, notification: props?.notification }
    }
  }
}

export function lessOrEqual(max: number, props?: ValidatorInfo): Validator {
  const error = props?.error || `Значение должно быть меньше или равно ${max}`

  return (value: number) => {
    if (value > max) {
      return { error, notification: props?.notification }
    }
  }
}

export function minLength(min: number, props?: ValidatorInfo): Validator {
  const error =
    props?.error ||
    `Поле должно быть не короче ${min} ${declination(min, [
      'символа',
      'символов',
      'символов',
    ])}`

  return (value: string) => {
    if (value.length < min) {
      return { error, notification: props?.notification }
    }
  }
}

export function maxLength(max: number, props?: ValidatorInfo): Validator {
  const error =
    props?.error ||
    `Поле должно быть не длинее ${max} ${declination(max, [
      'символа',
      'символов',
      'символов',
    ])}`

  return (value: string) => {
    if (value.length > max) {
      return { error, notification: props?.notification }
    }
  }
}

export function isEmail(props?: ValidatorInfo): Validator {
  const error = props?.error || 'Введите корректный e-mail'

  return (value: string) => {
    const re = /\S+@\S+\.\S+/
    if (!re.test(value)) {
      return { error, notification: props?.notification }
    }
  }
}

export function isUrl(props?: ValidatorInfo): Validator {
  const error = props?.error || 'Введите корректный url'

  return (value: string) => {
    const re =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,90}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    if (!re.test(value)) {
      return { error, notification: props?.notification }
    }
  }
}
