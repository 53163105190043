import { createModule, mutation, action } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError } from '@/helpers/notifications'
import { ICourse } from './salaryStore'

export interface IPaymentMethod {
  id: number
  title: string
}

export default class SalarySettingsStore extends createModule({
  namespaced: 'salarySettings',
  strict: false,
}) {
  private _paymentMethods: IPaymentMethod[] = []
  private _currentCourse: ICourse | null = null

  get paymentMethods() {
    return this._paymentMethods
  }
  get currentCourse() {
    return this._currentCourse
  }

  @mutation public setCurrentCourse(course: ICourse | null) {
    this._currentCourse = course
  }
  @mutation private setPaymentMethods(paymentMethods: IPaymentMethod[]) {
    this._paymentMethods = paymentMethods
  }
  @mutation private addPaymentMethod(paymentMethod: IPaymentMethod) {
    this._paymentMethods.push(paymentMethod)
  }
  @mutation private removePaymentMethod(id: number) {
    this._paymentMethods = this._paymentMethods.filter(m => m.id !== id)
  }

  @action async fetchPaymentMethods() {
    try {
      const response: AxiosResponse<{
        paymentMethods: IPaymentMethod[]
      }> = await axios.get('v1/salary/payment-methods')
      this.setPaymentMethods(response.data.paymentMethods)
    } catch (error) {
      showError('При получении типов оплаты произошла ошибка')
      console.error(error)
    }
  }
  @action async addPaymentType(title: string) {
    try {
      const response: AxiosResponse<{
        paymentMethod: IPaymentMethod
      }> = await axios.post('v1/salary/payment-methods', {
        title,
      })
      this.addPaymentMethod(response.data.paymentMethod)
      return true
    } catch (error) {
      showError('При добавлении типа оплаты произошла ошибка')
      console.error(error)
      return false
    }
  }
  @action async deletePaymentMethod(id: number) {
    try {
      await axios.delete(`v1/salary/payment-methods/${id}`)
      this.removePaymentMethod(id)
    } catch (error) {
      showError('При удалении типа оплаты произошла ошибка')
      console.error(error)
    }
  }
}
