import { Middleware } from '@/router/middlewarePipeline'
import { userStore } from '@/store'

const authMiddleware: Middleware = function({ next, nextMiddleware }) {
  if (!userStore.isLoggedIn) {
    return next({ name: 'login' })
  }
  return nextMiddleware()
}

export default authMiddleware
