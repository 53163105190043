import Vue from 'vue'
import VueRouter from 'vue-router'
import store, { commonStore } from '@/store'
import middlewarePipeline, { Middleware } from './middlewarePipeline'
import auth from '@/middlewares/auth'
import { coursesRoutes } from './modules/courses'
import { streamsRoutes } from './modules/streams'
import { webinarsRoutes } from './modules/webinars'
import { studentsRoutes } from './modules/students'
import { promotionsRoutes } from './modules/promotions'
import { blogRoutes } from './modules/blog'
import { Permission } from '@/enums/Permission'
import partnersMiddleware from '@/middlewares/partnersMiddleware'

const userMiddleware = require('@/middlewares/userMiddleware').default
const registerMiddleware = require('@/middlewares/registerMiddleware').default
const permissionsMiddleware =
  require('@/middlewares/permissionsMiddleware').default

const register = require('@/views/Register').default

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...coursesRoutes,
    ...streamsRoutes,
    ...studentsRoutes,
    ...promotionsRoutes,
    ...webinarsRoutes,
    ...blogRoutes,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home'),
      meta: {
        pageTitle: 'Home',
        middleware: [auth],
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/homework',
      name: 'homework',
      component: () => import('@/views/homework/HomeworkReview.vue'),
      meta: {
        middleware: [auth, permissionsMiddleware],
        permission: Permission.HOMEWORK,
      },
    },
    {
      path: '/prices',
      name: 'prices',
      component: () => import('@/views/payments/Prices.vue'),
      meta: {
        middleware: [auth, permissionsMiddleware],
        permission: Permission.PROMOTIONS,
      },
    },
    {
      path: '/payments',
      name: 'payments',
      component: () => import('@/views/payments/Payments'),
      meta: {
        pageTitle: 'Платежи',
        middleware: [auth, permissionsMiddleware],
        permission: Permission.PAYMENTS,
      },
    },
    {
      path: '/promocodes',
      name: 'promocodes',
      component: () => import('@/views/Promocodes'),
      meta: {
        pageTitle: 'Промокоды',
        middleware: [auth, permissionsMiddleware],
        permission: Permission.COUPONS,
      },
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins/Admins.vue'),
      meta: {
        pageTitle: 'Список администраторов',
        middleware: [auth, permissionsMiddleware],
        permission: Permission.ADMINISTRATORS,
      },
    },
    {
      path: '/user/:id',
      name: 'adminInfo',
      component: () => import('@/views/admins/AdminInfo.vue'),
      meta: {
        pageTitle: 'Пользователь',
        middleware: [auth, userMiddleware, permissionsMiddleware],
        permission: Permission.ADMINISTRATORS,
      },
    },
    {
      path: '/invites',
      name: 'invites',
      component: () => import('@/views/invites/Invites.vue'),
      meta: {
        pageTitle: 'Управление инвайтами',
        middleware: [auth, permissionsMiddleware],
        permission: Permission.ADMINISTRATORS,
      },
    },
    {
      path: '/user-groups',
      name: 'userGroups',
      component: () => import('@/views/user-groups/UserGroups.vue'),
      meta: {
        pageTitle: 'Управление группами пользователей',
        middleware: [auth, permissionsMiddleware],
        permission: Permission.ADMINISTRATORS,
      },
    },
    {
      path: '/profile/settings',
      name: 'userSettings',
      component: () => import('@/views/user-settings/UserSettings.vue'),
      meta: {
        pageTitle: 'Настройки профиля',
        middleware: [auth],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: register,
      meta: {
        layout: 'full',
        middleware: [registerMiddleware],
      },
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: () => import('@/views/Analytics.vue'),
      meta: {
        pageTitle: 'Аналитика',
        middleware: [permissionsMiddleware],
        permission: Permission.ANALYTICS,
      },
    },
    {
      path: '/salary',
      name: 'salary',
      component: () => import('@/views/Salary.vue'),
      meta: {
        pageTitle: 'Расчет ЗП',
        middleware: [permissionsMiddleware],
        permission: Permission.SALARY,
      },
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/Partners'),
      meta: {
        pageTitle: 'Партнерская программа',
        middleware: [permissionsMiddleware, partnersMiddleware],
        permission: Permission.PARTNERS,
      },
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
    },
  ],
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middlewares: Middleware[] = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store,
  }

  commonStore.pageTransitionActive = true
  setTimeout(() => {
    if (commonStore.pageTransitionActive) {
      commonStore.pageOverlayEnabled = true
    }
  }, 100)

  return middlewares[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middlewares, 1),
  })
})

router.afterEach(() => {
  commonStore.pageTransitionActive = false
  commonStore.pageOverlayEnabled = false
})

export default router
