import axios from '@/libs/axios'

export default {
  state: {
    mentors: [],
    teachers: [],
  },
  getters: {
    getMentors: state => state.mentors,
    getTeachers: state => state.teachers,
  },
  mutations: {
    setMentors(state, mentors) {
      state.mentors = mentors
    },
    setTeachers(state, teachers) {
      state.teachers = teachers
    },
  },
  actions: {
    async fetchMentors(ctx) {
      try {
        const response = await axios.get('v1/mentors')
        ctx.commit('setMentors', response.data.mentors)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchTeachers(ctx) {
      try {
        const response = await axios.get('v1/teachers')
        ctx.commit('setTeachers', response.data.teachers)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
