import { Middleware } from '@/router/middlewarePipeline'
import { courseStore } from '@/store'

const courseEditMiddleware: Middleware = async function ({
  nextMiddleware,
  to,
  next,
}) {
  await courseStore.reset()
  await courseStore.fetchMentors()
  await courseStore.fetchTeachers()
  await courseStore.fetchTags()

  if (to.name === 'editCourse') {
    courseStore.courseState = 'edit'
    const courseId = to.params.id
    const result = await courseStore.fetchCourse(courseId)
    if (!result) {
      return next({ name: '404' })
    }
  } else {
    courseStore.courseState = 'new'
  }

  return nextMiddleware()
}

export default courseEditMiddleware
