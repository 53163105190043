import { createModule, mutation, action, getter } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError, showMessage } from '@/helpers/notifications'
import { LocalizedField } from './i18nStore'
import { loadFromLS } from '@/helpers/localStorage'

interface ISprintsAndLessons {
  sprint: {
    id: number
    title: LocalizedField
    stream_id: number
    position: number
    description: LocalizedField
  }
  lessons: {
    id: number
    title: string
    description: string
    date_start: string
    live: boolean
    live_url: string | null
    type: number
    position: number
    note: string | null
    attaches: unknown[]
    video: {
      video_fragments: string | null
      url: string | null
      play_time: unknown | null
      urls_video: Record<string, string>
    }
  }[]
}

export type Sprint = {
  id: number
  title: LocalizedField
  stream_id: number
  position: number
  description: LocalizedField
  collapsed: boolean
  lessons: Lesson[]
  index: number
}

export type Lesson = {
  id: number
  title: string
  description: string
  date_start: string
  live: boolean
  live_url: string | null
  type: number
  position: number
  note: string | null
  attaches: unknown[]
  sprintId: number
  streamId: number
  index: number
}

export default class SprintsStore extends createModule({
  namespaced: 'sprints',
  strict: false,
}) {
  @getter sprints: Sprint[] = []
  @getter lessonDragged = false
  @getter pending = false
  @getter search = ''
  @getter streamId = -1

  @mutation
  private setSprintsAndLessons(data: ISprintsAndLessons[]) {
    const collapsedStates =
      loadFromLS<Record<number, boolean>>('sprintCollapseStates') || {}

    data = data.sort((el1, el2) => el1.sprint.position - el2.sprint.position)

    let sprintIndex = 1
    let lessonIndex = 1

    this.sprints = data.map(el => {
      el.lessons = el.lessons.sort((l1, l2) => l1.position - l2.position)

      return {
        ...el.sprint,
        title: el.sprint.title,
        collapsed:
          this.sprints.find(s => s.id === el.sprint.id)?.collapsed ||
          collapsedStates[el.sprint.id] ||
          false,
        index: sprintIndex++,
        lessons: el.lessons.map(lesson => ({
          ...lesson,
          index: lessonIndex++,
          sprintId: el.sprint.id,
          streamId: el.sprint.stream_id,
        })),
      }
    })
  }

  @action
  async changeSprintPosition({
    id,
    position,
  }: {
    id: number
    position: number
  }) {
    try {
      this.pending = true
      await axios.patch(`v2/sprints/${id}/position`, { position })

      await this.fetchSprintsAndLessons(this.streamId)
    } catch (error) {
      console.error(error)
      showError('При изменении позиции спринта произошла ошибка')
      this.pending = false
    }
  }

  @action
  async changeLessonPosition({
    id,
    position,
  }: {
    id: number
    position: number
  }) {
    try {
      this.pending = true
      await axios.patch(`v1/lessons/${id}/position`, { position })

      await this.fetchSprintsAndLessons(this.streamId)
    } catch (error) {
      console.error(error)
      showError('При изменении позиции урока произошла ошибка')
      this.pending = false
    }
  }

  @action
  async changeLessonSprint({ id, sprintId }: { id: number; sprintId: number }) {
    try {
      this.pending = true
      await axios.patch(`v1/lessons/${id}/move-to-sprint`, { sprintId })
    } catch (error) {
      showError('При перемещении урока в другой спринт произошла ошибка')
      this.pending = false
    }
  }

  @action
  async fetchSprintsAndLessons(streamId: number | string) {
    try {
      const response: AxiosResponse<{
        data: ISprintsAndLessons[]
      }> = await axios.get(`v1/streams/${streamId}/lessons/sprints`)
      this.setSprintsAndLessons(response.data.data)
      this.pending = false
      return true
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при загрузке потоков')
      this.pending = false
      return false
    }
  }

  @action
  async removeSprint(id: number) {
    try {
      this.pending = true
      await axios.delete(`v2/sprints/${id}`)
      await this.fetchSprintsAndLessons(this.streamId)
      showMessage('Спринт успешно удалён')
    } catch (error) {
      console.error(error)
      showError('При удалении спринта произошла ошибка')
    }
    this.pending = false
  }

  @action
  async removeLesson(id: number) {
    try {
      this.pending = true
      await axios.delete(`v2/lessons/${id}`)
      await this.fetchSprintsAndLessons(this.streamId)
      showMessage('Урок успешно удалён')
    } catch (error) {
      console.error(error)
      showError('При удалении урока произошла ошибка')
    }
    this.pending = false
  }
}
