import { Middleware } from '@/router/middlewarePipeline'
import { webinarStore } from '@/store'

const editWebinarMiddleware: Middleware = async function({
  to,
  next,
  nextMiddleware,
}) {
  webinarStore.reset()

  if (to.name === 'editWebinar') {
    const webinarId = parseInt(to.params.id)
    const result = await webinarStore.fetchWebinar(webinarId)

    if (!result) return next({ name: '404' })
    webinarStore.setState('edit')
  } else {
    webinarStore.setState('new')
  }

  return nextMiddleware()
}

export default editWebinarMiddleware
