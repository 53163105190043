import { Middleware } from '@/router/middlewarePipeline'
import { partnersStore } from '@/store'

const partnersMiddleware: Middleware = async function({ nextMiddleware }) {
  await partnersStore.fetchCommonData()

  return nextMiddleware()
}

export default partnersMiddleware
