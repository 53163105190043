import { TOKEN_KEY } from '@/@core/auth/auth'
import { loadFromLS } from '@/helpers/localStorage'
import { createModule, mutation } from 'vuex-class-component'

export interface IUser {
  id: number
  first_name: string
  last_name: string
  email: string
  phone: string
  telegram: string | null
  dob: string
  avatar_url: string | null
  street: string
  house: string
  block: any | null
  position: string
  achievements: string
  country_id: number
  city_id: number
  apartment: string
  post_index: string
  post_office: number
  group: {
    title: string
    permissions: Record<string, boolean>
  }
  access: string
  email_verified: boolean
}
export default class UserStore extends createModule({
  namespaced: 'user',
  strict: false,
}) {
  private _user: IUser | null = null

  get user() {
    return this._user
  }
  get isLoggedIn() {
    return this._user !== null && loadFromLS(TOKEN_KEY)
  }

  @mutation setUser(user: IUser) {
    this._user = user
  }

  @mutation clearUser() {
    this._user = null
  }

  //TODO
  @mutation updateUser(userData: any) {
    if (!this._user) {
      return
    }

    this._user.first_name = userData.first_name
    this._user.last_name = userData.last_name
    this._user.avatar_url = userData.avatar_url ?? this._user.avatar_url
  }
}
