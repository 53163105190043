export function saveToLS(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function loadFromLS<T>(key: string): T | null {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : null
}

export function removeFromLS(key: string) {
  localStorage.removeItem(key)
}
