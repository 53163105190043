import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export function showMessage(message, label = 'Сообщение') {
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title: label,
      icon: 'BellIcon',
      text: message,
      variant: 'primary',
    },
  })
}

export function showError(message, label = 'Ошибка') {
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title: label,
      icon: 'AlertCircleIcon',
      text: message,
      variant: 'danger',
    },
  })
}
