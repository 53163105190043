import axios from '@/libs/axios'
import { showMessage, showError } from '@/helpers/notifications'

export default {
  state: {
    sprints: [],
    lessons: [],
    currentLesson: null,
  },
  getters: {
    getSprints: state => state.sprints,
    getLessons: state => state.lessons,
    getCurrentLesson: state => state.currentLesson,
  },
  mutations: {
    setSprints(state, sprints) {
      state.sprints = sprints
    },
    setLesson(state, lesson) {
      state.currentLesson = lesson
    },
    setSprintAndLessons(state, lessonsData) {
      lessonsData = lessonsData.sort(
        (d1, d2) => d1.sprint.position - d2.sprint.position,
      )
      state.sprints = lessonsData.map(el => el.sprint)

      for (let i = 0; i < state.sprints.length; i++) {
        state.sprints[i].index = i + 1
      }

      state.lessons = []
      lessonsData.forEach(el => {
        el.sprint.lessonsCount = el.lessons.length
        el.lessons = el.lessons.sort((l1, l2) => l1.position - l2.position)
        for (let i = 0; i < el.lessons.length; i++) {
          el.lessons[i].positionText = `${el.sprint.index}-${i + 1}`
        }
        el.lessons.forEach(l => {
          l.sprintId = el.sprint.id
          l.streamId = el.sprint.stream_id
        })
        state.lessons = state.lessons.concat(el.lessons)
      })
    },
    deleteLesson(state, id) {
      state.lessons = state.lessons.filter(l => l.id !== id)
    },
    addSprint(state, sprint) {
      sprint.index = state.sprints.length + 1
      state.sprints.push(sprint)
    },
    editSprint(state, sprintData) {
      const sprint = state.sprints.find(s => s.id === sprintData.id)
      sprint.title = sprintData.title
      sprint.description = sprintData.description
    },
    deleteSprint(state, id) {
      state.sprints = state.sprints.filter(s => s.id !== id)
    },
  },
  actions: {
    async fetchSprintAndLessons(ctx, streamId) {
      try {
        const response = await axios.get(
          `v1/streams/${streamId}/lessons/sprints`,
        )
        ctx.commit('setSprintAndLessons', response.data.data)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async fetchLesson(ctx, lessonId) {
      try {
        const response = await axios.get(`v1/lessons/${lessonId}`)
        ctx.commit('setLesson', response.data.data)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async updateLessonPosition(ctx, lessonData) {
      try {
        await axios.patch(`v1/lessons/${lessonData.id}/position`, {
          position: lessonData.position,
        })
        ctx.dispatch('fetchSprintAndLessons', lessonData.streamId)
      } catch (error) {
        console.error(error)
      }
    },
    async updateSprintPosition(ctx, sprintData) {
      try {
        await axios.patch(`v1/sprints/${sprintData.id}/position`, {
          position: sprintData.position,
        })
        ctx.dispatch('fetchSprintAndLessons', sprintData.streamId)
      } catch (error) {
        console.error(error)
      }
    },
    async deleteLesson(ctx, lessonId) {
      try {
        await axios.delete(`v1/lessons/${lessonId}`)
        ctx.commit('deleteLesson', lessonId)
        showMessage('Занятие удалено', 'Успех')
      } catch (error) {
        console.error(error)
        showError('При удалении занятия произошла ошибка')
        return false
      }
    },
    async addSprint(ctx, sprintData) {
      try {
        const response = await axios.post('v1/sprints', sprintData)
        ctx.commit('addSprint', response.data.data)
        showMessage('Спринт добавлен', 'Успех')
        return true
      } catch (error) {
        console.error(error)
        showError('При добавлении спринта произошла ошибка')
        return false
      }
    },
    async editSprint(ctx, sprintData) {
      try {
        await axios.patch(`v1/sprints/${sprintData.id}`, sprintData)
        ctx.commit('editSprint', sprintData)
        showMessage('Спринт сохранен', 'Успех')
        return true
      } catch (error) {
        console.error(error)
        showError('При сохранении спринта произошла ошибка')
        return false
      }
    },
    async deleteSprint(ctx, sprintId) {
      try {
        await axios.delete(`v1/sprints/${sprintId}`)
        ctx.commit('deleteSprint', sprintId)
        showMessage('Спринт удален', 'Успех')
        return true
      } catch (error) {
        console.error(error)
        showError('При удалении спринта произошла ошибка')
        return false
      }
    },
    async fetchSprints(ctx, streamId) {
      try {
        const response = await axios.get(`v1/streams/${streamId}/sprints`)
        ctx.commit('setSprints', response.data.data)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async addLesson(ctx, lessonData) {
      try {
        const response = await axios.post('v1/lessons', lessonData)
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async saveLesson(ctx, lessonData) {
      try {
        const response = await axios.patch(
          `v1/lessons/${lessonData.id}`,
          lessonData,
        )
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async moveLessonToSprint({ dispatch }, { lessonId, sprintId, streamId }) {
      try {
        await axios.patch(`v1/lessons/${lessonId}/move-to-sprint`, { sprintId })
        dispatch('fetchSprintAndLessons', streamId)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
