import { createModule, mutation, action } from 'vuex-class-component'
import axios from '@/libs/axios'
import axiosStatic, { AxiosResponse, Canceler } from 'axios'
import { showError } from '@/helpers/notifications'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'
import { SalaryCalculation } from '@/helpers/salary'
import { DateString } from '@/helpers/types/dateString'

export type Currency = 'rub' | 'uah'

export interface ICourse {
  id: number
  title: string
  imageUrl: string
  hexColor: string
}

export interface IStream {
  id: number
  title: string
  courseId: number
  dateStart: DateString
  dateEnd: DateString
}

export interface ISalaryVariables {
  telegram: {
    telegramPoints: number
    telegramStep: number
    telegramStepCoef: number
    telegramPriceRub: number
    telegramPriceUah: number
  }
  feedback: {
    feedbackPoints: number
    feedbackStep: number
    feedbackStepCoef: number
    feedbackPriceRub: number
    feedbackPriceUah: number
  }
}

export interface ISpeedData {
  id: number
  mentorId: number
  courseId: number
  speeds: {
    speed1: number
    speed2: number
    speed3: number
    speed4: number
    speed5: number
    speed6: number
    speed7: number
  }
  step: number
  stepPrice: number
  currency: Currency
  price: number
  paymentType: string
  mastermindPrice: number
}

export interface IMentorData {
  price: number
  step: number
  stepPrice: number
  currency: Currency
  currencySymbol: '₽' | '₴'
  telegram: number | null
  toPay: string | null
  mastermindPrice: number
  paymentType: string
  homeworksApproved: number
  answerTime: number
  mentor: {
    id: number
    name: string
    avatar?: string
  }
  averageRating: number
  studentsCount: number
  homeworksRejected: number
  videoreviews: number
  approveTime: number
  averageRejected: number
  studentMessagesCount: number
  mentorMessagesCount: number
  periods: {
    month: number
    dateStart: string
    dateEnd: string
    speed: number
    homeworksApproved: number
    answerTime: number
  }[]
}

export interface IPayment {
  id: number
  mentor: {
    id: number
    fullName: string
    avatar?: string
  }
  paymentMethod: string
  amount: number
  currency: Currency
  dateStart: string
  dateEnd: string
  comment?: string
  telegramRating: number
  homeworkPrice: number
  homeworksApproved: number
  bonuses: IBonus[]
  stats: string
  createdAt: string
  isDeleted: boolean
}

export interface IBonus {
  amount: number
  comment: string
}

export default class SalaryStore extends createModule({
  namespaced: 'salary',
  strict: false,
}) {
  private _courses: ICourse[] = []
  private _streams: IStream[] = []
  private _selectedCourse: ICourse | null = null
  private _selectedStream: IStream | null = null
  private _dateStart: string | null = null
  private _dateEnd: string | null = null
  private _dateError = false
  private _pending = false
  private _salaryTable: IMentorData[] = []
  private _variables: ISalaryVariables | null = null
  private _payments: IPayment[] = []
  private _axiosCanceler: Canceler | null = null

  get courses() {
    return this._courses
  }
  get streams() {
    return this._selectedCourse
      ? this._streams
          .filter(stream => stream.courseId === this._selectedCourse!.id)
          .sort((s1, s2) => {
            const date1 = new Date(s1.dateStart).getTime()
            const date2 = new Date(s2.dateStart).getTime()

            return date2 - date1
          })
      : []
  }
  get selectedCourse() {
    return this._selectedCourse
  }
  get selectedStream() {
    return this._selectedStream
  }
  get dateStart() {
    return this._dateStart
  }
  get dateEnd() {
    return this._dateEnd
  }
  get dateError() {
    return this._dateError
  }
  get pending() {
    return this._pending
  }
  get salaryTable() {
    return this._salaryTable
  }
  get variables() {
    return this._variables
  }
  get payments() {
    return this._payments
  }

  @mutation private setCoursesAndStreams(
    courses: Array<ICourse & { streams: IStream[] }>,
  ) {
    this._streams = []
    this._courses = courses.map(c => {
      this._streams = this._streams.concat(
        c.streams.map(s => {
          return {
            id: s.id,
            title: s.title,
            courseId: c.id,
            dateStart: s.dateStart,
            dateEnd: s.dateEnd,
          }
        }),
      )
      return {
        id: c.id,
        title: c.title,
        imageUrl: c.imageUrl,
        hexColor: c.hexColor,
      }
    })
  }
  @mutation public setCourseFilter(course: ICourse | null) {
    this._selectedCourse = course
    this._selectedStream = null
  }
  @mutation public setStreamFilter(stream: IStream | null) {
    this._selectedStream = stream
  }
  @mutation public setDateStart(dateStart: string | null) {
    this._dateStart = dateStart

    if (
      this._dateEnd !== null &&
      this._dateStart !== null &&
      new Date(this._dateStart).getTime() - new Date(this._dateEnd).getTime() >
        0
    ) {
      this._dateError = true
    } else {
      this._dateError = false
    }
  }
  @mutation public setDateEnd(dateEnd: string | null) {
    this._dateEnd = dateEnd

    if (
      this._dateEnd !== null &&
      this._dateStart !== null &&
      new Date(this._dateStart).getTime() - new Date(this._dateEnd).getTime() >
        0
    ) {
      this._dateError = true
    } else {
      this._dateError = false
    }
  }
  @mutation private setPending(value: boolean) {
    this._pending = value
  }
  @mutation private setSalaryTable({
    speeds,
    mentorsData,
  }: {
    speeds: ISpeedData[]
    mentorsData: IMentorData[]
  }) {
    const table = []
    for (const mentorData of mentorsData) {
      let error = false
      const speedData = speeds.find(
        (s: any) => s.mentorId === mentorData.mentor.id,
      )
      if (
        !speedData ||
        !speedData.price ||
        !speedData.currency ||
        !speedData.stepPrice ||
        !speedData.step
      ) {
        error = true
      } else {
        for (const period of mentorData.periods) {
          const month: 1 | 2 | 3 | 4 | 5 | 6 | 7 = period.month as any
          if (month > 7 || month < 1) {
            showError('Некорректное кол-во месяцев')
          }

          const speed = speedData.speeds[`speed${month}`]
          if (!speed) {
            error = true
            break
          }
          period.speed = speed
        }
      }

      if (speedData) {
        mentorData.price = speedData.price
        mentorData.step = speedData.step
        mentorData.stepPrice = speedData.stepPrice
        mentorData.currency = speedData.currency
        mentorData.telegram = null
        mentorData.toPay = null
        mentorData.mastermindPrice = speedData.mastermindPrice
        mentorData.paymentType = speedData.paymentType
        mentorData.currencySymbol = speedData.currency === 'rub' ? '₽' : '₴'

        mentorData.homeworksApproved = mentorData.periods.reduce(
          (ha, p) => ha + p.homeworksApproved,
          0,
        )
        mentorData.answerTime =
          mentorData.periods.reduce((at, p) => at + p.answerTime, 0) /
          mentorData.periods.length
      } else {
        error = true
      }

      if (error) {
        showError(
          `У ментора ${mentorData.mentor.name} не заполнены данные для расчета зп`,
        )
      } else {
        table.push(mentorData)
      }
    }
    this._salaryTable = table
  }
  @mutation private setVariables(variables: ISalaryVariables) {
    if (!variables) {
      showError('Для курса не заданы настройки фидбэк/телеграм')
      throw new Error('Для курса не заданы настройки фидбэк/телеграм')
    }
    if (
      !variables.feedback.feedbackPoints ||
      !variables.feedback.feedbackPriceRub ||
      !variables.feedback.feedbackPriceUah ||
      !variables.feedback.feedbackStep ||
      !variables.feedback.feedbackStepCoef
    ) {
      showError('Для курса не заданы настройки фидбэк')
      throw new Error('Для курса не заданы настройки фидбэк')
    }

    this._variables = variables
  }
  @mutation private setPayments(payments: IPayment[]) {
    this._payments = payments
  }
  @mutation private addPaymentLocal(payment: IPayment) {
    this._payments.push(payment)
  }
  @mutation private loadFilterData() {
    const data = loadFromLS<any>('salaryFilter')

    this._selectedCourse = this._courses.find(c => c.id === data.course) || null
    this._selectedStream = this._streams.find(s => s.id === data.stream) || null
    this._dateStart = data.dateStart
    this._dateEnd = data.dateEnd
  }

  @action async fetchPayments() {
    if (!this._selectedStream) {
      return
    }

    try {
      const response: AxiosResponse<{ payments: IPayment[] }> = await axios.get(
        `v1/salary/payments?stream=${this._selectedStream.id}`,
      )

      this.setPayments(response.data.payments)
    } catch (error) {
      console.error(error)
      showError('При получении платежей произошла ошибка')
    }
  }
  @action async fetchFilterData() {
    try {
      const response: AxiosResponse<{
        courses: Array<ICourse & { streams: IStream[] }>
      }> = await axios.get('v1/courses-with-streams')

      this.setCoursesAndStreams(response.data.courses)
      this.loadFilterData()
      await this.fetchData()
    } catch (error) {
      console.error(error)
    }
  }
  @action async fetchData() {
    try {
      if (
        this._dateStart === null ||
        this._dateEnd === null ||
        this._dateError ||
        !this._selectedStream ||
        !this._selectedCourse
      ) {
        return
      }
      this.setPending(true)

      if (this._axiosCanceler) {
        this._axiosCanceler('cancel')
      }

      await this.fetchPayments()

      const cancelTokenSource = axiosStatic.CancelToken.source()
      this._axiosCanceler = cancelTokenSource.cancel

      const responseVariables: AxiosResponse<{
        variables: ISalaryVariables
      }> = await axios.get(
        `v1/salary/course/variables/${this._selectedCourse.id}`,
        { cancelToken: cancelTokenSource.token },
      )
      const variables = responseVariables.data.variables
      this.setVariables(variables)

      const responseSpeeds: AxiosResponse<{
        data: { speeds: ISpeedData[] }
      }> = await axios.get(`v1/salary/speeds/${this._selectedCourse.id}`, {
        cancelToken: cancelTokenSource.token,
      })
      const speeds = responseSpeeds.data.data.speeds

      const responseSalary: AxiosResponse<{
        data: IMentorData[]
      }> = await axios.get(
        `v1/salary?stream=${this._selectedStream.id}&dateStart=${this._dateStart}&dateEnd=${this._dateEnd}`,
        { cancelToken: cancelTokenSource.token },
      )
      const mentorsData = responseSalary.data.data

      saveToLS('salaryFilter', {
        course: this._selectedCourse.id,
        stream: this._selectedStream.id,
        dateStart: this._dateStart,
        dateEnd: this._dateEnd,
      })

      this.setSalaryTable({ speeds, mentorsData })

      this.setPending(false)
    } catch (error) {
      if ((error as any).message !== 'cancel') {
        showError('Ошибка получения данных для рачета зп')
        console.error(error)
        this.setPending(false)
      }
    }
  }
  @action async addPayment({
    data,
    comment,
    bonuses,
    paymentMethod,
    amount,
    calculationData,
  }: {
    data: IMentorData
    comment: string
    bonuses: IBonus[]
    paymentMethod: string
    amount: number
    calculationData: SalaryCalculation
  }) {
    try {
      const payload = {
        mentorId: data.mentor.id,
        streamId: this._selectedStream!.id,
        amount: Math.round(amount),
        currency: data.currency,
        dateStart: data.periods[0].dateStart.slice(0, 10),
        dateEnd: data.periods[data.periods.length - 1].dateEnd.slice(0, 10),
        paymentMethod,
        comment,
        telegramRating: data.telegram,
        homeworkPrice: data.price,
        homeworksApproved: data.homeworksApproved,
        bonuses,
        stats: JSON.stringify({ data, calculationData }),
      }

      const response: AxiosResponse<{ payment: IPayment }> = await axios.post(
        'v1/salary/payments',
        payload,
      )
      this.addPaymentLocal(response.data.payment)

      return true
    } catch (error) {
      console.error(error)
      showError('При добавлении платежа произошла ошибка')
      return false
    }
  }
}
