import Vue from 'vue'
import { createModule, mutation, action } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError } from '@/helpers/notifications'
import { IWebinarTag, IWebinarTagSimple } from '@/interfaces/IWebinarTag'

export default class WebinarTagsStore extends createModule({
  namespaced: 'webinarTags',
  strict: false,
}) {
  private _tags: IWebinarTagSimple[] = []

  get tags() {
    return this._tags
  }

  @mutation private addTagLocal(tag: IWebinarTagSimple) {
    this._tags.push(tag)
  }

  @mutation private setTags(tags: IWebinarTagSimple[]) {
    this._tags = tags
  }

  @mutation private deleteTagLocal(id: number) {
    this._tags = this._tags.filter(t => t.id !== id)
  }

  @mutation private updateTagLocal(tag: IWebinarTagSimple) {
    for (let i = 0; i < this._tags.length; i++) {
      if (this._tags[i].id == tag.id) {
        Vue.set(this._tags, i, tag)
        break
      }
    }
  }

  @action async addTag(tag: IWebinarTag) {
    try {
      const response: AxiosResponse<{
        tag: IWebinarTagSimple
      }> = await axios.post('v1/webinar/tags', tag)
      this.addTagLocal(response.data.tag)

      return true
    } catch (error) {
      console.error(error)
      showError('При создании тега произошла ошибка')
      return false
    }
  }

  @action async fetchTags() {
    try {
      const response: AxiosResponse<{
        tags: IWebinarTagSimple[]
      }> = await axios.get('v1/webinar/tags')
      this.setTags(response.data.tags)
    } catch (error) {
      console.error(error)
      showError('При получении тегов произошла ошибка')
    }
  }

  @action async fetchTag(id: number) {
    try {
      const response: AxiosResponse<{ tag: IWebinarTag }> = await axios.get(
        `v1/webinar/tags/${id}`,
      )

      return response.data.tag
    } catch (error) {
      console.error(error)
      showError('При получении тега произошла ошибка')
      return null
    }
  }

  @action async deleteTag(id: number) {
    try {
      await axios.delete(`v1/webinar/tags/${id}`)

      this.deleteTagLocal(id)
    } catch (error) {
      console.error(error)
      showError('При удалении тега произошла ошибка')
    }
  }

  @action async updateTag({ tag, id }: { tag: IWebinarTag; id: number }) {
    try {
      const response: AxiosResponse<{
        tag: IWebinarTagSimple
      }> = await axios.patch(`v1/webinar/tags/${id}`, tag)
      this.updateTagLocal(response.data.tag)

      return true
    } catch (error) {
      showError('При сохранении тега произошла ошибка')
      console.error(error)
      return false
    }
  }
}
