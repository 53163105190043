import { Permission } from '@/enums/Permission'
import auth from '@/middlewares/auth'
import blogEditMiddleware from '@/middlewares/blogEditMiddleware'
import { RouteConfig } from 'vue-router'

const permissionsMiddleware =
  require('@/middlewares/permissionsMiddleware').default

export const blogRoutes: RouteConfig[] = [
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/Blog'),
    meta: {
      pageTitle: 'Журнал',
      middleware: [permissionsMiddleware],
      permission: Permission.BLOG,
    },
  },
  {
    path: '/blog/add',
    name: 'addArticle',
    component: () => import('@/views/Blog/BlogEdit'),
    meta: {
      pageTitle: 'Добавить статью',
      middleware: [auth, permissionsMiddleware, blogEditMiddleware],
      breadcrumb: [
        {
          text: 'Блог',
          to: { name: 'blog' },
        },
      ],
      permission: Permission.BLOG,
    },
  },
  {
    path: '/blog/edit/:id',
    name: 'editArticle',
    component: () => import('@/views/Blog/BlogEdit'),
    meta: {
      pageTitle: 'Редактировать статью',
      middleware: [auth, permissionsMiddleware, blogEditMiddleware],
      breadcrumb: [
        {
          text: 'Блог',
          to: { name: 'blog' },
        },
      ],
      permission: Permission.BLOG,
    },
  },
]
