import { showError } from './notifications'
import axios from '@/libs/axios'
import { AxiosRequestConfig } from 'axios'

export async function uploadFile(
  file: File,
  url: string,
  progressChangedHandler:
    | ((progressEvent: { loaded: number; total: number }) => void)
    | null = null,
  field = 'file',
) {
  try {
    const formData = new FormData()
    formData.append(field, file)

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    if (progressChangedHandler) {
      config.onUploadProgress = progressChangedHandler
    }

    const response = await axios.post(url, formData, config)

    return response.data as any
  } catch (error) {
    console.error(error)
    showError(`При загрузке файла ${file.name} произошла ошибка`)
    return null
  }
}
