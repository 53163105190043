import { createModule, mutation, action, getter } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError } from '@/helpers/notifications'

interface IPartnersInfo {
  partners: IPartnersData
  orders: IOrdersData
  currencies: ICurrenciesData
  payments: IPaymentsData
}
interface IPartnersData {
  total: number
  active: number
  withPayments: number
}
interface IOrdersData {
  count: number
  budget: number
  paid: number
}
interface ICurrenciesData {
  rub: number
  uah: number
  useActual: boolean
  custom: {
    rub: number
    uah: number
    points: number
  }
}
interface IPaymentsData {
  period: {
    start: string | null
    end: string | null
  }
}

export default class PartnersStore extends createModule({
  namespaced: 'partners',
  strict: false,
}) {
  @getter partnersData: IPartnersData | null = null
  @getter ordersData: IOrdersData | null = null
  @getter currenciesData: ICurrenciesData | null = null
  @getter paymentsData: IPaymentsData | null = null

  @action
  async fetchCommonData() {
    try {
      const response: AxiosResponse<IPartnersInfo> = await axios.get(
        'v1/partners/info',
      )

      this.partnersData = response.data.partners
      this.ordersData = response.data.orders
      this.currenciesData = response.data.currencies
      this.paymentsData = response.data.payments
    } catch (error) {
      console.error(error)
      showError('Ошибка при загрузке данных')
    }
  }
}
