export default async function userMiddleware({
  to,
  next,
  store,
  nextMiddleware,
}) {
  const userId = to.params.id
  const result = await store.dispatch('fetchUser', userId)
  if (!result) return next({ name: '404' })

  return nextMiddleware()
}
