import { RouteConfig } from 'vue-router'
import auth from '@/middlewares/auth'
import editPromotionMiddleware from '@/middlewares/promotionEditMiddleware'
import { Permission } from '@/enums/Permission'

const permissionsMiddleware =
  require('@/middlewares/permissionsMiddleware').default

export const promotionsRoutes: RouteConfig[] = [
  {
    path: '/promotions/add',
    name: 'addPromotion',
    component: () => import('@/views/payments/PromotionEdit'),
    meta: {
      pageTitle: 'Добавить акцию',
      middleware: [auth, permissionsMiddleware, editPromotionMiddleware],
      permission: Permission.PROMOTIONS,
      breadcrumb: [
        {
          text: 'Цены и акции',
          to: { name: 'prices' },
        },
      ],
    },
  },
  {
    path: '/promotions/edit/:id',
    name: 'editPromotion',
    component: () => import('@/views/payments/PromotionEdit'),
    meta: {
      pageTitle: 'Редактировать акцию',
      middleware: [auth, permissionsMiddleware, editPromotionMiddleware],
      permission: Permission.PROMOTIONS,
      breadcrumb: [
        {
          text: 'Цены и акции',
          to: { name: 'prices' },
        },
      ],
    },
  },
]
