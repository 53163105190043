import { createModule, mutation, action } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError } from '@/helpers/notifications'
import { ValidationGroup, ValueValidation } from '@/helpers/validationOld'
import { uploadFile } from '@/helpers/files'
import { IWebinarTagSimple } from '@/interfaces/IWebinarTag'

interface ITeacherAvatar {
  file: File | null
  url: string | null
}
interface IWebinar {
  id: number
  alias: string
  title: string
  description: string
  live: boolean
  tagId: number | null
  tag: IWebinarTagSimple | null
  telegram: string
  teacher: {
    name: string
    description: string
    avatar: string
    achievements: string[]
  }
  program: Array<{
    title: string
    description: string
  }>
}

function getDefaultWebinar() {
  return {
    id: -1,
    alias: '',
    title: '',
    description: '',
    live: false,
    tagId: null,
    tag: null,
    telegram: '',
    teacher: {
      name: '',
      description: '',
      avatar: '',
      achievements: [],
    },
    program: [
      {
        title: '',
        description: '',
      },
      {
        title: '',
        description: '',
      },
      {
        title: '',
        description: '',
      },
    ],
  }
}

function avatarValidation(value: ITeacherAvatar) {
  if (!value.file && !value.url) {
    return 'Выберите изображение'
  }
}

export default class WebinarStore extends createModule({
  namespaced: 'webinar',
  strict: false,
}) {
  private _state: 'new' | 'edit' = 'new'
  private _webinar: IWebinar = getDefaultWebinar()
  private _validation = new ValidationGroup()
  private _teacherAvatar = new ValueValidation<ITeacherAvatar>({
    initialValue: {
      file: null,
      url: null,
    },
    validators: [avatarValidation],
    validationGroup: this._validation,
  })

  get getState() {
    return this._state
  }

  get webinar() {
    return this._webinar
  }

  get validation() {
    return this._validation
  }

  get teacherAvatar() {
    return this._teacherAvatar
  }

  @mutation public setState(value: 'new' | 'edit') {
    this._state = value
  }

  @mutation public reset() {
    this._validation = new ValidationGroup()
    this._webinar = getDefaultWebinar()
    this._teacherAvatar = new ValueValidation<ITeacherAvatar>({
      initialValue: {
        file: null,
        url: null,
      },
      validators: [avatarValidation],
      validationGroup: this._validation,
    })
  }

  @mutation private setAvatarUrl(url: string) {
    this._webinar.teacher.avatar = url
  }

  @mutation private setWebinar(webinar: IWebinar) {
    this._webinar = webinar
    this._teacherAvatar.value.url = webinar.teacher.avatar
  }

  @action async fetchWebinar(id: number) {
    try {
      const response: AxiosResponse<{ webinar: IWebinar }> = await axios.get(
        `v1/webinars/${id}`,
      )
      this.setWebinar(response.data.webinar)

      return true
    } catch (error) {
      showError('При получении события произошла ошибка')
      console.error(error)
      return false
    }
  }

  @action async addWebinar() {
    if (!this._validation.validate()) {
      showError('Некорректно заполнены поля')
      return false
    }

    if (!(await this.uploadImage())) {
      return false
    }

    try {
      await axios.post('v1/webinars', this._webinar)

      return true
    } catch (error) {
      console.error(error)
      showError('При создании события произошла ошибка')
      return false
    }
  }

  @action async saveWebinar() {
    if (!(await this.uploadImage())) {
      return false
    }

    try {
      await axios.patch(`v1/webinars/${this._webinar.id}`, this._webinar)

      return true
    } catch (error) {
      console.error(error)
      showError('При сохранении события произошла ошибка')
      return false
    }
  }

  @action async uploadImage() {
    if (!this.teacherAvatar.value.file) {
      return true
    }

    const response = await uploadFile(
      this.teacherAvatar.value.file,
      'v1/uploads/images',
      null,
      'image',
    )

    if (response) {
      this.teacherAvatar.value.url = response.url
      this.setAvatarUrl(response.url)
      return true
    }

    return false
  }
}
