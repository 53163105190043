import axios from '@/libs/axios'

export default {
  state: {
    countries: [],
    cities: [],
  },
  getters: {
    getCountries: state => state.countries,
    getCities: state => state.cities,
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries
    },
    setCities(state, cities) {
      state.cities = cities
    },
  },
  actions: {
    async fetchCountries(ctx) {
      try {
        const response = await axios.get('v1/locations')
        ctx.commit('setCountries', response.data.countries)
      } catch (error) {
        console.error(error)
      }
    },
    async fetchCities(ctx, countryId) {
      try {
        const response = await axios.get(`v1/locations/cities?country=${countryId}`)
        ctx.commit('setCities', response.data.cities)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
