import { createModule, mutation, action, getter } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError, showMessage } from '@/helpers/notifications'
import { DateString } from '@/helpers/types/dateString'
import { Nullable } from '@/helpers/types/nullable'

export type CourseType = 'free' | 'paid'
export type MentorData = {
  id: number
  name: string
  avatar?: string
  telegram: string
  telegramId: number
}
export type StreamData = {
  title: string
  date_start: string
  number: false | number
  course_id: number | string
  telegram_chat?: string
  telegram_notifications?: string
  telegram_bot?: string
  promocode?: number
  promocode_id?: number
}

export interface IStream {
  id: number
  title: string
  date_start: DateString
  number: number
  course: {
    id: number
    title: string
    abbreviation: string
    type: CourseType
  }
  lessons_count: number
  students_count: number
  telegram_chat: Nullable<string>
  telegram_notifications: Nullable<string>
  telegram_bot: Nullable<string>
  telegram_chats: {
    id: number
    mentor_id: number
    stream_id: number
    telegram_url: string
  }[]
  promocode: Nullable<number>
}

export default class StreamsStore extends createModule({
  namespaced: 'streams',
  strict: false,
}) {
  @getter streams: IStream[] = []
  @getter courseType: CourseType = 'free'

  @action
  async fetchStreams(courseId: string | number) {
    try {
      const response: AxiosResponse<{ data: IStream[] }> = await axios.get(
        `v1/courses/${courseId}/streams`,
      )
      this.streams = response.data.data

      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  @action
  async addStream({
    data,
    mentors,
  }: {
    data: StreamData
    mentors: MentorData[]
  }) {
    try {
      const response: AxiosResponse<{
        data: { id: number }
      }> = await axios.post('v1/streams/new', data)

      for (const mentor of mentors) {
        if (mentor.telegram) {
          await axios.post('v1/streams/mentor/chat', {
            mentor_id: mentor.id,
            stream_id: response.data.data.id,
            telegram_chat: mentor.telegram,
          })
        }
      }

      await this.fetchStreams(data.course_id)
      showMessage('Поток добавлен', 'Успех')
      return true
    } catch (error) {
      console.error(error)
      showMessage('При добавлении потока произошла ошибка')
      return false
    }
  }

  @action
  async saveStream({
    data,
    mentors,
    streamId,
  }: {
    data: StreamData
    mentors: MentorData[]
    streamId: number
  }) {
    try {
      await axios.patch(`v1/streams/${streamId}`, data)

      for (const mentor of mentors) {
        if (mentor.telegram) {
          if (mentor.telegramId) {
            await axios.patch(`v1/streams/mentor/chat/${mentor.telegramId}`, {
              telegram_chat: mentor.telegram,
            })
          } else {
            await axios.post('v1/streams/mentor/chat', {
              mentor_id: mentor.id,
              stream_id: streamId,
              telegram_chat: mentor.telegram,
            })
          }
        } else if (mentor.telegramId) {
          await axios.delete(`v1/streams/mentor/chat/${mentor.telegramId}`)
        }
      }

      await this.fetchStreams(data.course_id)
      showMessage('Поток сохранен', 'Успех')

      return true
    } catch (error) {
      console.error(error)
      showError('Ошибка сохранения потока')
      return false
    }
  }

  @action
  async deleteStream(id: number) {
    try {
      await axios.delete(`v1/streams/${id}`)

      this.streams = this.streams.filter(s => s.id !== id)

      showMessage('Поток удален', 'Успех')
    } catch (error) {
      console.error(error)
      showError('При удалении потока произошла ошибка')
    }
  }

  @action
  async duplicateStream(id: number) {
    try {
      const response: AxiosResponse<{ data: IStream }> = await axios.post(
        `v1/streams/${id}/double`,
      )
      this.streams.push(response.data.data)
      showMessage('Поток клонирован', 'Успех')
    } catch (error) {
      console.error(error)
      showError('При клонировании потока произошла ошибка')
    }
  }

  // TODO костыль для определения типа курса, надо менять
  @action
  async checkCourseType(courseId: number | string) {
    try {
      const response: AxiosResponse<{
        course: { type: 'free' | 'paid' }
      }> = await axios.get(`v1/courses/${courseId}`)

      this.courseType = response.data.course.type
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при проверке типа курса')
    }
  }
}
