import Vue from 'vue'
import Vuex from 'vuex'
import { createProxy, extractVuexModule } from 'vuex-class-component'
import BlogStore from './blogStore'
import CommonStore from './commonStore'
import CourseStore from './courseStore'
import I18nStore from './i18nStore'
import LessonStore from './lessonStore'
import PartnersStore from './partnersStore'
import PromocodesStore from './promocodesStore'
import PromotionsStore from './promotionsStore'
import PromotionStore from './promotionStore'
import SalarySettingsStore from './salarySettingsStore'
import SalaryStore from './salaryStore'
import SprintsStore from './sprintsStore'
import StreamsStore from './streamsStore'
import StudentStore from './studentStore'
import UserStore from './userStore'
import WebinarInstancesStore from './webinarInstancesStore'
import WebinarStore from './webinarStore'
import WebinarTagsStore from './webinarTagsStore'

// Modules
const app = require('./app').default
const appConfig = require('./app-config').default
const verticalMenu = require('./vertical-menu').default
const tagStore = require('./tagStore').default
const employeesStore = require('./employeesStore').default
const lessonStoreOld = require('./lessonsStore').default
const usersStore = require('./usersStore').default
const userGroupsStore = require('./userGroupsStore').default
const invitesStore = require('./invitesStore').default
const homeworkReviewStore = require('./homeworkReviewStore').default
const locationStore = require('./locationStore').default
const profileStore = require('./profileStore').default
const analytics = require('./analytics').default

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    tagStore,
    employeesStore,
    lessonStoreOld,
    usersStore,
    userGroupsStore,
    invitesStore,
    homeworkReviewStore,
    locationStore,
    profileStore,
    analytics,
    ...extractVuexModule(UserStore),
    ...extractVuexModule(PromotionsStore),
    ...extractVuexModule(SalarySettingsStore),
    ...extractVuexModule(SalaryStore),
    ...extractVuexModule(StudentStore),
    ...extractVuexModule(WebinarInstancesStore),
    ...extractVuexModule(WebinarStore),
    ...extractVuexModule(WebinarTagsStore),
    ...extractVuexModule(StreamsStore),
    ...extractVuexModule(PromocodesStore),
    ...extractVuexModule(PartnersStore),
    ...extractVuexModule(I18nStore),
    ...extractVuexModule(CourseStore),
    ...extractVuexModule(CommonStore),
    ...extractVuexModule(LessonStore),
    ...extractVuexModule(SprintsStore),
    ...extractVuexModule(BlogStore),
    ...extractVuexModule(PromotionStore),
  },
  strict: process.env.DEV,
})

export const userStore = createProxy(store, UserStore)
export const promotionsStore = createProxy(store, PromotionsStore)
export const promotionStore = createProxy(store, PromotionStore)
export const salarySettingsStore = createProxy(store, SalarySettingsStore)
export const salaryStore = createProxy(store, SalaryStore)
export const studentStore = createProxy(store, StudentStore)
export const webinarInstancesStore = createProxy(store, WebinarInstancesStore)
export const webinarStore = createProxy(store, WebinarStore)
export const webinarTagsStore = createProxy(store, WebinarTagsStore)
export const streamsStore = createProxy(store, StreamsStore)
export const promocodesStore = createProxy(store, PromocodesStore)
export const partnersStore = createProxy(store, PartnersStore)
export const i18nStore = createProxy(store, I18nStore)
export const courseStore = createProxy(store, CourseStore)
export const commonStore = createProxy(store, CommonStore)
export const lessonStore = createProxy(store, LessonStore)
export const sprintsStore = createProxy(store, SprintsStore)
export const blogStore = createProxy(store, BlogStore)

export default store
