import axios from '@/libs/axios'
import { showError } from '@/helpers/notifications'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'

export default {
  namespaced: true,
  state: {
    courses: [],
    streams: [],
    selectedCourse: null,
    selectedStreams: [],
    dateStart: null,
    dateEnd: null,
    dateError: false,
    commonTable: [],
    mentorsTable: [],
    pending: false,
    chartPending: false,
    chartSeries: [],
  },
  getters: {
    courses: state => state.courses,
    streams(state) {
      return state.selectedCourse
        ? state.streams.filter(
            stream => stream.courseId === state.selectedCourse.id,
          )
        : []
    },
    selectedCourse: state => state.selectedCourse,
    selectedStreams: state => state.selectedStreams,
    dateStart: state => state.dateStart,
    dateEnd: state => state.dateEnd,
    dateError: state => state.dateError,
    commonTable: state => state.commonTable,
    mentorsTable: state => state.mentorsTable,
    pending: state => state.pending,
    chartPending: state => state.chartPending,
    chartSeries: state => state.chartSeries,
  },
  mutations: {
    setCoursesAndStreams(state, data) {
      state.streams = []
      state.courses = data.courses.map(c => {
        state.streams = state.streams.concat(
          c.streams.map(s => {
            const day = s.dateStart.substring(8, 10)
            const month = s.dateStart.substring(5, 7)
            const year = s.dateStart.substring(0, 4)

            return {
              id: s.id,
              title: s.title,
              courseId: c.id,
              dateStart: `${day}.${month}.${year}`,
            }
          }),
        )
        return {
          id: c.id,
          title: c.title,
          imageUrl: c.imageUrl,
          hex: c.hexColor,
        }
      })
    },
    setCourseFilter(state, course) {
      state.selectedCourse = course
      state.selectedStreams = []
    },
    setStreamsFilter(state, streams) {
      state.selectedStreams = streams
    },
    setDateStart(state, dateStart) {
      state.dateStart = dateStart

      if (
        state.dateEnd !== null &&
        state.dateStart !== null &&
        new Date(state.dateStart) - new Date(state.dateEnd) > 0
      ) {
        state.dateError = true
      } else {
        state.dateError = false
      }
    },
    setDateEnd(state, dateEnd) {
      state.dateEnd = dateEnd

      if (
        state.dateEnd !== null &&
        state.dateStart !== null &&
        new Date(state.dateStart) - new Date(state.dateEnd) > 0
      ) {
        state.dateError = true
      } else {
        state.dateError = false
      }
    },
    setCommonTableData(state, data) {
      state.commonTable = []

      for (const mentor of data.analytics) {
        for (const stream of mentor.streams) {
          const existedStream = state.commonTable.find(s => s.id === stream.id)

          if (existedStream) {
            existedStream.studentsCount += stream.studentsCount
            existedStream.homeworksApproved += stream.homeworksApproved
            existedStream.homeworksRejected += stream.homeworksRejected
            existedStream.mentorMessagesCount += stream.mentorMessagesCount
            existedStream.studentMessagesCount += stream.studentMessagesCount
            existedStream.successfulTests += stream.successfulTests
            existedStream.videoReviews += stream.videoReviews
          } else {
            state.commonTable.push(stream)
          }
        }
      }

      for (const stream of state.commonTable) {
        const averageData = data.averageDataByStreams.find(
          d => d.streamId === stream.id,
        )
        stream.averageRating = averageData.averageRating
        stream.answerTime = averageData.answerTime
        stream.approveTime = averageData.approveTime
      }
    },
    setMentorsTableData(state, data) {
      state.mentorsTable = []

      for (const mentor of data.analytics) {
        mentor.averageRating = mentor.streams.map(s => s.averageRating)
        mentor.answerTime = mentor.streams.map(s => s.answerTime)
        mentor.approveTime = mentor.streams.map(s => s.approveTime)
        mentor.studentsCount = mentor.streams.map(s => s.studentsCount)
        mentor.homeworksApproved = mentor.streams.map(s => s.homeworksApproved)
        mentor.homeworksRejected = mentor.streams.map(s => s.homeworksRejected)
        mentor.mentorMessagesCount = mentor.streams.map(
          s => s.mentorMessagesCount,
        )
        mentor.studentMessagesCount = mentor.streams.map(
          s => s.studentMessagesCount,
        )
        mentor.successfulTests = mentor.streams.map(s => s.successfulTests)
        mentor.videoReviews = mentor.streams.map(s => s.videoReviews)
        mentor.averageRejected = mentor.streams.map(s => s.averageRejected)
      }

      state.mentorsTable = data.analytics

      const sprints = data.analytics[0].streams.map(s => s.title)

      state.mentorsTable = [
        {
          mentor: null,
          averageRating: sprints,
          answerTime: sprints,
          approveTime: sprints,
          studentsCount: sprints,
          homeworksApproved: sprints,
          homeworksRejected: sprints,
          mentorMessagesCount: sprints,
          studentMessagesCount: sprints,
          successfulTests: sprints,
          videoReviews: sprints,
          averageRejected: sprints,
        },
        ...state.mentorsTable,
      ]
    },
    clearTables(state) {
      state.commonTable = []
      state.mentorsTable = []
    },
    setPending(state, value) {
      state.pending = value
    },
    setChartPending(state, value) {
      state.chartPending = value
    },
    addChartData(state, { data, title, streamId, column }) {
      state.chartSeries.push({
        name: title,
        data: data.map(el => ({
          y: el.value,
          x: new Date(el.date).toLocaleDateString('ru'),
        })),
        streamId,
        column,
      })
    },
    clearChart(state) {
      state.chartSeries = []
    },
    removeSeries(state, index) {
      state.chartSeries.splice(index, 1)
    },
    loadFilterData(state) {
      const data = loadFromLS('analyticsFilter')

      state.selectedCourse = state.courses.find(c => c.id === data.course)
      state.selectedStreams = state.streams.filter(s =>
        data.streams.includes(s.id),
      )
      state.dateStart = data.dateStart
      state.dateEnd = data.dateEnd
    },
  },
  actions: {
    async fetchFilterData({ commit, dispatch }) {
      try {
        const response = await axios.get('v1/courses-with-streams')

        commit('setCoursesAndStreams', response.data)
        commit('loadFilterData')
        dispatch('fetchData')
      } catch (error) {
        console.error(error)
      }
    },
    async fetchData({ commit, state }) {
      try {
        if (
          state.dateStart === null ||
          state.dateEnd === null ||
          state.dateError ||
          state.selectedStreams.length === 0
        ) {
          commit('clearTables')
          return
        }

        commit('setPending', true)

        const streams = state.selectedStreams.map(s => s.id).toString()
        const response = await axios.get(
          `v1/analytics?streams=[${streams}]&dateStart=${state.dateStart}&dateEnd=${state.dateEnd}`,
        )
        saveToLS('analyticsFilter', {
          course: state.selectedCourse.id,
          streams: state.selectedStreams.map(s => s.id),
          dateStart: state.dateStart,
          dateEnd: state.dateEnd,
        })
        commit('setMentorsTableData', response.data.data)
        commit('setCommonTableData', response.data.data)
        commit('clearChart')
      } catch (error) {
        console.error(error)
      } finally {
        commit('setPending', false)
      }
    },
    async fetchChartData({ commit, state }, { column, title, streamId }) {
      try {
        const existedIndex = state.chartSeries.findIndex(s => s.name === title)
        if (~existedIndex) {
          commit('removeSeries', existedIndex)
          return
        }

        if (state.chartSeries.length === 10) {
          return showError('На графике не может быть больше 10 значений')
        }

        commit('setChartPending', true)

        const response = await axios.get(
          `v1/analytics/daily?streams=[${streamId}]&dateStart=${state.dateStart}&dateEnd=${state.dateEnd}&filter=${column}`,
        )
        commit('addChartData', {
          data: response.data.data[0][column],
          title,
          streamId,
          column,
        })
      } catch (error) {
        console.error(error)
      } finally {
        commit('setChartPending', false)
      }
    },
  },
}
