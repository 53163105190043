import { Middleware } from '@/router/middlewarePipeline'
import { courseStore, lessonStore } from '@/store'

const lessonEditMiddleware: Middleware = async function ({
  nextMiddleware,
  to,
  next,
}) {
  await lessonStore.reset()

  const streamId = to.params.streamId
  await courseStore.fetchTeachers()
  const result = await lessonStore.fetchSprintsAndLessons(streamId)

  if (!result) {
    return next({ name: '404' })
  }

  if (to.name === 'editLesson') {
    lessonStore.lessonState = 'edit'

    const id = parseInt(to.params.id)

    lessonStore.lessonId = id

    const result = await lessonStore.fetchLesson(id)
    if (!result) {
      return next({ name: '404' })
    }
  } else {
    lessonStore.lessonState = 'new'
  }

  return nextMiddleware()
}

export default lessonEditMiddleware
