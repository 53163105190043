export default async function registerMiddleware({
  to,
  next,
  store,
  nextMiddleware,
}) {
  const { invite } = to.query
  const result = await store.dispatch('checkInvite', invite)
  if (!result) return next({ name: '404' })

  return nextMiddleware()
}
