import { RouteConfig } from 'vue-router'
import auth from '@/middlewares/auth'
import { Permission } from '@/enums/Permission'
import lessonEditMiddleware from '@/middlewares/lessonEdit'
import sprintsMiddleware from '@/middlewares/sprintsMiddleware'

const breadcrumbMiddleware =
  require('@/middlewares/breadcrumbMiddleware').default
const permissionsMiddleware =
  require('@/middlewares/permissionsMiddleware').default

export const streamsRoutes: RouteConfig[] = [
  {
    path: '/streams/:id',
    name: 'lessons',
    props: true,
    component: () => import('@/views/Sprints'),
    meta: {
      pageTitle: 'Занятия',
      middleware: [
        auth,
        permissionsMiddleware,
        sprintsMiddleware,
        breadcrumbMiddleware,
      ],
      permission: Permission.COURSES,
    },
  },
  {
    path: '/streams/:streamId/lessons/edit/:id/',
    name: 'editLesson',
    props: true,
    component: () => import('@/views/LessonEdit'),
    meta: {
      pageTitle: 'Редактировать занятие',
      middleware: [
        auth,
        lessonEditMiddleware,
        breadcrumbMiddleware,
        permissionsMiddleware,
      ],
      permission: Permission.COURSES,
    },
  },
  {
    path: '/streams/:streamId/lessons/add',
    name: 'newLesson',
    component: () => import('@/views/LessonEdit'),
    props: true,
    meta: {
      pageTitle: 'Новое занятие',
      middleware: [
        auth,
        lessonEditMiddleware,
        breadcrumbMiddleware,
        permissionsMiddleware,
      ],
      permission: Permission.COURSES,
    },
  },
]
