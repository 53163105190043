import axios from '@/libs/axios'

export default {
  state: {
    tags: [],
  },
  getters: {
    getTags: state => state.tags,
  },
  mutations: {
    setTags(state, tags) {
      state.tags = tags
    },
    addTag(state, tag) {
      state.tags.push(tag)
    },
  },
  actions: {
    async fetchTags(ctx) {
      try {
        const response = await axios.get('v1/courses-tags')
        ctx.commit('setTags', response.data.tags)
      } catch (error) {
        console.error(error)
      }
    },
    async saveTag(ctx, tagData) {
      try {
        const response = await axios.post('v1/courses-tags', tagData)
        ctx.commit('addTag', response.data.data)
        return response.data.data
      } catch (error) {
        console.error(error)
        return null
      }
    },
  },
}
