import axios from '@/libs/axios'
import { showError, showMessage } from '@/helpers/notifications'

export default {
  actions: {
    async updateGeneralSettings(ctx, userData) {
      const formData = new FormData()

      if (userData.avatar) {
        Object.keys(userData).forEach(
          item => formData.append(item, userData[item]),
        )
      }

      try {
        await axios.post(
          'v1/profile/settings/general/update',
          userData.avatar ? formData : userData,
        )
        ctx.commit('updateUser', userData)
        showMessage('Настройки профиля обновлены', 'Успех')
      } catch (error) {
        const { errors } = error.response.data
        showError(errors[Object.keys(errors)[0]][0])
        console.error(error)
      }
    },
    async updatePassword(ctx, passwordData) {
      try {
        await axios.patch('v1/profile/settings/password/change', passwordData)
        showMessage('Пароль успешно изменен', 'Успех')
      } catch (error) {
        if (error.response.data.code === 377) {
          showError('Текущий пароль введен неверно')
        } else {
          showError('Ошибка при изменении пароля')
        }

        console.error(error)
      }
    },
    async updateLocation(ctx, locationData) {
      try {
        await axios.patch('v1/profile/settings/location/update', locationData)
        showMessage('Данные о местоположении сохранены', 'Успех')
      } catch (error) {
        const { errors } = error.response.data
        showError(errors[Object.keys(errors)[0]][0])
        console.error(error)
      }
    },
  },
}
