import axios from '@/libs/axios'

export interface ICourse {
  id: number
  title: string
  image: {
    color: string
    url: string
  }
  teacher: {
    id: number
    name: string
    avatar: string
  }
  type: CourseType
  price: number
  learnTime: LearnTime
  isActive: boolean
  tag: {
    id: number
    label: string
    color: string
  }
}
export type CourseType = 'free' | 'paid'
export type LearnTimeType = 'day' | 'week' | 'month'
export type LearnTime = { value: number; type: LearnTimeType }

export async function getCourses() {
  const response = await axios.get<ICourse[]>('v2/courses')
  return response.data
}
