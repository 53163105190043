<template>
  <div
    ref="wayupSelect"
    :class="{
      'is-open': isOpen,
      disabled,
      flat,
      'dropdown-top': dropdownTop,
      success: state === true,
      error: state === false,
      preview: showPreview,
    }"
    class="wayup-select"
    role="combobox"
    @click.self="$refs.wayupSelectInput.focus()"
  >
    <div
      v-if="multiselect"
      :class="{ empty: value.length === 0, single: value.length === 1 }"
      class="selected-view d-flex flex-shrink-0"
      @click="$refs.wayupSelectInput.focus()"
      @mouseenter="multiselectMouseEnter"
      @mouseleave="multiselectMouseLeave"
    >
      <span v-if="value.length !== 0" class="selected-view_text flex-shrink-0">
        {{ declination(value.length, ['выбран', 'выбрано', 'выбрано']) }}
      </span>
      <span
        v-if="value.length !== 0"
        class="selected-view_count"
        :class="{ 'flex-shrink-0': value.length !== 1 }"
      >
        {{ value.length === 1 ? value[0][label] : value.length }}
      </span>
      <span
        v-if="value.length !== 0"
        class="selected-view_text overflow-ellipsis"
      >
        {{ declination(value.length, elementDeclination) }}
      </span>
      <div class="delete-icon" v-if="value.length === 1" @click.stop="clear">
        <feather-icon icon="XIcon" size="13" />
      </div>
    </div>

    <input
      ref="wayupSelectInput"
      v-model="search"
      :class="{ invicible: !isOpen && value && !multiselect }"
      :disabled="disabled"
      :placeholder="value && !multiselect ? '' : placeholder"
      class="input"
      type="text"
      @blur="handleBlur"
      @click="focusInput"
      @focus="focusInput"
      @input="handleInput"
    />
    <div
      class="value"
      v-if="value && !multiselect"
      :class="{ invicible: search }"
    >
      <slot name="selected-option" v-bind="value">
        <div class="value-default">
          {{ value[label] }}
        </div>
      </slot>
    </div>

    <button
      v-if="clearable === true"
      @click="clear"
      class="button"
      :class="{ hidden: isValueEmpty }"
    >
      <feather-icon icon="XIcon" size="18" />
    </button>

    <div
      class="dropdown-icon"
      :class="{ 'rotate-180': isOpen && !pending }"
      @click="dropdownIconClick"
    >
      <transition name="fade">
        <div
          key="preloader"
          v-if="!isOpen && pending"
          class="preloader d-flex align-items-center justify-content-center"
        >
          <b-spinner small />
        </div>
        <feather-icon key="icon" v-else icon="ChevronDownIcon" size="25" />
      </transition>
    </div>

    <transition name="fade">
      <vue-perfect-scrollbar
        v-show="isOpen || showPreview"
        ref="wayupSelectDropdown"
        class="dropdown"
        :class="{ 'dropdown-top': dropdownTop, flat, append: !appendToBody }"
        :id="`wayup-dropdown-${uid}`"
      >
        <ul :id="`wayup-dropdown-ul${uid}`">
          <li
            v-if="filteredOptions.length === 0 || pending"
            class="empty-message d-flex justify-content-center"
          >
            <span v-if="filteredOptions.length === 0 && !pending">
              {{ emptyFilterMessage }}
            </span>

            <div v-else class="preloader d-flex align-items-center">
              <b-spinner small />
            </div>
          </li>
          <li
            v-for="(option, index) in filteredOptions"
            :key="index"
            :class="{
              selected: compareById
                ? multiselect
                  ? value.some(o => o.id === value.id)
                  : value && option.id === value.id
                : multiselect
                ? value.includes(option)
                : option === value,
            }"
            class="option"
            @click="select(option)"
          >
            <div class="option-content">
              <slot name="option" v-bind="option">
                <div class="option-default" :class="{ 'no-wrap': noWrap }">
                  {{ option[label] }}
                </div>
              </slot>
            </div>
            <div>
              <feather-icon class="icon" icon="CheckIcon" size="18" />
            </div>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </transition>
  </div>
</template>

<script>
import declination from '@/helpers/declination'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    VuePerfectScrollbar,
    BSpinner,
  },
  props: {
    value: {},
    // массив опций выбора
    options: {
      type: Array,
      default: [],
    },
    // input placeholder
    placeholder: {
      type: String,
      default: 'введите текст',
    },
    // множественный выбор
    multiselect: {
      type: Boolean,
      default: false,
    },
    // отображение выбранных элементов сверху списка
    selectedOnTop: {
      type: Boolean,
      default: false,
    },
    // автозакрытие выпадающего списка при выборе
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    // input disabled
    disabled: {
      type: Boolean,
      default: false,
    },
    // имя поля объекта для фильтрации и отображения
    label: {
      type: String,
      default: 'label',
    },
    // склонение названия элемента для множественного выбора
    elementDeclination: {
      type: Array,
      default: () => ['элемент', 'элемента', 'элементов'],
    },
    // сообщение о пустом результате фильтра
    emptyFilterMessage: {
      type: String,
      default: 'нет подходящих результатов',
    },
    // true - success; false - error
    state: {
      value: Boolean | undefined,
      default: undefined,
    },
    // запрет переноса текста в стандартных опциях
    noWrap: {
      value: Boolean,
      default: true,
    },
    // показывать выбраные опции при наведении
    showSelectionPreview: {
      value: Boolean,
      default: true,
    },
    // задержка показа выбранных опций
    selectionPreviewTimeout: {
      value: Number,
      default: 500,
    },
    // индикация подгрузки данных
    pending: {
      value: Boolean,
      default: false,
    },
    // возможность очистить значение
    clearable: {
      value: Boolean,
      default: true,
    },
    // стиль без рамок
    flat: {
      value: Boolean,
      default: false,
    },
    // прикрепить выпадающий список к body
    appendToBody: {
      value: Boolean,
      default: false,
    },
    // сравнивать объекты по полю id
    compareById: {
      value: Boolean,
      default: false,
    },
  },
  data: () => ({
    uid: null,
    _value: null,
    search: '',
    isOpen: false,
    hasChanges: false,
    showPreview: false,
    filteredOptions: [],
    previewTimeoutHandle: null,
    dropdownTop: false,
  }),
  computed: {
    isValueEmpty() {
      if (Array.isArray(this.value)) return this.value.length === 0
      return !this.value
    },
  },
  watch: {
    value: function(val) {
      this._value = val
      this.filter()
    },
    options: function() {
      // TODO проверять выбранные значения на вхождение в новые параметры
      this.filter()
    },
  },
  mounted() {
    this.uid = this._uid
    document.addEventListener('mousedown', this.clickHandler)
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.clickHandler)
  },
  methods: {
    declination,
    clickHandler(e) {
      const dropdown = document.querySelector(`#wayup-dropdown-${this.uid}`)
      if (!this.$el.contains(e.target) && !dropdown.contains(e.target)) {
        this.closeDropdown()
      }
    },
    filter() {
      const search = this.tempSearch ? this.tempSearch : this.search
      const filteredArray = this.options.filter(
        option =>
          option[this.label].toLowerCase().indexOf(search.toLowerCase()) > -1,
      )

      if (!this.selectedOnTop) {
        this.filteredOptions = filteredArray
        return
      }

      this.filteredOptions = filteredArray.sort((el1, el2) => {
        const el1find = this.value.includes(el1)
        const el2find = this.value.includes(el2)
        return el1find === el2find ? 0 : el1find ? -1 : 1
      })
    },
    dropdownIconClick() {
      if (this.isOpen) this.closeDropdown()
      else this.$refs.wayupSelectInput.focus()
    },

    handleInput() {
      this.filter()
      this.focusInput()
      this.$emit('inputChanged', this.search)
    },
    focusInput() {
      if (this.isOpen) return
      clearTimeout(this.previewTimeoutHandle)
      this.filter()
      this.hasChanges = false
      this.dropdownTop =
        window.innerHeight -
          this.$refs.wayupSelect.getBoundingClientRect().top <
        320

      this.$nextTick(() => {
        ;(
          this.$refs.wayupSelectDropdown.$el || this.$refs.wayupSelectDropdown
        ).scrollTop = 0
      })
      if (this.appendToBody) this.updateDropdown()
      this.isOpen = true
    },
    updateDropdown() {
      this.$nextTick(() => {
        const dropdown = document.querySelector(`#wayup-dropdown-${this.uid}`)
        document.body.appendChild(dropdown)
        const parentRect = this.$refs.wayupSelect.getBoundingClientRect()
        dropdown.style.width = `${parentRect.width}px`
        dropdown.style.left = `${parentRect.left + window.scrollX}px`

        if (this.dropdownTop) {
          dropdown.style.top = `${parentRect.top -
            dropdown.offsetHeight +
            window.scrollY +
            1}px`
        } else {
          dropdown.style.top = `${parentRect.top +
            parentRect.height +
            window.scrollY -
            1}px`
        }
      })
    },
    closeDropdown() {
      if (!this.isOpen) return
      this.isOpen = false
      this.search = ''
      if (this.hasChanges) this.$emit('change', this.value)
    },
    handleBlur() {
      this.search = ''
    },
    select(option) {
      let needClose = true
      if (this.multiselect) {
        let _value
        if (this.value.includes(option)) {
          _value = this.value.filter(el => el !== option)
          needClose = false
        } else {
          _value = this.value
          _value.push(option)
        }

        this.hasChanges = true
        this.$emit('input', _value)
      } else if (option !== this.value) {
        this.hasChanges = true
        this.$emit('input', option)
      }

      if (needClose && this.closeOnSelect) {
        this.closeDropdown()
      }
    },
    clear() {
      if (this.value || (Array.isArray(this.value) && this.value.length === 0))
        this.hasChanges = true
      this.search = ''
      this.$emit('input', this.multiselect ? [] : null)
      this.$emit('clear')
      if (this.hasChanges && !this.isOpen) this.$emit('change', this.value)
      this.closeDropdown()
    },
    multiselectMouseEnter() {
      if (this.isOpen || !this.showSelectionPreview || this.value.length === 1)
        return

      this.previewTimeoutHandle = setTimeout(() => {
        this.filteredOptions = this.options.filter(el =>
          this.value.includes(el),
        )
        this.showPreview = true
      }, this.selectionPreviewTimeout)
    },
    multiselectMouseLeave() {
      clearTimeout(this.previewTimeoutHandle)
      this.showPreview = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

$box-shadow: 0 3px 5px 3px rgba(0, 0, 0, 0.1);
$selected-color: rgb(213, 228, 255);
$selected-color-dark: rgb(70, 101, 158);
$transition: 0.2s ease-in-out;

.wayup-select {
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  cursor: text;
  display: flex;
  position: relative;
  transition: $transition;
  min-width: 200px;
  background: $white;

  &.dropdown-top {
    &.is-open {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  &.flat {
    border: none;

    .dropdown {
      border-radius: $border-radius;
      border-top-color: $input-border-color;
      border-bottom-color: $input-border-color;
    }
  }

  &.success {
    border-color: $success;
  }

  &.error {
    border-color: $danger;
  }

  &.is-open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-color: $primary !important;

    .selected-view_text {
      max-width: 0 !important;
      opacity: 0 !important;
    }

    .selected-view_count {
      margin: 0 !important;
      max-width: 20px !important;
    }

    .value {
      opacity: 0.2;
      left: 10px;
    }
  }

  &.preview {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.disabled {
    background: #f8f8f8;
    pointer-events: none;

    .input {
      color: $text-muted;
    }

    .selected-view {
      background: rgba($primary, 0.5);
    }
  }

  .value {
    overflow: hidden;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 40px;
    bottom: 2px;
    pointer-events: none;
    display: flex;
    align-items: center;
    transition: $transition;

    .value-default {
      overflow: hidden;
      white-space: nowrap;
      margin-left: 10px;
      text-overflow: ellipsis;
    }
  }

  .input {
    background: transparent;
    border: none;
    outline: none;
    flex-grow: 1;
    margin: 7px 0 7px 10px;
    min-width: 10px;
    transition: $transition;
    color: $body-color;

    &::placeholder {
      color: $text-muted;
    }
  }

  .selected-view {
    margin: 5px 0 5px 5px;
    background: $primary;
    color: $white;
    border-radius: $border-radius;
    font-size: 0.8rem;
    padding: 5px 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: $transition;
    max-width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;

    .delete-icon {
      margin: 0 -3px 0 3px;
    }

    &.empty {
      max-width: 0;
      padding: 5px 0;
      opacity: 0;
    }

    &.single {
      .selected-view_text {
        max-width: 0 !important;
        opacity: 0 !important;
      }
      .selected-view_count {
        margin: 0;
      }
    }

    .selected-view_text {
      max-width: 100px;
      transition: $transition;
      white-space: nowrap;
      overflow: hidden;
    }

    .selected-view_count {
      transition: $transition;
      margin: 0 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 250px;
    }
  }

  .dropdown-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    cursor: pointer;
    user-select: none;
  }

  .button {
    border: none;
    background: none;
    width: 20px;
    padding: 0;
  }

  .dropdown-icon,
  .button {
    color: $text-muted;
    transition: $transition;

    &:hover {
      color: $body-color;
    }

    &.hidden {
      width: 0;
    }
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .hidden {
    opacity: 0;
    display: block;
    visibility: hidden;
  }

  .invicible {
    opacity: 0 !important;
  }
}

.dropdown {
  position: absolute;
  width: calc(100% + 2px);
  top: 36px;
  left: -1px;
  border: 1px solid $input-border-color;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  overflow: hidden;
  box-shadow: $box-shadow;
  z-index: 10000;
  transition: 0.2s opacity ease-in-out;
  max-height: 282px;
  user-select: none;
  background: $white;
  border-top-color: $primary;
  cursor: default;

  &.dropdown-top {
    box-shadow: 0 -3px 5px 3px rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    border-bottom-color: $primary;
    border-top-color: $input-border-color;

    &.append {
      top: inherit;
      bottom: 36px;
    }
  }

  &.flat {
    border-radius: $border-radius;
    border-top-color: $input-border-color;
    border-bottom-color: $input-border-color;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .option {
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
      transition: $transition;
      visibility: hidden;
      opacity: 0;
      margin-right: 13px;
      width: 0;
    }

    .option-content {
      flex-grow: 1;
      overflow: hidden;

      .option-default {
        padding: 10px;

        &.no-wrap {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    &:hover {
      background: $selected-color;
      color: $primary;
    }

    &.selected {
      background: $primary;
      color: $white;

      &:hover {
        color: $selected-color;
      }

      .icon {
        visibility: visible;
        opacity: 1;
        width: 15px;
        color: $white;
      }
    }
  }

  .empty-message {
    padding: 7px;
    text-align: center;
    cursor: default;

    .lds-dual-ring {
      height: 20px;

      &:after {
        margin: 0;
      }
    }
  }
}

.preloader {
  width: 25px;
  min-width: 25px;
  height: 36px;
}

.dark-layout {
  .wayup-select {
    background: $theme-dark-input-bg;
    border-color: $theme-dark-border-color;

    &.flat .dropdown {
      border-top-color: $theme-dark-border-color;
      border-bottom-color: $theme-dark-border-color !important;
    }

    &.dropdown-top {
      .dropdown {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        border-bottom-color: $primary;
        border-top-color: $theme-dark-border-color;
      }
    }

    &.success {
      border-color: $success;
    }

    &.error {
      border-color: $danger;
    }

    .input {
      color: $theme-dark-body-color;

      &::placeholder {
        color: $theme-dark-input-placeholder-color;
      }
    }

    &.disabled {
      background: $theme-dark-input-disabled-border-color;

      .input {
        color: $text-muted;
      }

      .selected-view {
        background: rgba($primary, 0.5);
      }
    }

    .dropdown {
      border-color: $theme-dark-border-color;
      background: $theme-dark-input-bg;
      border-top-color: $primary;

      .option:hover:not(.selected) {
        background: $selected-color-dark;
        color: $white;
      }

      &::-webkit-scrollbar-thumb {
        background: $theme-dark-border-color;
      }
    }

    .dropdown-icon,
    .button {
      color: $body-color;

      &:hover {
        color: $text-muted;
      }
    }
  }
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
</style>
