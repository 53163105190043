import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_URL,
})

Vue.prototype.$axios = axiosIns

export default axiosIns
