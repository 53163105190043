import { RouteConfig } from 'vue-router'
import auth from '@/middlewares/auth'
import { Permission } from '@/enums/Permission'
import streamsMiddleware from '@/middlewares/streamsMiddleware'
import courseEditMiddleware from '@/middlewares/courseEdit'

const permissionsMiddleware = require('@/middlewares/permissionsMiddleware')
  .default
const breadcrumbMiddleware = require('@/middlewares/breadcrumbMiddleware')
  .default

export const coursesRoutes: RouteConfig[] = [
  {
    path: '/courses/add',
    name: 'addCourse',
    component: () => import('@/views/courses/CourseEdit'),
    meta: {
      pageTitle: 'Добавить курс',
      middleware: [auth, permissionsMiddleware, courseEditMiddleware],
      breadcrumb: [
        {
          text: 'Курсы',
          to: { name: 'courses' },
        },
      ],
      permission: Permission.COURSES,
    },
  },
  {
    path: '/courses/edit/:id',
    name: 'editCourse',
    component: () => import('@/views/courses/CourseEdit'),
    props: true,
    meta: {
      pageTitle: 'Редактировать курс',
      middleware: [auth, permissionsMiddleware, courseEditMiddleware],
      breadcrumb: [
        {
          text: 'Курсы',
          to: { name: 'courses' },
        },
      ],
      permission: Permission.COURSES,
    },
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import('@/views/courses/Courses'),
    meta: {
      pageTitle: 'Курсы',
      middleware: [auth, permissionsMiddleware],
      breadcrumb: [
        {
          text: 'Курсы',
          active: true,
        },
      ],
      permission: Permission.COURSES,
    },
  },
  {
    path: '/courses/:id',
    name: 'streams',
    component: () => import('@/views/Streams'),
    meta: {
      pageTitle: 'Потоки',
      middleware: [
        auth,
        streamsMiddleware,
        breadcrumbMiddleware,
        permissionsMiddleware,
      ],
      permission: Permission.COURSES,
    },
  },
]
