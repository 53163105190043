import { Middleware } from '@/router/middlewarePipeline'
import { promotionStore } from '@/store'

const editPromotionMiddleware: Middleware = async function ({
  to,
  next,
  nextMiddleware,
}) {
  await promotionStore.reset()
  await promotionStore.fetchCourses()

  if (to.name === 'editPromotion') {
    promotionStore.promotionState = 'edit'

    const id = parseInt(to.params.id)

    promotionStore.currentPromotionId = id

    const result = await promotionStore.fetchPromotion(id)
    if (!result) {
      return next({ name: '404' })
    }
  } else {
    promotionStore.promotionState = 'new'
  }

  return nextMiddleware()
}

export default editPromotionMiddleware
