import axios from '@/libs/axios'

export default async function breadcrumbsMiddleware({ to, nextMiddleware }) {
  const breadcrumb = [
    {
      text: 'Курсы',
      to: '/courses',
    },
  ]

  switch (to.name) {
    case 'streams':
      let response = await axios.get(
        `v1/course-stream?courseId=${to.params.id}`,
      )

      breadcrumb.push({ text: response.data.course, active: true })
      break
    case 'lessons':
      response = await axios.get(`v1/course-stream?streamId=${to.params.id}`)
      breadcrumb.push({
        text: response.data.course,
        to: `/courses/${response.data.courseId}`,
      })
      breadcrumb.push({ text: response.data.stream, active: true })
      break
    case 'newLesson':
    case 'editLesson':
      response = await axios.get(
        `v1/course-stream?streamId=${to.params.streamId}`,
      )
      breadcrumb.push({
        text: response.data.course,
        to: `/courses/${response.data.courseId}`,
      })
      breadcrumb.push({
        text: response.data.stream,
        to: `/streams/${to.params.streamId}`,
      })
      break
  }

  to.meta.breadcrumb = breadcrumb
  return nextMiddleware()
}
