import axios from '@/libs/axios'
import { showMessage } from '@/helpers/notifications'

export default {
  state: {
    userGroups: [],
  },
  getters: {
    getUserGroups: state => state.userGroups,
  },
  mutations: {
    setUserGroups(state, userGroups) {
      state.userGroups = userGroups
    },
    addUserGroup(state, userGroup) {
      state.userGroups.push({
        id: userGroup.id,
        title: userGroup.title,
        permissions: JSON.parse(userGroup.permissions),
      })
    },
    deleteUserGroup(state, id) {
      const index = state.userGroups.findIndex(group => group.id === id)
      state.userGroups.splice(index, 1)
    },
  },
  actions: {
    async fetchUserGroups(ctx) {
      try {
        const response = await axios.get('v1/groups')
        ctx.commit('setUserGroups', response.data.data)
      } catch (error) {
        console.error(error)
      }
    },
    async addUserGroup(ctx, groupData) {
      try {
        const response = await axios.post('v1/groups', groupData)
        ctx.commit('addUserGroup', response.data.data)
        showMessage('Группа пользователей успешно создана', 'Успех')
      } catch (error) {
        console.error(error)
      }
    },
    async deleteUserGroup(ctx, groupId) {
      try {
        await axios.delete(`v1/groups/${groupId}`)
        ctx.commit('deleteUserGroup', groupId)
        showMessage('Группа пользователей успешно удалена', 'Успех')
      } catch (error) {
        console.error(error)
      }
    },
    async updateUserGroup(ctx, { groupId, updateData }) {
      try {
        await axios.patch(`v1/groups/${groupId}`, updateData)
        showMessage('Разрешения успешно обновлены', 'Успех')
      } catch (error) {
        console.error(error)
      }
    },
  },
}
