import axios from '@/libs/axios'
import { Middleware } from '@/router/middlewarePipeline'
import { webinarInstancesStore } from '@/store'
import { AxiosResponse } from 'axios'

const webinarInstancesMiddleware: Middleware = async function ({
  to,
  next,
  nextMiddleware,
}) {
  const webinarId = parseInt(to.params.id)

  webinarInstancesStore.setPaginationOptions({ page: 1, perPage: 10 })
  if (!(await webinarInstancesStore.fetchInstances(webinarId))) {
    return next({ name: '404' })
  }

  const response: AxiosResponse<any> = await axios.get(
    `v1/webinars/${webinarId}`,
  )

  webinarInstancesStore.setAlias(response.data.webinar.alias)

  const breadcrumb = [
    {
      text: 'События',
      to: { name: 'webinars' },
    },
    {
      text: response.data.webinar.title,
      active: true,
    },
  ]

  to.meta.breadcrumb = breadcrumb

  return nextMiddleware()
}

export default webinarInstancesMiddleware
