import { Middleware } from '@/router/middlewarePipeline'
import { streamsStore } from '@/store'

const streamsMiddleware: Middleware = async function({
  to,
  next,
  nextMiddleware,
}) {
  const courseId = to.params.id
  const result = await streamsStore.fetchStreams(courseId)
  if (!result) {
    return next({ name: '404' })
  }

  return nextMiddleware()
}

export default streamsMiddleware
