import { createModule, mutation, action } from 'vuex-class-component'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { showError, showMessage } from '@/helpers/notifications'

export interface IStudent {
  id: number
  firstName: string
  lastName?: string
  patronymic?: string
  email: string
  dob?: string
  phone: string
  superStudent?: boolean
  country?: {
    id: number
    name: string
  }
  city?: {
    id: number
    country_id: number
    name: string
  }
  street?: string
  house?: number
  block?: number
  apartment?: number
  postIndex?: number
  postOffice?: number
  avatarUrl?: string
  social: {
    telegram?: string
    whatsApp?: string
    viber?: string
    instagram?: string
    faceBook?: string
    vk?: string
    tikTok?: string
  }
  points: number
}

export default class StudentStore extends createModule({
  namespaced: 'student',
  strict: false,
}) {
  private _student: IStudent | null = null

  get student() {
    return this._student
  }

  @mutation private setStudent(student: IStudent) {
    this._student = student
  }

  @action public async fetchStudent(studentId: number) {
    try {
      const response: AxiosResponse<{ data: IStudent }> = await axios.get(
        `v1/students/${studentId}`,
      )
      this.setStudent(response.data.data)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  @action public async saveStudentData(data: {
    email: string
    firstName: string
    lastName: string
    middleName: string
    phone: string
    dob: string
    telegram: string
    whatsApp: string
    viber: string
    instagram: string
    faceBook: string
    vk: string
    tikTok: string
    superStudent: boolean
  }) {
    try {
      await axios.patch(`v1/students/${this.student!.id}`, data)
      await this.fetchStudent(this.student!.id)
      showMessage('Данные успешно сохранены')
      return true
    } catch (error) {
      console.error(error)
      showError('При сохранении данных произошла ошибка')
      return false
    }
  }
}
