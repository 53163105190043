import Vue from 'vue'

export class ValueValidation<T> {
  error: string | null = null

  private _validateOnChange: boolean
  private _value!: T
  private _validators?: Array<(value: T) => string | void>

  get value() {
    return this._value
  }

  set value(value) {
    this._value = value

    if (this._validateOnChange) {
      this.validate()
    }
  }

  constructor({
    initialValue,
    validators,
    validateOnChange = true,
    validationGroup,
  }: {
    initialValue: T
    validators?: Array<(value: T) => string | void>
    validateOnChange?: boolean
    validationGroup?: ValidationGroup
  }) {
    this.value = Vue.observable(initialValue)
    this._validators = validators
    this._validateOnChange = validateOnChange

    if (validationGroup) {
      validationGroup.addValue(this)
    }
  }

  public validate() {
    this.error = null

    if (!this._validators) {
      return true
    }

    for (const validator of this._validators) {
      this.error = validator(this.value) || null
      if (this.error) {
        return false
      }
    }

    return true
  }
}

export class ValidationProvider<T> {
  error: string | null = null

  private _setter: (value: T) => void
  private _getter: () => T
  private _validateOnChange: boolean
  private _validators?: Array<(value: T) => string | void>

  get value() {
    return this._getter()
  }

  set value(value) {
    this._setter(value)

    if (this._validateOnChange) {
      this.validate()
    }
  }

  constructor({
    getter,
    setter,
    validators,
    validateOnChange = true,
    validationGroup,
  }: {
    setter: (value: T) => void
    getter: () => T
    validators?: Array<(value: T) => string | void>
    validateOnChange?: boolean
    validationGroup?: ValidationGroup
  }) {
    this._validators = validators
    this._validateOnChange = validateOnChange
    this._setter = setter
    this._getter = getter

    if (validationGroup) {
      validationGroup.addProvider(this)
    }
  }

  public validate() {
    this.error = null

    if (!this._validators) {
      return true
    }

    for (const validator of this._validators) {
      this.error = validator(this.value) || null
      if (this.error) {
        return false
      }
    }

    return true
  }
}

export class ValidationGroup {
  private _validationProviders: ValidationProvider<any>[] = []
  private _validationValues: ValueValidation<any>[] = []

  public addProvider(provider: ValidationProvider<any>) {
    this._validationProviders.push(provider)
  }

  public addValue(value: ValueValidation<any>) {
    this._validationValues.push(value)
  }

  public validate() {
    let result = true
    this._validationProviders.forEach(p => {
      if (!p.validate()) {
        result = false
      }
    })
    this._validationValues.forEach(v => {
      if (!v.validate()) {
        result = false
      }
    })
    return result
  }

  public resetErrors() {
    this._validationProviders.forEach(p => (p.error = null))
    this._validationValues.forEach(p => (p.error = null))
  }
}

// VALIDATORS

export function notEmpty(error = 'Заполните поле') {
  return (value: any) => {
    if (value === null || value === undefined || value === '') {
      return error
    }
  }
}

export function greater(
  min: number,
  error = `Значение должно быть больше ${min}`,
) {
  return (value: number) => {
    if (value <= min) {
      return error
    }
  }
}
