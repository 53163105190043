export enum Permission {
  ADMINISTRATORS = 'admin.administrators',
  STUDENTS = 'admin.students',
  COURSES = 'admin.courses',
  HOMEWORK = 'admin.homework',
  ANALYTICS = 'admin.analytics',
  PROMOTIONS = 'admin.promotions',
  COUPONS = 'admin.coupons',
  PAYMENTS = 'admin.payments',
  SALARY = 'admin.salary',
  WEBINARS = 'admin.webinars',
  PARTNERS = 'admin.partners',
  HOMEWORK_MENTORS_VIEW = 'admin.homework.mentors.view',
  BLOG = 'admin.blog',
}
