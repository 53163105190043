import { userStore } from '@/store'

export default async function permissionsMiddleware({
  to,
  next,
  store,
  nextMiddleware,
}) {
  if (!userStore.user.group.permissions[to.meta.permission]) {
    return next({ name: '403' })
  }

  return nextMiddleware()
}
