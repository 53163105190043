import { RouteConfig } from 'vue-router'
import auth from '@/middlewares/auth'
import { Permission } from '@/enums/Permission'

const studentMiddleware = require('@/middlewares/studentMiddleware').default
const permissionsMiddleware = require('@/middlewares/permissionsMiddleware')
  .default

export const studentsRoutes: RouteConfig[] = [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Orders'),
    meta: {
      pageTitle: 'Список студентов',
      middleware: [auth, permissionsMiddleware],
      permission: Permission.STUDENTS,
    },
  },
  {
    path: '/all-students',
    name: 'allStudents',
    component: () => import('@/views/students/AllStudents'),
    meta: {
      pageTitle: 'Пользователи',
      middleware: [auth, permissionsMiddleware],
      permission: Permission.STUDENTS,
    },
  },
  {
    path: '/students/:id',
    name: 'student',
    component: () => import('@/views/students/Student.vue'),
    meta: {
      pageTitle: 'Студент',
      middleware: [auth, studentMiddleware, permissionsMiddleware],
      permission: Permission.STUDENTS,
    },
  },
]
