import { Middleware } from '@/router/middlewarePipeline'
import { sprintsStore } from '@/store'

const sprintsMiddleware: Middleware = async function ({
  nextMiddleware,
  to,
  next,
}) {
  const streamId = to.params.id
  sprintsStore.streamId = parseInt(streamId)
  const result = await sprintsStore.fetchSprintsAndLessons(streamId)
  if (!result) {
    return next({ name: '404' })
  }
  return nextMiddleware()
}

export default sprintsMiddleware
